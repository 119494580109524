import Config from './Config';
import ReaderResult from './ReaderResult';
import SummaryResult from './SummaryResult';

// Central place that knows the format the publish time should be displayed
// as.
// It is currently able to format the dates for Navigation and Detail
// lists by various rules that affect what the format should be.
export default class TimeFormatter {
  static relativeTime(
    result: ReaderResult | SummaryResult,
    showTimeInPast: boolean = false
  ): string {
    const date = result.publishTime();
    const today = moment();
    const yesterday = moment().subtract(1, 'day');
    const todayLabel = I18n.t('date.today');
    const yesterdayLabel = I18n.t('date.yesterday');

    if (date.isSame(today, 'day')) {
      if (result.isPrintResult()) {
        return todayLabel;
      } else {
        return `${todayLabel} ${date.format('LT')}`;
      }
    } else if (date.isSame(yesterday, 'day')) {
      if (result.isPrintResult()) {
        return yesterdayLabel;
      } else {
        return `${yesterdayLabel} ${date.format('LT')}`;
      }
    } else {
      if (showTimeInPast) {
        if (result.isPrintResult()) {
          return date.format('dd l');
        } else {
          return date.format('dd l LT');
        }
      } else {
        return date.format('dd l');
      }
    }
  }
}
