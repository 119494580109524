import ArchiveDate from '../ArchiveDate';
import Sidebar from '../Sidebar';
import * as _ from 'underscore';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

export interface ArchiveDateDropdownProps {
  archiveDates: ArchiveDate[];
}

export default class ArchiveDateDropdown extends React.Component<
  ArchiveDateDropdownProps,
  {}
> {
  render() {
    let value = this.sidebar().currentArchiveDate().id.toString();
    return (
      <div>
        <select
          aria-label={I18n.t('navigation.selectIssue')}
          onChange={this.handleChange.bind(this)}
          value={value}
          className="archive__date__dropdown"
        >
          {this.items()}
          <option value="nothing" className="invisible">
            {this.dummyOptionText()}
          </option>
        </select>
      </div>
    );
  }

  handleChange(event: React.ChangeEvent<any>) {
    let ad = _.find(this.props.archiveDates, (ad: ArchiveDate) => {
      return ad.id.toString() == event.target.value;
    });
    if (ad === undefined) {
      throw 'ad undefined';
    }
    this.sidebar().archiveDateSwitchedCallback(ad);
  }

  sidebar(): Sidebar {
    return window.app.dataStore.sidebar;
  }

  items() {
    let items: JSX.Element[] = [];
    _.each(this.props.archiveDates, (archiveDate) => {
      let formatted = this.sidebar().formatArchiveDate(archiveDate);
      let isSelected = archiveDate.id == this.sidebar().currentArchiveDate().id;
      let klass = isSelected ? 'isSelected' : '';

      items.push(
        <option
          key={archiveDate.id.toString()}
          value={archiveDate.id.toString()}
          className={klass}
        >
          {formatted}
        </option>
      );
    });
    return items;
  }

  // Generate a long dummy option text to workaround some weird
  // Chrome 53 probem
  dummyOptionText(): string {
    let length = _.max(
      this.props.archiveDates.map((ad) => {
        return this.sidebar().formatArchiveDate(ad).length;
      })
    );
    return '_'.repeat(length + 3);
  }
}
