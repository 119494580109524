import { ReaderCategory as ReaderCategoryResponse } from './api/response/ReaderCategory';
import ReaderResults from './ReaderResults';

export default class ReaderCategory {
  public readerResults: ReaderResults;
  public name: string;
  public id: number;
  public position: number;
  public onlyImportantResults: boolean;

  constructor(readerCategory: ReaderCategoryResponse) {
    this.name = readerCategory.name;
    this.id = readerCategory.id;
    this.position = readerCategory.position;
    this.onlyImportantResults = readerCategory.only_important_results;
    this.readerResults = new ReaderResults([], 0, true);
  }
}
