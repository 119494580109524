import { StatusBar, Style } from '@capacitor/status-bar';
import ColorHandler from './ColorHandler';
import Environment from './Environment';

export default class BrowserChrome {
  setColor(color: string) {
    this.setChromeThemeColor(color);
    if (
      Environment.platform() === 'android' ||
      Environment.platform() === 'ios'
    ) {
      this.setStatusBarColor(color);
    } else {
      this.setStatusBarColor(this.darken(color));
    }
  }

  setChromeThemeColor(color: string) {
    $('[data-status-bar-color]').attr('content', color);
  }

  // FIXME: The logic of this method is somewhat obscure, especially the
  // newColor for iOS. It show be revised and adjusted to the current platform
  // and status-bar API capabilities.
  setStatusBarColor(color: string) {
    if (Environment.isNativeApp()) {
      if (Environment.platform() === 'android') {
        // On Android, the status bar text color cannot be
        // changed, so we use a fixed status bar color.
        StatusBar.setBackgroundColor({ color: '#484848' });
      } else if (Environment.platform() === 'ios') {
        const newColor = '#ff' + color.substr(1);
        StatusBar.setBackgroundColor({ color: newColor });
        StatusBar.setStyle({ style: Style.Default });
      } else {
        const colorHandler = new ColorHandler();
        StatusBar.setBackgroundColor({ color: colorHandler.toHex(color) });
      }
    }
  }

  setStatusBarStyle(style: 'default' | 'light') {
    if (Environment.isNativeApp()) {
      StatusBar.setOverlaysWebView({ overlay: false });
      if (style === 'default') {
        StatusBar.setStyle({ style: Style.Default });
        if (Environment.platform() === 'ios') {
          StatusBar.setBackgroundColor({ color: '#fff4f4f4' });
        }
      } else {
        StatusBar.setStyle({ style: Style.Light });
        if (Environment.platform() === 'ios') {
          StatusBar.setBackgroundColor({ color: '#ff4c4c4c' });
        }
      }
    }
  }

  transparentStatusBar(style: 'default' | 'light') {
    if (Environment.isNativeApp()) {
      this.setStatusBarStyle(style);
      if (Environment.platform() !== 'ios') {
        StatusBar.setOverlaysWebView({ overlay: true });
      }
    }
  }

  private darken(color: string): string {
    const handler = new ColorHandler();
    return handler.darkenHex(color, 0.85);
  }
}
