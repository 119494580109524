import { NotificationListener, PushNotificationData } from '../Notifications';
import App from '../../App';
import * as _ from 'underscore';

export default class ReaderSettingsChangeListener
  implements NotificationListener
{
  private handler: Function;

  constructor(private app: App) {
    this.app = app;
    this.handler = _.throttle(
      () => {
        window.app.dataStore.onNotificationSettingsChanged();
      },
      1000,
      { leading: false }
    );
  }

  run({ event_type, configuration_id }: PushNotificationData) {
    if (
      event_type === 'push_notification_settings_changed' &&
      this.app.currentlyActiveConfig().id === Number(configuration_id)
    ) {
      this.handler();
    }
  }
}
