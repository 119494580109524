import AccessToken from './AccessToken';

export default class AccessibleReader {
  constructor(
    public id: number,
    public title: string,
    public client: string,
    public token: string
  ) {}

  accessToken(): AccessToken {
    return new AccessToken(this.token);
  }
}
