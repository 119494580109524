import AccessToken from './AccessToken';
import RequestUri from './api/RequestUri';

/** Logs the reader session start to the backend */
export default class LogReaderSession {
  private token: AccessToken;
  private requestUri: RequestUri;

  constructor(s: AccessToken) {
    this.token = s;
    this.requestUri = new RequestUri();
  }

  perform() {
    $.post(this.requestUri.build('reader_session'), {
      reader_token: this.token.toString(),
    });
  }
}
