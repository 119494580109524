import * as _ from 'underscore';

export default class Source {
  url?: string;
  pdf?: string;
  info?: string;
  reachCount?: number;
  videoId?: string;
  imageUrl?: string;

  constructor(
    public title: string,
    public clipUrl: string | undefined,
    public kind: string,
    reach: number,
    public circulationDistributed: number | undefined,
    public thumbnailUrl: string | undefined,
    public downloadable: boolean,
    public pageNumber: string | undefined,
    public isPrintSource: boolean,
    public isRtv: boolean,
    public image: string,
    public mediaKind?: string
  ) {
    if (reach != undefined && this.displayReach(reach)) {
      var formattedReach = I18n.toNumber(reach, { precision: 0 });
      this.info = I18n.translate('detail_view.visitors_per_month', {
        reach: formattedReach,
      });
    }

    if (reach) {
      // for RTV clips reach seems to be a string so we parse it into an int
      this.reachCount = parseInt(`${reach}`);
    }

    if (this.isPrintSource && this.downloadable) {
      this.pdf = this.pdfUrl();
      this.url = undefined;
    } else {
      this.pdf = undefined;
      this.url = this.clipUrl;
      if (this.clipUrl !== undefined && this.isVideoSource()) {
        this.videoId = this.extractVideoId(this.clipUrl);
      }
    }

    if (this.isInstagramClip()) {
      this.imageUrl = image;
    }
  }
  private displayReach(reach: number) {
    return !_.contains(['MMOClip', 'ImportedClip'], this.kind) && reach > 0;
  }
  private pdfUrl() {
    return '#';
  }
  private vimeoRegExp =
    /^https?:\/\/(?:\S+.)?vimeo.com\/(?:(?:video\/)|(?:channels\/.*\/))?(\d+)(?:[#?].*)?$/;
  private videoRegExp =
    /^https?:\/\/(?:\S+.)?vimeo.com\/(?:(?:video\/)|(?:channels\/.*\/))?(\d+)(?:[#?].*)?$|^https?:\/\/www\.youtube\.com\/watch\?(?:.*&)?v=([-\w]+)(?:[#&].*)?$/;
  private youtubeRegExp =
    /^https?:\/\/www\.youtube\.com\/watch\?(?:.*&)?v=([-\w]+)(?:[#&].*)?$/;
  private isVideoSource(): boolean {
    let kindP =
      this.kind === 'ImportedClip' ||
      this.kind === 'RtvClip' ||
      this.isYoutubeClip();

    let clipUrlP = this.clipUrl !== undefined;

    let videoRegExpP =
      this.videoRegExp != undefined &&
      this.clipUrl !== undefined &&
      !_.isNull(this.clipUrl.match(this.videoRegExp));

    return kindP && clipUrlP && videoRegExpP;
  }

  private isInstagramClip(): Boolean {
    return (
      this.kind == 'SMTInstagramVideoClip' ||
      this.kind == 'SMTInstagramImageClip'
    );
  }

  isYoutubeClip(): Boolean {
    if (this.kind === 'SMTYoutubeVideoClip') {
      return true;
    }
    return (
      this.clipUrl !== undefined && !!this.clipUrl.match(this.youtubeRegExp)
    );
  }

  isVimeoClip(): Boolean {
    if (this.clipUrl !== undefined) {
      return !!this.clipUrl.match(this.vimeoRegExp);
    } else {
      return false;
    }
  }

  isInstagramVideo(): Boolean {
    return this.kind == 'SMTInstagramVideoClip';
  }

  isInstagramImage(): Boolean {
    return this.kind == 'SMTInstagramImageClip';
  }
  private extractVideoId(url: String): string | undefined {
    var matchData: RegExpMatchArray | null = url.match(this.videoRegExp);
    if (matchData != null) {
      let filtered: string[] = _.filter(matchData, (match: string) => {
        return !_.isUndefined(match);
      });
      let videoId: string | null = _.last(filtered) || null;
      if (videoId != null) {
        return videoId;
      } else {
        return undefined;
      }
    }
  }
}
