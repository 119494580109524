import { IIndexedDatabase } from './IIndexedDatabase';
import { Settings } from './Settings';
import { Config as ConfigResponse } from './api/response/Config';
import IDBConfiguration from './IDBRecord/Configuration';
import { ArchiveDate as ArchiveDateRespones } from './api/response/ArchiveDate';
import { ArchiveDateContents as ArchiveDateContentsResponse } from './api/response/ArchiveDateContents';
import { ReaderResults as ReaderResultsResponse } from './api/response/ReaderResults';
import { ArchiveDate as ArchiveDateResponse } from './api/response/ArchiveDate';
import { ReaderCategory as ReaderCategoryResponse } from './api/response/ReaderCategory';
import { ArchiveDateSummary as ArchiveDateSummaryResponse } from './api/response/ArchiveDateSummary';
import IDBArchiveDate from './IDBRecord/ArchiveDate';
import IDBResult from './IDBRecord/Result';
import IDBArchiveDateSummary from './IDBRecord/ArchiveDateSummary';
import IDBCategory from './IDBRecord/Category';
import { IIDBRecord } from './IDBRecord/IIDBRecord';
import Utils from './Utils';
import * as _ from 'underscore';

// A wrapper service around IndexedDatabase.
// Adds an interface for storing and retrieving data from the
// IndexedDatabase, this way reader can be used also offline.
//
// The results are refreshed on every call to the API, meaning the
// stored data gets always replaced, to guarantee the latest data
// on local storage.
//
// If browser does not support IndexedDB, see DummyDB for the dummy
// implementation.
export default class ReaderDB implements IIndexedDatabase {
  private static dbName: string = 'ReaderDB';
  private static dbVersion: number = 3;
  private db?: IDBDatabase;

  initializeDatabase(): JQueryPromise<void> {
    let deferred = $.Deferred<void>();
    let indexedDB: IDBFactory = window.indexedDB || window.shimIndexedDB;
    let request: IDBOpenDBRequest = indexedDB.open(
      ReaderDB.dbName,
      ReaderDB.dbVersion
    );

    request.onerror = (e: Event) => {
      deferred.reject(request.error);
    };
    request.onsuccess = (e: Event) => {
      this.db = request.result;
      deferred.resolve();
    };
    // DB version has changed, update.
    request.onupgradeneeded = (e: Event) => {
      this.db = request.result as IDBDatabase;

      if (!this.db.objectStoreNames.contains('configurations')) {
        this.db.createObjectStore('configurations', { keyPath: 'identifier' });
      }

      if (!this.db.objectStoreNames.contains('archiveDates')) {
        this.db.createObjectStore('archiveDates', { keyPath: 'identifier' });
      }

      if (!this.db.objectStoreNames.contains('archiveDateSummaries')) {
        this.db.createObjectStore('archiveDateSummaries', {
          keyPath: 'identifier',
        });
      }

      if (!this.db.objectStoreNames.contains('categories')) {
        this.db.createObjectStore('categories', { keyPath: 'identifier' });
      }

      if (!this.db.objectStoreNames.contains('results')) {
        this.db.createObjectStore('results', { keyPath: 'identifier' });
      }
    };
    return deferred.promise();
  }

  saveNotificationSettings(
    token: string,
    settings: Settings
  ): JQueryPromise<ConfigResponse> {
    return this.loadConfigurations(token).then((config: ConfigResponse) => {
      let configResponse = config;
      configResponse.email_notifications_enabled =
        settings.emailNotificationsEnabled;
      configResponse.push_notifications_enabled =
        settings.pushNotificationsEnabled;
      return this.updateConfigurations(configResponse, token);
    });
  }

  loadConfigurations(token: string): JQueryPromise<ConfigResponse> {
    return this.initializeDatabase().then(() => {
      let objectStore = this.getObjectStore('configurations', 'readonly');
      return IDBConfiguration.findByToken(token, objectStore);
    });
  }

  loadConfigurationAndArchiveDates(
    token: string
  ): JQueryPromise<ConfigResponse> {
    return this.loadConfigurations(token).then((config: ConfigResponse) => {
      return this.loadArchiveDates(config.id).then(
        (ad: ArchiveDateResponse[]) => {
          config.archive_dates = ad;
          return config;
        }
      );
    });
  }

  loadArchiveDateContents(
    configId: number,
    archiveDateId: number
  ): JQueryPromise<ArchiveDateContentsResponse> {
    const deferred = $.Deferred<ArchiveDateContentsResponse>();
    const summaryPromise = this.loadArchiveDateSummary(configId, archiveDateId);
    let resultsPromises: JQueryPromise<ReaderResultsResponse>[];
    this.loadCategories(configId, archiveDateId)
      .done((categories: ReaderCategoryResponse[]) => {
        resultsPromises = _.map(
          categories,
          (category: ReaderCategoryResponse) => {
            return this.loadReaderResults(
              configId,
              archiveDateId,
              category.id
            ).then((results) => {
              category.results = results;
              return results;
            });
          }
        );
        $.when(summaryPromise, ...resultsPromises)
          .done((summary: ArchiveDateSummaryResponse, ...results: any[]) => {
            const contents: ArchiveDateContentsResponse = {
              summary: summary,
              categories: categories,
            };
            deferred.resolve(contents);
          })
          .fail(() => {
            deferred.reject();
          });
      })
      .fail(() => {
        deferred.reject();
      });
    return deferred;
  }

  loadArchiveDates(configId: number): JQueryPromise<ArchiveDateResponse[]> {
    return this.initializeDatabase().then(() => {
      let objectStore = this.getObjectStore('archiveDates', 'readonly');
      return IDBArchiveDate.findByConfigId(configId, objectStore);
    });
  }

  lastArchiveDateUpdate(configId: number): JQueryPromise<number> {
    return this.initializeDatabase().then(() => {
      let objectStore = this.getObjectStore('archiveDates', 'readonly');
      return IDBArchiveDate.lastUpdatedAt(configId, objectStore);
    });
  }

  lastReaderResultUpdate(
    configId: number,
    archiveDateId: number,
    categoryId: number
  ): JQueryPromise<number> {
    return this.initializeDatabase().then(() => {
      let objectStore = this.getObjectStore('readerResults', 'readonly');
      return IDBResult.lastUpdatedAt(
        configId,
        archiveDateId,
        categoryId,
        objectStore
      );
    });
  }

  loadArchiveDateSummary(
    configId: number,
    archiveDateId: number
  ): JQueryPromise<ArchiveDateSummaryResponse> {
    return this.initializeDatabase().then(() => {
      let objectStore = this.getObjectStore('archiveDateSummaries', 'readonly');
      return IDBArchiveDateSummary.findByConfigIdAndArchiveDateId(
        configId,
        archiveDateId,
        objectStore
      );
    });
  }

  loadCategories(
    configId: number,
    archiveDateId: number
  ): JQueryPromise<ReaderCategoryResponse[]> {
    return this.initializeDatabase().then(() => {
      let objectStore = this.getObjectStore('categories', 'readonly');
      return IDBCategory.findByConfigIdAndArchiveDateId(
        configId,
        archiveDateId,
        objectStore
      );
    });
  }

  loadReaderResults(
    configId: number,
    archiveDateId: number,
    categoryId: number
  ): JQueryPromise<ReaderResultsResponse> {
    return this.initializeDatabase().then(() => {
      let objectStore = this.getObjectStore('results', 'readonly');
      return IDBResult.findByConfigIdAndArchiveDateIdAndCategoryId(
        configId,
        archiveDateId,
        categoryId,
        objectStore
      );
    });
  }

  updateConfigurations(
    config: ConfigResponse,
    token: string
  ): JQueryPromise<void> {
    return this.initializeDatabase().then(() => {
      let configRecord = new IDBConfiguration(config, token);
      return this.updateRecord(configRecord, 'configurations');
    });
  }

  updateArchiveDates(
    archiveDates: ArchiveDateResponse[],
    configId: number
  ): JQueryPromise<void> {
    return this.initializeDatabase().then(() => {
      let archiveDateRecord = new IDBArchiveDate(
        archiveDates,
        configId,
        moment().valueOf()
      );
      return this.updateRecord(archiveDateRecord, 'archiveDates');
    });
  }

  updateArchiveDateSummary(
    summary: ArchiveDateSummaryResponse,
    configId: number,
    archiveDateId: number
  ): JQueryPromise<void> {
    return this.initializeDatabase().then(() => {
      let archiveDateRecord = new IDBArchiveDateSummary(
        summary,
        configId,
        archiveDateId
      );
      return this.updateRecord(archiveDateRecord, 'archiveDateSummaries');
    });
  }

  updateCategories(
    categories: ReaderCategoryResponse[],
    configId: number,
    archiveDateId: number
  ): JQueryPromise<void> {
    return this.initializeDatabase().then(() => {
      let categoryRecord = new IDBCategory(categories, configId, archiveDateId);
      return this.updateRecord(categoryRecord, 'categories');
    });
  }

  updateReaderResults(
    results: ReaderResultsResponse,
    configId: number,
    archiveDateId: number,
    categoryId: number
  ): JQueryPromise<void> {
    return this.initializeDatabase().then(() => {
      let resultRecord = new IDBResult(
        results,
        configId,
        archiveDateId,
        categoryId,
        moment().valueOf()
      );
      return this.updateRecord(resultRecord, 'results');
    });
  }

  updateArchiveDateContents(
    contents: ArchiveDateContentsResponse,
    configId: number,
    archiveDateId: number
  ): JQueryPromise<void> {
    const summary = contents.summary;
    const categories = contents.categories;
    const summaryPromise = this.updateArchiveDateSummary(
      summary,
      configId,
      archiveDateId
    );
    const categoriesPromise = this.updateCategories(
      categories,
      configId,
      archiveDateId
    );
    const resultsPromises = _.map(categories, (category) => {
      return this.updateReaderResults(
        category.results,
        configId,
        archiveDateId,
        category.id
      );
    });
    return $.when(summaryPromise, categoriesPromise, ...resultsPromises);
  }

  invalidateConfiguration(token: string | null): void {
    if (Utils.isPresent(token)) {
      let objectStore = this.getObjectStore('configurations', 'readwrite');
      objectStore.delete(<string>token);
    }
  }

  private updateRecord(record: any, storeName: string): JQueryPromise<void> {
    let deferred = $.Deferred<void>();
    let objectStore = this.getObjectStore(storeName, 'readwrite');

    this.addOrPutToObjectStore(objectStore, record, deferred);
    return deferred.promise();
  }

  private addOrPutToObjectStore(
    objectStore: IDBObjectStore,
    obj: IIDBRecord,
    d: JQueryDeferred<void>
  ) {
    let objectStoreRequest: IDBRequest = objectStore.put(obj);
    this.registerTransactionCallbacks(d, objectStoreRequest);
  }

  private getObjectStore(
    storeName: string,
    mode: 'readwrite' | 'readonly' | 'versionchange' | undefined
  ): IDBObjectStore {
    if (this.db === undefined) {
      throw 'no DB set';
    }
    let transaction: IDBTransaction = this.db.transaction(storeName, mode);
    return transaction.objectStore(storeName);
  }

  private registerTransactionCallbacks(d: JQueryDeferred<void>, r: IDBRequest) {
    r.onerror = (e: Event) => {
      d.reject(r.error);
    };
    r.onsuccess = (e: Event) => {
      d.resolve(r.result);
    };
  }
}
