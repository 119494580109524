import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as _ from 'underscore';
import { Capacitor } from '@capacitor/core';
import { App as CapacitorApp } from '@capacitor/app';
import OfflineSettings from './OfflineSettings';
import DummyStorage from './DummyStorage';
import DataLoader from './DataLoader';
import OfflineAndMobileDataUpdateService from './OfflineAndMobileDataUpdateService';
import Navigation from './Navigation';
import MessagePusher from './MessagePusher';
import Layout from './Layout';
import Config from './Config';
import { Timer as AnalyticsTimer } from './Analytics';
import Visibility from './Visibility';
import ReaderResultWatch from './ReaderResultWatch';
import UrlParamParser from './UrlParamParser';
import LinkOpener from './LinkOpener';
import ScrollingHandler from './ScrollingHandler';
import DataStore from './DataStore';
import OfflineHandler from './mobile/OfflineHandler';
import ReactLayout from './ReactLayout';
import TokenManager from './TokenManager';
import { VimeoMetadataCacheDepot } from './VimeoClient';
import FontsLoadedChecker from './FontsLoadedChecker';
import Analytics from './Analytics';
import AccessToken from './AccessToken';
import ArchiveDate from './ArchiveDate';
import ReaderCategory from './ReaderCategory';
import ArchiveDateSummary from './ArchiveDateSummary';
import { NavigationComponentProps } from './components/NavigationComponent';
import NavigationComponent from './components/NavigationComponent';
import ActivationToken from './ActivationToken';
import FailureReport from './FailureReport';
import PdfDownloadManager from './PdfDownloadManager';
import AccessibleReader from './AccessibleReader';
import { UpdatingResultsProps } from './components/UpdatingResults';
import Scroller from './Scroller';
import { Settings } from './Settings';
import ReaderResults from './ReaderResults';
import { ResultsByCategoryId } from './ResultsByCategoryId';
import ReaderResult from './ReaderResult';
import SummaryResult from './SummaryResult';
import { DetailListProps } from './components/DetailList';
import DetailList from './components/DetailList';
import { AccessKind } from './api/Client';
import { ClientContext } from './ApiClient';
import ApiClient from './ApiClient';
import ReaderLocale from './ReaderLocale';
import Environment from './Environment';
import LogReaderSession from './LogReaderSession';
import ReaderTokenInit from './ReaderTokenInit';
import MobileNotifications from './mobile/Notifications';
import MobileResumeHandler from './mobile/ResumeHandler';
import MobileScrollToResultListener from './mobile/notificationListeners/ScrollToResultListener';
import MobileArchiveDatePublishedListener from './mobile/notificationListeners/ArchiveDatePublishedListener';
import MobileArchiveDateResultChangeListener from './mobile/notificationListeners/ArchiveDateResultChangeListener';
import MobileReaderResultChangeListener from './mobile/notificationListeners/ReaderResultChangeListener';
import MobileReaderSettingsChangeListener from './mobile/notificationListeners/ReaderSettingsChangeListener';
import ErrorScreen from './components/ErrorScreen';
import AppOutdatedView from './components/AppOutdatedView';
import ReaderActivation from './ReaderActivation';
import { Activation as ActivationResponse } from './api/response/Activation';

// Custom URL scheme handler
CapacitorApp.addListener('appUrlOpen', ({ url }) => {
  const match = url.match(/^brreader:\/\/\?activationtoken=([0-9A-Z]+)$/);
  if (match) {
    const activationToken = new ActivationToken(match[1]);
    window.app.activateWithToken(activationToken);
  }
});

export interface AfterResultRenderCallback {
  (): void;
}

export default class App {
  public offlineSettings: OfflineSettings;
  public dummyStorage: DummyStorage;
  public id: string;
  public name: string;
  public version: string;
  public deviceReadyPromise: JQueryPromise<void>;
  public agentResultQueryLimit = 200;
  public dataLoader: DataLoader;
  private dataUpdateService: OfflineAndMobileDataUpdateService;
  public urlParams: any;
  public navigation: Navigation;
  private pusher: MessagePusher;
  private afterResultRenderCallbacks: Array<AfterResultRenderCallback> = [];
  public config: Config;
  private startupTimer: AnalyticsTimer;
  private startupTimeTracked = false;
  private loggingTimerId: number;
  private visibility: Visibility;
  private readerResultWatcher: ReaderResultWatch;
  private urlParamParser: UrlParamParser;
  public linkOpener: LinkOpener;
  private readerTokenInit: ReaderTokenInit;
  private mobileNotifications: MobileNotifications;
  public navigationInitialized: JQueryDeferred<App>;
  private scrollingHandler: ScrollingHandler;
  private mobileResumeHandler: MobileResumeHandler;
  public dataStore: DataStore;
  private offlineHandler: OfflineHandler;
  public fontsLoaded: JQueryPromise<void>;
  public reactLayout: ReactLayout;
  public tokenManager: TokenManager;
  private activeConfig: Config;

  constructor(name: string, version: string, id: string) {
    window.app = this;
    window.canShowOfflineNotification = true;
    VimeoMetadataCacheDepot.init();
    this.name = name;
    this.version = version;
    this.id = id;
    this.offlineSettings = new OfflineSettings();
    this.offlineHandler = new OfflineHandler();
    this.urlParamParser = new UrlParamParser();
    this.startupTimer = new AnalyticsTimer('Load times', 'Startup time');
    this.urlParams = {};
    this.navigationInitialized = $.Deferred();
    this.fontsLoaded = new FontsLoadedChecker().promise();
    window.scrollingHandler = new ScrollingHandler();
    this.dummyStorage = new DummyStorage();
    this.tokenManager = new TokenManager();
    ReaderLocale.customizeLocales();
    Environment.deviceReady().done(() => {
      this.initMobileOfflineHandling();
      // prevent change to landscape mode on Android phones
      // see https://bluereport.atlassian.net/browse/DEV-1239
      if (Capacitor.getPlatform() === 'android' && $(window).width() < 700) {
        window.screen.orientation.lock('portrait');
      }
    });
  }

  static reload() {
    window.location.reload();
  }

  private accessTokenPresentHandler(token: AccessToken) {
    this.maybeInitMobileNotifications(token);
    new LogReaderSession(token).perform();
    this.startReaderApp();
  }

  renderReactNavigation(opts: { isLoading: boolean }) {
    $.when(
      this.dataStore.getResults(),
      this.dataStore.getArchiveDates(),
      this.dataStore.getCategories(),
      this.dataStore.getSummary(),
      this.dataStore.getConfig()
    ).done(
      (
        results: any,
        archiveDates: ArchiveDate[],
        categories: ReaderCategory[],
        summary: ArchiveDateSummary,
        config: Config
      ) => {
        this.activeConfig = config;
        let props: NavigationComponentProps = {
          archiveDates: archiveDates,
          summary: summary,
          config: config,
          isLoading: opts.isLoading,
          newClips: false,
          download: false,
          categories: categories,
          readerResults: results,
          searchResults: undefined,
        };

        let component = React.createElement(NavigationComponent, props);
        let cnt = document.getElementById('navigation');
        if (cnt != null) {
          ReactDOM.render(component, cnt);
        } else {
          throw new Error('Could not find #navigation in the DOM');
        }
      }
    );
  }

  private formatArchiveDate(archiveDate: ArchiveDate): string {
    let date = archiveDate.date();
    let today = moment();
    let yesterday = moment().subtract(1, 'day');
    let todayLabel = I18n.t('date.today');
    let yesterdayLabel = I18n.t('date.yesterday');
    let fmt = 'DD.MM.YYYY';

    if (date.isSame(today, 'day')) {
      return todayLabel + ', ' + date.format(fmt);
    } else if (date.isSame(yesterday, 'day')) {
      return yesterdayLabel + ', ' + date.format(fmt);
    } else {
      return date.format('ddd, ' + fmt);
    }
  }

  // TODO: https://red.intra.cognita.ch/issues/11657
  private addIosRubberBandFix() {
    if (Environment.isNativeApp()) {
      $(document).ready(() => {
        Environment.deviceIsReady().done(() => {
          $(document).on('touchmove', function (e) {
            e.preventDefault();
          });
          // Allow native scrolling for iOS (see #14672)
          $('body').on('touchmove', '.enable-ios-scrolling', function (e) {
            e.stopPropagation();
          });
        });
      });
    }
  }

  private maybeInitMobileNotifications(accessToken: AccessToken) {
    if (Environment.isNativeApp()) {
      this.mobileNotifications = new MobileNotifications(accessToken, this);
      this.navigationInitialized.done((app?: App) => {
        if (app) {
          this.mobileNotifications.addListeners([
            new MobileScrollToResultListener(app),
            new MobileArchiveDatePublishedListener(app),
            new MobileArchiveDateResultChangeListener(app),
            new MobileReaderResultChangeListener(app),
            new MobileReaderSettingsChangeListener(app),
          ]);
        }
        this.mobileNotifications.startListening();
      });
    }
  }

  public activateWithToken(activationToken: ActivationToken) {
    var readerActivation = new ReaderActivation(activationToken);
    readerActivation
      .getAccessToken()
      .done((data: ActivationResponse) => {
        window.setTimeout(() => {
          let accessToken = new AccessToken(data.reader_token);
          this.tokenManager.store(accessToken);
          this.accessTokenPresentHandler(accessToken);
        }, 300);
      })
      .fail((data: any) => {
        console.error('Error activating with token.');
      });
  }

  start(activationToken: ActivationToken | undefined) {
    ReaderLocale.setLocaleFromBrowser();
    FailureReport.initReporting();
    FailureReport.addPayload({
      platform: Environment.platform(),
      appName: this.name,
    });
    FailureReport.addPayload({
      appVersion: this.version,
      touchDevice: Environment.isTouchDevice(),
    });
    this.readerTokenInit = new ReaderTokenInit((token: AccessToken) => {
      this.accessTokenPresentHandler(token);
    });
    // TODO: Why is Visibility initialized twice?
    this.visibility = new Visibility(this);
    this.visibility.addListener();
    this.readerTokenInit.startReader(activationToken);
  }

  private initializeMobileResumeHandler() {
    if (Environment.isNativeApp()) {
      this.mobileResumeHandler = new MobileResumeHandler(
        this.dataUpdateService,
        this.dataLoader,
        this.config
      );
      this.mobileResumeHandler.startListening();
    }
  }

  private startReaderApp() {
    document.title = 'Loading';
    let self = this;
    this.dataStore = new DataStore();
    this.dataLoader = new DataLoader();
    this.dataUpdateService = new OfflineAndMobileDataUpdateService(this);
    this.visibility = new Visibility(this);
    this.visibility.addListener();

    this.urlParams = this.urlParamParser.deparam(window.location.search);
    this.readerResultWatcher = new ReaderResultWatch();

    this.dataStore.refreshConfigAndArchiveDates().done(() => {
      $.when(this.dataStore.getConfig()).done((config: Config) => {
        FailureReport.addPayload({
          client: config.client_id,
          config: config.id,
          token: this.tokenManager.getToken() || '',
        });
        this.activeConfig = config;
        if (Environment.isNativeApp()) {
          if (config.isMobileVersionOutdated(this.version)) {
            Layout.hideLoadingScreen();
            this.showMobileVersionOutdatedScreen();
          } else {
            this.startReader(config);
          }
        } else {
          this.startReader(config);
        }
      });
    });

    // TODO: https://red.intra.cognita.ch/issues/11658
    new PdfDownloadManager(this).start();
    this.linkOpener = new LinkOpener(this);
    this.linkOpener.start();
    Layout.addPlatformToBody();
    Layout.addIEToBody();
    Layout.addAppNameToBody();
    this.onAfterResultRender(() => {
      window.scrollingHandler.onContentChanged();
      window.scrollingHandler.updateVisibleItemPositionCache();
      this.navigationInitialized.resolve(this);
    });
    $(window).resize(() => {
      window.scrollingHandler.handleResize();
    });
  }

  public currentlyActiveConfig(): Config {
    return this.activeConfig;
  }

  private startReader(config: Config) {
    Layout.showLoadingScreen();
    this.addIosRubberBandFix();
    config.applyUserGroupBranding();
    this.initializeMobileResumeHandler();
    Analytics.setup(this.config);
    Analytics.sendPageview();
    let token = this.tokenManager.getToken();
    if (Environment.useMessagePusher()) {
      const initializePusher = (token: string) => {
        this.pusher = new MessagePusher(token, this.config.id, this);
      };
      if (token != null) {
        initializePusher(token);
      } else {
        this.tokenManager.tokenPromise().done(initializePusher);
      }
    }
    this.loadReactApp();
  }

  private showMobileVersionOutdatedScreen() {
    let component = React.createElement(AppOutdatedView, {});
    let cnt = document.getElementById('container');
    if (cnt != null) {
      ReactDOM.render(component, cnt);
    }
  }

  loadReactApp() {
    let self = this;
    if (!this.reactLayout) {
      this.reactLayout = new ReactLayout();
    }
    $.when(
      this.dataStore.getConfig(),
      this.dataStore.getAccessibleReaders(),
      this.dataStore.getArchiveDates()
    ).done(
      (
        config: Config,
        accessibleReaders: AccessibleReader[],
        archiveDates: ArchiveDate[]
      ) => {
        this.navigation = new Navigation(self, config, archiveDates);
        // TODO: put the missing stuff from loadApp here
        ReaderLocale.setLocale(this.config.locale);
        $(document).ready(function () {
          document.title = config.title;
          document.documentElement.lang = I18n.locale;
        });

        // handle reader_id query parameter
        const readerIdParam = parseInt(this.urlParams.readerId);
        if (readerIdParam && !this.tokenManager.tokenGiven()) {
          // if the query parameter equals the ID of the currently active reader
          // nothing needs to be done, otherwise we either switch to an accessible
          // reader or render an "access denied" error screen
          if (readerIdParam !== config.id) {
            const ar = accessibleReaders.find((r) => r.id === readerIdParam);
            if (ar) {
              this.tokenManager.replaceToken(ar.accessToken());
              window.location.reload();
            } else {
              this.showErrorScreen({
                title: I18n.t('error.access_denied.headline'),
                message: I18n.t('error.access_denied.description'),
              });
              Layout.hideLoadingScreen();
              return;
            }
          }
        }

        var app = this;
        try {
          this.dataStore.setCurrentArchiveDate(archiveDates);
        } catch (e) {
          this.showErrorScreen({
            title: I18n.t('error.no_issues_available_headline'),
            message: I18n.t('error.no_issues_available_description'),
          });
          Layout.hideLoadingScreen();
        }
        if (this.dataLoader.offlineDataNeedsUpdate()) {
          this.dataUpdateService.updateOfflineReaderOnApplicationLoad();
        }

        if (!this.dataStore.hasCurrentArchiveDate()) {
          Layout.hideLoadingScreen();
          self.rerender();
        } else {
          let currentArchiveDate = this.dataStore.getCurrentArchiveDate();
          this.dataStore
            .switchToResultsInArchiveDate(currentArchiveDate)
            .done(() => {
              self.rerender().done(() => {
                Layout.hideLoadingScreen();
                app.dataStore.runAfterAllResultsLoadCallback();
                window.scrollingHandler.start();
                window.scrollingHandler.enableScrolling();
              });
            });
        }
      }
    );
  }

  rerender(): JQueryPromise<void> {
    if (this.reactLayout) {
      return this.reactLayout.renderLayoutComponent();
    } else {
      return $.Deferred<void>().resolve();
    }
  }

  pullToRefreshUpdate(): JQueryPromise<void> {
    const currentDate = this.getCurrentArchiveDate();
    const currentArchiveDateId = currentDate.id;
    const notificationProps: UpdatingResultsProps = {
      displayNotification: true,
    };
    Layout.renderUpdatingResults(notificationProps);
    this.fetchAndReRenderArchiveDatesWithConditionalSelect();
    return jQuery.when(
      this.refreshCurrentArchiveDateResults(currentArchiveDateId),
      this.dataStore.refreshAccessulbeReaders()
    );
  }

  // TODO: See above. Especially with the new data layer this could be
  //       somehow extracted away. Navigation should not need to track
  //       which archive date is currently selected.
  getCurrentArchiveDate(): ArchiveDate {
    return this.dataStore.getCurrentArchiveDate();
  }

  // DATA LOADING START

  /**
   *  Push notifications use this logic for newly published archive dates.
   *
   *  Updates the offline store with new archive dates and renders them
   *  depending on conditions.
   */
  fetchAndReRenderArchiveDatesWithConditionalSelect() {
    let self = this;
    return $.when(self.dataStore.refreshArchiveDates()).then(() => {
      self.dataStore.getArchiveDates().done((aDates: ArchiveDate[]) => {
        self.dataStore.reRenderArchiveDatesWithConditionalSelect(aDates);
      });
    });
  }

  /**
   * Used by scroll notification.
   */
  switchToLatestArchiveDateAndScrollToResult({
    result_id,
    archive_date_id,
  }: {
    archive_date_id: number;
    result_id: number;
  }) {
    return $.when(this.dataStore.refreshArchiveDates()).then(() => {
      this.dataStore.getArchiveDates().done((aDates: ArchiveDate[]) => {
        this.navigation.reRenderArchiveDatesAndSwitchToLatest(aDates);
        this.showNavigationLoadingIndicator();

        setTimeout(() => {
          Scroller.scrollToResult(result_id, archive_date_id, this, true);
        }, 500);
      });
    });
  }

  /**
   *  Push notifications use this logic for newly created results.
   *
   *  Updates the offline store with new reader results for current
   *  archive date. Renders the new results.
   *
   *  @param {number} archiveDateId - The id of given archive date
   */
  refreshCurrentArchiveDateResults(archiveDateId: number): JQueryPromise<void> {
    var archiveDate = this.dataStore.findArchiveDateById(archiveDateId);
    // Editorial archive date recognition.
    // * We cannot find the date, since it is not yet published.
    if (archiveDate === undefined) {
      return $().promise();
    }
    let wasCurrentArchiveDateUpdated =
      this.getCurrentArchiveDate().id == archiveDate.id;

    if (wasCurrentArchiveDateUpdated) {
      return this.dataUpdateService.refreshCurrentArchiveDateResults(
        wasCurrentArchiveDateUpdated
      );
    } else {
      return $().promise();
    }
  }

  /**
   *  Updates the Summary and returns the updated object.
   *
   *  @return ArchiveDateSummary - Updated summary object
   */
  public updateAndGetSummary(): JQueryPromise<ArchiveDateSummary> {
    const deferred = $.Deferred<ArchiveDateSummary>();

    $.when(this.dataStore.refreshArchiveDateSummary()).done(() => {
      this.dataStore.getSummary().done((summary: ArchiveDateSummary) => {
        deferred.resolve(summary);
      });
    });

    return deferred.promise();
  }

  private renderArchiveDateSummary(summary: ArchiveDateSummary) {
    this.rerender();
  }

  onSettingsChanged(settings: Settings): JQueryPromise<XMLHttpRequest> {
    return this.dataStore.saveNotificationSettings(settings);
  }

  public loadArchiveDates(): JQueryPromise<ArchiveDate[]> {
    const deferred = $.Deferred<ArchiveDate[]>();

    $.when(this.dataStore.refreshArchiveDates()).done(() => {
      this.dataStore.getArchiveDates().done((archiveDates: ArchiveDate[]) => {
        deferred.resolve(archiveDates);
      });
    });

    return deferred.promise();
  }

  loadReaderResults(
    archiveDate: ArchiveDate,
    category: ReaderCategory
  ): JQueryPromise<ReaderResults> {
    return this.dataLoader.loadResults(
      this.config,
      archiveDate,
      category,
      this.agentResultQueryLimit
    );
  }

  /**
   * Fetches always fresh results using OfflineAndMobileDataUpdateService.
   *
   * TODO: Stupid method name, made to separate the loadReaderResults which
   *       uses DataLoader. So this method here exists only to update the
   *       offline Store?
   */
  fetchMoreResults(
    category: ReaderCategory,
    archiveDate: ArchiveDate
  ): JQueryPromise<ReaderResults> {
    return this.dataStore.getResults().then((results) => {
      return results[category.id];
    });
  }

  // DATA LOADING END

  renderNewResults(
    archiveDate: ArchiveDate,
    category: ReaderCategory,
    results: ReaderResults
  ) {
    this.rerender();
    this.runAfterResultRenderCallbacks();
  }

  /**
   * Open all the categories for given archive date.
   * Important for scroll to a result notification.
   */
  openAllCategories(archiveDateId: number) {
    let archiveDate = this.dataStore.findArchiveDateById(archiveDateId);
    $.when(this.dataStore.getCategories()).done(
      (categories: ReaderCategory[]) => {
        _.each(categories, (category) => {
          window.scrollingHandler.openResultsForCategory(category.id);
        });
      }
    );
  }

  /** Runs after results of all categories have been loaded */
  afterAllResultsLoadCallback(
    archiveDate: ArchiveDate,
    categories: ReaderCategory[],
    categoryResults: ResultsByCategoryId
  ) {
    this.trackStartupTimeOnFirstCall();
    let allResults: (ReaderResult | SummaryResult)[] = [];
    categories.forEach((category) => {
      allResults = allResults.concat(categoryResults[category.id].collection);
    });
    this.newReaderResultsLoaded(allResults); // ??

    let self = this;
    this.rerender().done(() => {
      self.runAfterResultRenderCallbacks();
    });

    this.maybeScrollToResultGivenInUrl(allResults);
  }

  initMobileOfflineHandling() {
    if (Environment.isNativeApp()) {
      let offlineHandler = () => {
        this.onMobileOffline();
      };
      let onlineHandler = () => {
        this.onMobileOnline();
      };
      this.offlineHandler.setOfflineHandler(offlineHandler);
      this.offlineHandler.setOnlineHandler(onlineHandler);
      this.offlineHandler.start();
    }
  }

  onMobileOffline() {
    window.isMobileOffline = true;
  }

  onMobileOnline() {
    const dataStoreExists = !_.isUndefined(this.dataStore);
    if (dataStoreExists) {
      this.offlineSettings.persist();
    }
    window.isMobileOffline = false;
  }

  /** Scrolls to result provided in url params (archive_date_id, result_id)
   * if it is found in the provided results array
   */
  maybeScrollToResultGivenInUrl(
    results: Array<ReaderResult | SummaryResult>
  ): void {
    var resultId = this.urlParams.resultId;
    var isFound = _.find(results, (r: ReaderResult | SummaryResult) => {
      return r.id == resultId;
    });
    var archiveDateId = this.dataStore.getCurrentArchiveDate().id;

    if (resultId && isFound) {
      var scroller = new Scroller();
      scroller.scrollToReaderResult(resultId, archiveDateId, this);
    }
  }

  markNewReaderResults(results: ReaderResults) {
    _.each(results.collection, (result: ReaderResult | SummaryResult) => {
      if (this.readerResultWatcher.resultIsNew(result)) {
        result.markAsNew();
      }
    });
  }

  clearResults() {
    let readerResults: ResultsByCategoryId = [];
    let props: DetailListProps = {
      readerResults: readerResults,
      categories: [],
      summary: undefined,
      config: this.config,
    };

    // TODO: figure out how to type this
    let element: any = DetailList;
    let component = React.createElement(element, props);
    let cnt = document.getElementById('resultsDetail');
    if (cnt != null) {
      ReactDOM.render(component, cnt);
    } else {
      throw new Error('Could not find #resultsDetail in the DOM');
    }
  }

  logClipAccessRequest(
    clipId: number,
    agentResultId: number,
    resultKind: string,
    accessKind: AccessKind
  ) {
    window.clearTimeout(this.loggingTimerId);
    this.loggingTimerId = window.setTimeout(() => {
      ApiClient.create(ClientContext.App).logClipAccess(
        clipId,
        agentResultId,
        accessKind
      );
      Analytics.sendEvent('reader_agent_result_select', {
        reader_result_kind: resultKind,
      });
    }, 1000);
  }

  newReaderResultsLoaded(results: (ReaderResult | SummaryResult)[]) {
    this.readerResultWatcher.recordLoadedResults(results);
  }

  buildPdfDownloadUrl(clipId: number): string {
    const apiClient = ApiClient.create(ClientContext.App);
    return apiClient.buildPdfDownloadUrl(clipId);
  }

  onAfterResultRender(callback: AfterResultRenderCallback): void {
    this.afterResultRenderCallbacks.push(callback);
  }

  userIsAway() {
    if (Environment.useMessagePusher() && this.pusher) {
      this.pusher.stopListening();
    }
  }

  userIsBack() {
    if (Environment.useMessagePusher() && this.pusher) {
      this.pusher.startListening();
    }
  }

  /**
   *  Updates the summary and renders it to DOM.
   */
  public updateAndRenderArchiveDateSummary() {
    this.updateAndGetSummary().done((summary: ArchiveDateSummary) => {
      this.renderArchiveDateSummary(summary);
    });
  }

  private runAfterResultRenderCallbacks() {
    _.each(this.afterResultRenderCallbacks, (callback) => {
      callback();
    });
  }

  private maybeShowOneResult() {
    if (!this.config.show_all_articles) {
      $('body').addClass('show-one-result');
    }
  }

  private trackStartupTimeOnFirstCall() {
    if (!this.startupTimeTracked) {
      this.startupTimeTracked = true;
      this.startupTimer.send();
    }
  }

  showErrorScreen(props: { title: string; message: string }) {
    let component = React.createElement(ErrorScreen, props);
    let cnt = document.getElementById('container');
    if (cnt != null) {
      if (component) {
        ReactDOM.render(component, cnt);
      }
    } else {
      throw new Error('#container not present in DOM');
    }
  }

  showEmailActivationView() {
    this.readerTokenInit.showEmailActivationView();
  }

  showNavigationLoadingIndicator() {
    this.navigation.showLoadingIndicator();
  }
}
