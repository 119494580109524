import Environment from './Environment';

// Stores the given token in LocalStorage
export default class TokenCache {
  private TOKEN_KEY: string;

  constructor() {
    this.TOKEN_KEY = 'token';
  }

  store(token: string) {
    Environment.storeToLocalStorage(this.TOKEN_KEY, token);
  }

  isTokenPresent(): boolean {
    return this.getToken() != null;
  }

  getToken(): string | null {
    return window.localStorage.getItem(this.TOKEN_KEY);
  }

  removeToken() {
    window.localStorage.removeItem(this.TOKEN_KEY);
  }
}
