import { ResultsByCategoryId } from '../ResultsByCategoryId';
import ReaderResult from '../ReaderResult';
import SummaryResult from '../SummaryResult';
import ReaderCategory from '../ReaderCategory';
import ArchiveDateSummary from '../ArchiveDateSummary';
import Config from '../Config';
import Utils from '../Utils';
import DetailSummary from './DetailSummary';
import { Category } from '../Category';
import DetailComponent from './DetailComponent';
import Environment from '../Environment';
import * as _ from 'underscore';
import * as React from 'react';

export interface DetailListProps {
  // ResultsByCategoryId or (ReaderResult|SummaryResult)[] (for search results)
  // TODO: fix this, readerResults should have _one_ type!
  readerResults: ResultsByCategoryId | (ReaderResult | SummaryResult)[];
  categories: ReaderCategory[];
  summary?: ArchiveDateSummary;
  config?: Config;
  handleShowFullScreenImage?: (url: string) => void;
}

export default class DetailList extends React.Component<DetailListProps, {}> {
  render() {
    if (_.isArray(this.props.readerResults)) {
      return this.simpleResultList();
    } else {
      return this.categoryDetailList();
    }
  }

  summary() {
    if (
      this.props.summary != undefined &&
      (Utils.isNotBlank(this.props.summary.text) ||
        Utils.isNotBlank(this.props.summary.title))
    ) {
      return <DetailSummary summary={this.props.summary} />;
    }
  }

  isResultsByCategoryId(
    results: ResultsByCategoryId | (ReaderResult | SummaryResult)[]
  ): results is ResultsByCategoryId {
    return !_.isArray(results);
  }

  isResultsArray(
    results: ResultsByCategoryId | (ReaderResult | SummaryResult)[]
  ): results is (ReaderResult | SummaryResult)[] {
    return _.isArray(results);
  }

  categoryDetailList() {
    window.readerResults = this.props.readerResults;
    if (this.isResultsByCategoryId(this.props.readerResults)) {
      let readerResults: ResultsByCategoryId = this.props.readerResults;
      let categoryLists = _.chain(this.props.categories)
        .map((category: Category) => {
          if (readerResults[category.id].collection.length > 0) {
            let results = readerResults[category.id].collection;
            return this.categoryResultList(results);
          }
        })
        .value();

      return (
        <div>
          {this.summary()}
          {categoryLists}
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  simpleResultList() {
    if (this.isResultsArray(this.props.readerResults)) {
      let results: (ReaderResult | SummaryResult)[] = this.props.readerResults;
      let list = _.chain(results)
        .map((readerResult: ReaderResult | SummaryResult, idx: number) => {
          if (readerResult.id != undefined && this.props.config != undefined) {
            return (
              <DetailComponent
                key={readerResult.uniqueId()}
                readerResult={readerResult}
                config={this.props.config}
                isLastItem={false}
                onShowFullscreenImage={this.props.handleShowFullScreenImage}
              />
            );
          }
        })
        .value();

      return <div>{list}</div>;
    } else {
      return <div></div>;
    }
  }

  categoryResultList(results: (ReaderResult | SummaryResult)[]) {
    let list = _.chain(results)
      .map((readerResult: ReaderResult | SummaryResult, idx: number) => {
        let isLastItem = results.length == idx + 1;

        if (readerResult.id != undefined && this.props.config != undefined) {
          return (
            <DetailComponent
              key={readerResult.uniqueId()}
              readerResult={readerResult}
              config={this.props.config}
              isLastItem={isLastItem}
              onShowFullscreenImage={this.props.handleShowFullScreenImage}
            />
          );
        }
      })
      .value();
    return list;
  }
}
