import * as React from 'react';
import ReaderResult from '../ReaderResult';
import Config from '../Config';
import Result from '../Result';
import Source from '../Source';
import TimeFormatter from '../TimeFormatter';
import Utils from '../Utils';

export interface NavigationItemProps {
  readerResult: ReaderResult;
  config: Config;
}

export default class NavigationItem extends React.Component<
  NavigationItemProps,
  {}
> {
  render() {
    let readerResult = this.props.readerResult;
    let result = this.result();
    let primarySource = this.primarySource();
    if (primarySource.kind != undefined) {
      return (
        <li
          data-unique-result-id={readerResult.uniqueId()}
          data-reader-result-id={readerResult.id}
          data-result-id={result.id}
          data-clip-id={result.clipId}
          data-kind={primarySource.kind}
          data-reader-archive-date-id={readerResult.archiveDateId}
          className={this.klassName()}
        >
          <div className="navigation__item-important" />
          <div className={this.langClass()} lang={result.lang}>
            <h2 className="navigation__itemtitle">{this.title()}</h2>
          </div>
          <div className="navigation__itemdetails">
            <div className="navigation__itemsource">
              {this.primarySourceTitle()}
            </div>
            <time className="navigation__itemtime">{this.time()}</time>
          </div>
        </li>
      );
    } else {
      return <div></div>;
    }
  }

  title() {
    if (this.props.readerResult.isYoutubeContentRemoved()) {
      return I18n.t('detail_view.youtube_content_removed_hint');
    }

    return this.props.readerResult.title();
  }

  result(): Result {
    if (this.props.readerResult.result != undefined) {
      return this.props.readerResult.result;
    } else {
      throw new Error('readerResult.result is undefined');
    }
  }

  primarySource(): Source {
    let result: Result = this.result();
    if (result.primarySource != undefined) {
      return result.primarySource;
    } else {
      throw new Error('readerResult.result.primarySource is undefined');
    }
  }

  primarySourceTitle() {
    let readerResult = this.props.readerResult;
    let result = this.result();
    let primarySource = this.primarySource();
    let additionalSourceCount = result.additionalSourceCount();

    if (additionalSourceCount) {
      return (
        <div>
          <span className="navigation__itemsource__label">
            {primarySource.title}
          </span>
          <div className="navigation__additionalSourceCount">
            {additionalSourceCount}
          </div>
        </div>
      );
    } else {
      return <div>{primarySource.title}</div>;
    }
  }

  time() {
    let readerResult = this.props.readerResult;
    return TimeFormatter.relativeTime(readerResult);
  }

  langClass() {
    if (this.props.readerResult.result && this.props.readerResult.result.lang) {
      return 'hyphenate';
    }
  }

  klassName(): string {
    let result = this.result();

    var out = 'navigation__item ';

    if (!Utils.isPresent(result.id)) {
      out += ' summary-result';
    }
    if (result.isHighlighted) {
      out += ' highlight';
    }

    if (result.important) {
      out += ' important';
    }

    if (result.isNewResult()) {
      out += ' is-new';
    }

    return out;
  }
}
