import { SafeArea } from 'capacitor-plugin-safe-area';
import Environment from './Environment';
import Utils from './Utils';
import * as _ from 'underscore';

/**
 * This class keeps track of all the results and their 'size'. When user
 * scrolls this class checks if the scroll position is on a result and marks
 * that result as selected.
 *
 * See Scroller if you want functionality about restoring to result position
 */
export default class ScrollingHandler {
  private isAnimating: boolean;
  private content?: JQuery;
  private navigationItems: JQuery;
  private items?: JQuery;
  private visibleItems: JQuery;
  private minMaxScroll: Array<Array<number>>;
  private lastFocusItemId?: string;
  private scrollHandler: () => void;
  private screenSize: Array<number>;
  private scrollingHappened: boolean;
  private updateInterval?: number;
  private scrollHandlerRunInterval: number;

  constructor() {
    this.navigationItems = $();
    this.visibleItems = $();
    this.minMaxScroll = [];
    this.isAnimating = false;
    this.scrollHandler = () => {
      this.onScroll();
    };
    this.screenSize = [$(window).width(), $(window).height()];
    this.scrollingHappened = false;
    if (Environment.isLargeScreen()) {
      this.scrollHandlerRunInterval = 100;
    } else {
      this.scrollHandlerRunInterval = 500;
    }
  }

  start() {
    this.content = $('.layout__content');
    this.onContentChanged();
    this.handleScrollEvents();
    this.updateVisibleItemPositionCache();
    window.app.fontsLoaded.done(() => {
      this.updateVisibleItemPositionCache();
    });
  }

  enableScrolling() {
    $(document).ready(() => {
      this.activateScrolling();
    });
  }

  private activateScrolling() {
    let self = this;
    const $layout = $('.layout');
    window.is_scrolling = false;

    $layout.addClass('is-animated');

    $(window).bind('orientationchange', function (event) {
      $(window).scrollTop(0);
    });

    $layout.on(
      'click',
      '.navigation__item',
      function (this: JQueryEventObject) {
        self.broadCastCategoryChange($(this));
        self.handleNavigationItemClick($(this), true);
      }
    );
  }

  private broadCastCategoryChange(element: JQuery) {
    let readerResultId = element.data('unique-result-id');
    if (Utils.isPresent(readerResultId)) {
      let categoryName = $(
        '.navigation [data-unique-result-id=' + readerResultId + ']'
      )
        .parents('.navigation__group')
        .find('.navigation__grouptitle')
        .text();

      window.app.dataStore.setCurrentCategoryTitle(categoryName);
    }
  }

  /**
   * Finds the corresponding Detail element to the Navigation element
   */
  private findDetailElement(navElement: JQuery) {
    let $content = $('.layout__content');
    let readerResultId = navElement.data('unique-result-id');
    let readerArchiveDateId = navElement.data('reader-archive-date-id');
    let readerResultIdSelector =
      '[data-unique-result-id = ' + readerResultId + ']';
    let readerArchiveDateIdSelector =
      '[data-reader-archive-date-id = ' + readerArchiveDateId + ']';

    let byResultId = $content.find(readerResultIdSelector);
    let byArchiveDateId = $content.find(readerArchiveDateIdSelector);
    return byResultId.length > 0 ? byResultId : byArchiveDateId;
  }

  private openDetailView() {
    $('.layout').addClass('is-opened');
  }

  /** Removes css classes from the currently content & nav elements */
  private resetCurrentSelection() {
    let $content = $('.layout__content');
    $('.navigation__item').prev().removeClass('is-selected-previous');
    $('.navigation__item').next().removeClass('is-selected-next');
    $('.navigation__item').removeClass('is-selected');
    $content.find('.detail.selected-item').removeClass('selected-item');
  }

  /** $target is the target Detail element */
  private async scrollToDetail($target: JQuery, openDetailView: boolean) {
    window.is_scrolling = true;
    let $content = $('.layout__content');
    let fixedOffset = 40;

    if (openDetailView) {
      this.openDetailView();
    }

    let offset = $target.position().top - this.distanceFromTop() + fixedOffset;
    const { insets } = await SafeArea.getSafeAreaInsets();

    if (this.isDesktopScreen()) {
      if (Environment.isIosDevice()) {
        offset -= insets.top;
      }
      $content.animate({ scrollTop: offset }, undefined, () => {
        this.enableScrollingAgain();
      });
    } else {
      if ($('body.show-one-result').length > 0) {
        $content.scrollTop(0);
        this.enableScrollingAgain();
      } else {
        if (Environment.isIosDevice()) {
          offset += insets.top;
        }
        $content.scrollTop(offset);
        this.enableScrollingAgain();
      }
    }
    this.updateVisibleItemPositionCache();
  }

  // fix opera/ie not exact sidebar scroll to nav problem
  enableScrollingAgain() {
    setTimeout(() => {
      window.is_scrolling = false;
    }, 1000);
  }

  scrollNavigationToCategory(categoryId: number) {
    let categorySelector =
      '.navigation__group[data-category-id=' + categoryId + ']';
    const container = $('.navigation__list');
    const element = container.find(categorySelector);
    const offset = element.position().top + container.scrollTop();
    container.scrollTop(offset);
  }

  handleNavigationItemClick(navElement: JQuery, openDetailView: boolean) {
    let $content = $('.layout__content');
    let $target = this.findDetailElement(navElement);

    this.resetCurrentSelection();

    $target.addClass('selected-item');
    navElement.addClass('is-selected');
    navElement.prev().addClass('is-selected-previous');
    navElement.next().addClass('is-selected-next');

    if (this.isDesktopScreen()) {
      navElement.trigger('is-selected');
    }

    this.scrollToDetail($target, openDetailView);
  }

  onContentChanged() {
    let $content = $('.layout__content');
    this.navigationItems = $('.navigation__item');
    this.items = $content.find(
      '[data-unique-result-id], [data-reader-archive-date-id]'
    );
  }

  isDesktopScreen(): boolean {
    return $(window).width() >= 700;
  }

  /**
   * Stores the start and end position of all visible reader result
   * elements.
   * Stores the start and end position of the whole content element.
   **/
  updateVisibleItemPositionCache() {
    if (this.items !== undefined) {
      this.visibleItems = this.items.filter(':visible');
      this.minMaxScroll = [];
      let $content = $('.layout__content');
      const scrolled = $content.scrollTop();
      const itemChangePositionFromVisibleTop = 200;
      let endOfLastItem = 0;
      this.visibleItems.each((_i, item) => {
        const $item = $(item);
        const endOfItem = this.elementStartAndEndPosition($item, scrolled)[1];
        this.minMaxScroll.push([
          endOfLastItem - itemChangePositionFromVisibleTop,
          endOfItem - itemChangePositionFromVisibleTop,
        ]);
        endOfLastItem = endOfItem;
      });
    }
  }

  private elementStartAndEndPosition(
    element: JQuery,
    scrolled: number
  ): Array<number> {
    const beginOfElement = element.position().top + scrolled;
    const heightOfElement = element.height();
    const endOfElement = beginOfElement + heightOfElement;
    return [beginOfElement, endOfElement];
  }

  openResultsForCategory(categoryId: number): void {
    let detailContent = $('.layout__content');
    let results = detailContent.find('[data-category-id="' + categoryId + '"]');
    let promise = results.removeClass('invisible').promise();
    results.addClass('visible');
    this.updateVisibleItemPositionCache();
  }

  private handleScrollEvents() {
    this.enableScrollHandling();
  }

  private enableScrollHandling() {
    if (!this.content) {
      return;
    }
    this.content.on('scroll', this.scrollHandler);
    this.content.on('touchmove', this.scrollHandler);
    this.updateInterval = window.setInterval(() => {
      if (this.scrollingHappened) {
        this.maybeNotifyAboutItemChange();
        this.scrollingHappened = false;
      }
    }, this.scrollHandlerRunInterval);
  }

  updateNavigationSelection() {
    this.maybeNotifyAboutItemChange();
  }

  private onScroll() {
    this.scrollingHappened = true;
  }

  private maybeNotifyAboutItemChange() {
    if (this.visibleItems.length > 0) {
      let onFocusItem = this.currentlyOnFocusElement();

      if (onFocusItem != undefined && onFocusItem.length > 0) {
        const onFocusItemId = onFocusItem.data('unique-result-id');
        const different = this.lastFocusItemId !== onFocusItemId;
        if (different) {
          this.lastFocusItemId = onFocusItemId;
          this.currentItemChanged(onFocusItem);
        }
      }
    }
  }

  private currentlyOnFocusElement(): JQuery | undefined {
    if (this.visibleItems.length > 0) {
      let $content = $('.layout__content');
      const scroll = $content.scrollTop();
      let idx = -1;
      _.find(this.minMaxScroll, (minMax, i) => {
        idx = i;
        return scroll >= minMax[0] && scroll < minMax[1];
      });
      return $(this.visibleItems[idx]);
    }
  }

  private distanceFromTop(): number {
    return $('[data-detail-content-top]').offset().top;
  }

  /** Handle changing of current item through scrolling */
  private currentItemChanged(item: JQuery) {
    this.broadCastCategoryChange(item);
    this.markAsSelected(item);
  }

  private itemId($item: JQuery): string {
    const readerResultId = $item.data('unique-result-id');
    const readerArchiveDateId = $item.data('reader-archive-date-id');
    return String(readerResultId || readerArchiveDateId);
  }

  private markAsSelected($item: JQuery) {
    if (!window.is_scrolling && !this.isAnimating) {
      let resultId = this.itemId($item);
      this.navigationItems.removeClass('is-selected');
      this.navigationItems.removeClass('is-selected-previous');
      this.navigationItems.removeClass('is-selected-next');

      let readerResultSelector = '[data-unique-result-id = ' + resultId + ']';
      let summaryResultSelector =
        '.summary-result[data-reader-archive-date-id=' + resultId + ']';
      let current = $(
        this.navigationItems.filter(readerResultSelector).get(0) ||
          this.navigationItems.filter(summaryResultSelector).get(0)
      );

      current.addClass('is-selected');
      current.prev().addClass('is-selected-previous');
      current.next().addClass('is-selected-next');

      current.trigger('is-selected');

      this.maybeScrollNavigation(current);
    }
  }

  private maybeScrollNavigation(currentNavigationElement: JQuery) {
    if (currentNavigationElement.hasClass('.invisible')) {
      return;
    }
    let $list = $('.navigation__list');
    const firstCategoryHeader = $list.find('.navigation__grouptitle');
    const categoryHeaderHeight = firstCategoryHeader.outerHeight();
    const currentPosition = currentNavigationElement.position().top;
    const currentHeight = currentNavigationElement.height();
    const difference = currentPosition - categoryHeaderHeight;
    if (currentPosition + currentHeight > $list.height()) {
      $list.animate(
        {
          scrollTop: $list.scrollTop() + difference,
        },
        'fast',
        () => {
          this.isAnimating = false;
        }
      );
      this.isAnimating = true;
    } else if (currentPosition < categoryHeaderHeight) {
      $list.scrollTop($list.scrollTop() + difference);
    }
  }

  private throttle(callback: () => void, duration: number) {
    let throttled = false;
    let lambda = () => {
      if (!throttled) {
        throttled = true;
        window.setTimeout(function () {
          callback();
          throttled = false;
        }, duration);
      }
    };

    return lambda;
  }

  public handleResize() {
    const oldScreenSize = this.screenSize;
    const newScreenSize = [$(window).width(), $(window).height()];
    if (
      oldScreenSize[0] == newScreenSize[1] &&
      oldScreenSize[1] == newScreenSize[0]
    ) {
      this.orientationFlipped();
    }
    this.screenSize = newScreenSize;
    this.updateVisibleItemPositionCache();
  }

  private orientationFlipped() {
    const position = this.getArticlePosition();
    window.setTimeout(() => {
      this.restoreArticlePosition(position);
    }, 0);
  }

  private getArticlePosition(): number {
    if (this.content && this.visibleItems.length > 0) {
      const scroll = this.content.scrollTop();
      let idx = -1;
      const values = _.find(this.minMaxScroll, (minMax, i) => {
        idx = i;
        return scroll >= minMax[0] && scroll < minMax[1];
      });
      if (values === undefined) {
        throw 'values is undefined';
      }
      const itemChangePositionFromVisibleTop = 200;
      const top = values[0] + itemChangePositionFromVisibleTop;
      let fractionalPart: number;
      if (idx >= 0) {
        fractionalPart = (scroll - top) / (values[1] - values[0]);
      } else {
        fractionalPart = 0;
      }
      return idx + fractionalPart;
    } else {
      return -1;
    }
  }

  private restoreArticlePosition(position: number) {
    if (position < 0 || !this.content) {
      return;
    }
    const itemChangePositionFromVisibleTop = 200;
    const idx = Math.floor(position);
    const minMax = this.minMaxScroll[idx];
    const offset = (minMax[1] - minMax[0]) * (position - idx);
    const baseOffset = minMax[0] + itemChangePositionFromVisibleTop;
    this.content.scrollTop(baseOffset + offset);
  }
}
