import * as React from 'react';
import * as ReactDOM from 'react-dom';

export default class AppOutdatedView extends React.Component<{}, {}> {
  render() {
    let locale: string;
    if (I18n.locale == 'de') {
      locale = 'de';
    } else if (I18n.locale == 'fr') {
      locale = 'fr';
    } else {
      locale = 'en';
    }

    let appleUrl = `/images/appstore-badges/apple/${locale}.svg`;
    return (
      <div className="appstore-badge appstore-badge--apple">
        <a
          className="appstore-badge-link"
          aria-label={I18n.t('navigation.downloadAppStore')}
          href="https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=1091258545"
        >
          <img className="appstore-badge-image" src={appleUrl} />
        </a>
      </div>
    );
  }
}
