import DataLoader from '../DataLoader';
import Config from '../Config';
import Environment from '../Environment';
import OfflineAndMobileDataUpdateService from '../OfflineAndMobileDataUpdateService';

/** Triggers data refresh on App resume event */
export default class ResumeHandler {
  constructor(
    private dataUpdateService: OfflineAndMobileDataUpdateService,
    private dataLoader: DataLoader,
    private config: Config
  ) {
    this.dataUpdateService = dataUpdateService;
    this.dataLoader = dataLoader;
    this.config = config;
  }

  startListening() {
    document.addEventListener(
      'resume',
      () => {
        this.onResume();
      },
      false
    );
  }

  private onResume() {
    setTimeout(() => {
      this.refreshIfReady();
    }, 5000);
  }

  private refreshIfReady() {
    this.runIfReadyToRefresh(() => {
      this.dataUpdateService.updateMobileReaderOnResume();
    });
  }

  private runIfReadyToRefresh(callback: () => void) {
    this.dataLoader
      .lastArchiveDateUpdate(this.config)
      .done((lastUpdatedAt: number) => {
        if (this.isReadyToRefresh(moment(lastUpdatedAt))) {
          callback();
        }
      });
  }

  private isReadyToRefresh(lastUpdatedAt: moment.Moment): boolean {
    return moment().subtract(5, 'minutes').isAfter(lastUpdatedAt);
  }
}
