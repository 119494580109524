interface DummyStore {
  [idx: string]: string;
}

export default class DummyStorage {
  store: DummyStore;

  constructor() {
    this.store = {};
  }

  getItem(key: string): any {
    return this.store[key];
  }

  setItem(key: string, value: string): void {
    this.store[key] = value;
  }
}
