import { IIDBRecord } from './IIDBRecord';
import { Config as ConfigResponse } from '../api/response/Config';
import * as _ from 'underscore';

export default class Configuration implements IIDBRecord {
  public identifier: string;
  public response: ConfigResponse;

  constructor(response: ConfigResponse, token: string) {
    this.identifier = token;
    this.response = response;
  }

  static findByToken(
    token: string,
    objectStore: IDBObjectStore
  ): JQueryPromise<ConfigResponse> {
    let deferred = $.Deferred<ConfigResponse>();
    let request: IDBRequest = objectStore.get(token);
    request.onerror = (e: Event) => {
      let req: IDBRequest = <IDBRequest>e.target;
      deferred.reject(req.error);
    };
    request.onsuccess = (e: Event) => {
      let req: IDBRequest = <IDBRequest>e.target;
      if (_.isUndefined(req.result)) {
        deferred.reject();
      } else {
        let response = req.result.response;
        deferred.resolve(response);
      }
    };
    return deferred.promise();
  }
}
