import { NotificationListener, PushNotificationData } from '../Notifications';
import App from '../../App';
import * as _ from 'underscore';

export interface ArchiveDateResultChangeData {
  archive_date_id: number;
  configuration_id: number;
}

export default class ArchiveDateResultChangeListener
  implements NotificationListener
{
  private handler: Function;

  constructor(private app: App) {
    this.app = app;
    this.handler = _.throttle(
      (data: ArchiveDateResultChangeData) => {
        this.app.refreshCurrentArchiveDateResults(data.archive_date_id);
      },
      15000,
      { leading: false }
    );
  }

  run({ event_type, archive_date_id, configuration_id }: PushNotificationData) {
    if (
      (event_type === 'archive_date_results_changed' ||
        event_type === 'archive_date_summary_changed') &&
      this.app.currentlyActiveConfig().id === Number(configuration_id)
    ) {
      this.handler({
        archive_date_id: Number(archive_date_id),
        configuration_id: Number(configuration_id),
      });
    }
  }
}
