import React, {
  ReactElement,
  useState,
  useCallback,
  SVGAttributes,
  useEffect,
} from 'react';
import { Swiper as SwiperClass } from 'swiper/types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

const slideNum = 2;

interface Props {
  widgets: ReactElement[];
}

export default function InsightsCarousel({ widgets }: Props) {
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();
  const [isBeginning, setIsBeginning] = useState(false);
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    setIsBeginning(swiperRef?.isBeginning || false);
    setIsEnd(swiperRef?.isEnd || false);
  }, [swiperRef]);

  const handlePrev = useCallback(() => {
    swiperRef?.slidePrev();
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
  }, [swiperRef]);

  return (
    <div className="insights">
      <Swiper
        loop={false}
        modules={[Pagination]}
        pagination={{ clickable: true, el: '.insights-pagination' }}
        spaceBetween={10}
        slidesPerView={1}
        breakpoints={{
          1170: {
            slidesPerView: 2,
          },
          1440: {
            slidesPerView: 3,
          },
        }}
        onSlideChange={() => {
          setIsBeginning(swiperRef?.isBeginning || false);
          setIsEnd(swiperRef?.isEnd || false);
        }}
        onSwiper={setSwiperRef}
        oneWayMovement={false}
        rewind={false}
        watchOverflow={true}
      >
        {widgets.map((widget, i) => (
          <SwiperSlide key={`${i}`}>{widget}</SwiperSlide>
        ))}
      </Swiper>
      <div className="insights-pagination">...</div>
      {widgets.length > slideNum && (
        <>
          {!isBeginning && (
            <button className="prev" onClick={() => handlePrev()}>
              <ArrowSvg style={{ transform: 'scale(-1,1)' }} />
            </button>
          )}
          {!isEnd && (
            <button className="next" onClick={() => handleNext()}>
              <ArrowSvg />
            </button>
          )}
        </>
      )}
    </div>
  );
}

const ArrowSvg = (props: SVGAttributes<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={23}
    fill="none"
    {...props}
  >
    <path
      fill="#aaa"
      d="M8.625 11.5 0 2.875 2.625.25l11.25 11.25-11.25 11.25L0 20.125 8.625 11.5Z"
    />
  </svg>
);
