import { NotificationListener, PushNotificationData } from '../Notifications';
import App from '../../App';
import * as _ from 'underscore';

export interface ArchiveDatePublishedData {
  archive_date_id: number;
  configuration_id: number;
}

export default class ArchiveDatePublishedListener
  implements NotificationListener
{
  private handler: Function;

  constructor(private app: App) {
    this.app = app;
    this.handler = _.throttle(
      (data: ArchiveDatePublishedData) => {
        this.app.fetchAndReRenderArchiveDatesWithConditionalSelect();
      },
      15000,
      { leading: false }
    );
  }

  run({ event_type, archive_date_id, configuration_id }: PushNotificationData) {
    if (
      event_type === 'archive_date_published' &&
      this.app.currentlyActiveConfig().id === Number(configuration_id)
    ) {
      this.handler({
        archive_date_id: Number(archive_date_id),
        configuration_id,
      });
    }
  }
}
