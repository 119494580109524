/** Tagged string type for AccessTokens */
export default class AccessToken {
  private token: string;

  constructor(s: string) {
    this.token = s;
  }

  toString() {
    return this.token;
  }
}
