import * as _ from 'underscore';

export default class QueryHighlighter {
  static highlightSearchQuery(query: string) {
    let opts = {
      className: 'query-highlight',
    };

    let selector = QueryHighlighter.highlightSelector();

    // highlight whole query
    $(selector).highlight(query, opts);
    // highlight individual words
    _.each(query.split(' '), (word: string) => {
      $(selector).highlight(word, opts);
    });
  }

  static unHighlightLastQuery() {
    let opts = {
      className: 'query-highlight',
    };
    $(QueryHighlighter.highlightSelector()).unhighlight(opts);
  }

  static highlightSelector() {
    let selectors = [
      '.layout__content .detail__primarysourcetitle',
      '.layout__content .detail p',
      '.layout__content .detail__title',
      '.navigation .navigation__itemtitle',
      '.navigation .navigation__itemsource',
      '.blueimp-gallery h3.title p',
    ];

    return selectors.join(',');
  }
}
