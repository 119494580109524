import { createElement } from 'react';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import { NotificationListener, PushNotificationData } from '../Notifications';
import App from '../../App';
import Scroller from '../../Scroller';

export interface ReaderResultChangeData {
  archive_date_id: number;
  configuration_id: number;
  reader_result_id: number;
  agent_result_id: number;
  reader_result_updated_at: string;
  agent_result_updated_at: string;
  archive_date_updated_at: string;
  newsroom_event: string;
}

export default class ReaderResultChangeListener
  implements NotificationListener
{
  private handler: Function;

  constructor(private app: App) {
    this.app = app;
    this.handler = ({
      archive_date_id,
      agent_result_id,
    }: ReaderResultChangeData) => {
      this.app.refreshCurrentArchiveDateResults(archive_date_id);
      enqueueSnackbar(I18n.t('notifications.reader_result_changed'), {
        variant: 'info',
        persist: true,
        action: (notistackId) => [
          createElement('button', {
            key: 'show',
            children: I18n.t('notifications.show'),
            onClick: () => {
              Scroller.scrollToResult(
                agent_result_id,
                archive_date_id,
                this.app,
                true
              );
              closeSnackbar(notistackId);
            },
          }),
          createElement('button', {
            key: 'dismiss',
            children: ' ',
            className: 'dismiss',
            onClick: () => {
              console.log(`ID: ${notistackId}`);
              closeSnackbar(notistackId);
            },
          }),
        ],
      });
    };
  }

  run({
    event_type,
    archive_date_id,
    configuration_id,
    agent_result_id,
  }: PushNotificationData) {
    if (
      event_type === 'reader_result_changed' &&
      this.app.currentlyActiveConfig().id === Number(configuration_id)
    ) {
      this.handler({
        archive_date_id: Number(archive_date_id),
        configuration_id: Number(configuration_id),
        agent_result_id: Number(agent_result_id),
      });
    }
  }
}
