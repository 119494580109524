import { IIndexedDatabase } from './IIndexedDatabase';
import ReaderDB from './ReaderDB';
import DummyDB from './DummyDB';
import { Settings } from './Settings';
import { Config as ConfigResponse } from './api/response/Config';
import { ArchiveDate as ArchiveDateResponse } from './api/response/ArchiveDate';
import { ArchiveDateSummary as ArchiveDateSummaryResponse } from './api/response/ArchiveDateSummary';
import { ReaderCategory as ReaderCategoryResponse } from './api/response/ReaderCategory';
import { ReaderResults as ReaderResultsResponse } from './api/response/ReaderResults';
import { ArchiveDateContents as ArchiveDateContentsResponse } from './api/response/ArchiveDateContents';

// A wrapper around ReaderDB
// A fallback to DummyDB is included
// in the case the initialization of
// the db fails.
export default class ReaderDBWithFallback implements IIndexedDatabase {
  private db: IIndexedDatabase;
  private initializationDone: JQueryPromise<void>;
  constructor() {
    const readerDB = new ReaderDB();
    this.db = readerDB;
    const deferred = $.Deferred<void>();
    this.initializationDone = deferred.promise();
    const initPromise = readerDB.initializeDatabase();
    initPromise.fail(() => {
      this.db = new DummyDB();
      deferred.resolve();
    });
    initPromise.done(() => {
      deferred.resolve();
    });
  }

  saveNotificationSettings(
    token: string,
    settings: Settings
  ): JQueryPromise<ConfigResponse> {
    return this.initializationDone.then(() => {
      return this.db.saveNotificationSettings(token, settings);
    });
  }

  loadConfigurations(token: string): JQueryPromise<ConfigResponse> {
    return this.initializationDone.then(() => {
      return this.db.loadConfigurations(token);
    });
  }

  loadConfigurationAndArchiveDates(
    token: string
  ): JQueryPromise<ConfigResponse> {
    return this.initializationDone.then(() => {
      return this.db.loadConfigurationAndArchiveDates(token);
    });
  }

  loadArchiveDates(configId: number): JQueryPromise<ArchiveDateResponse[]> {
    return this.initializationDone.then(() => {
      return this.db.loadArchiveDates(configId);
    });
  }

  lastArchiveDateUpdate(configId: number): JQueryPromise<number> {
    return this.initializationDone.then(() => {
      return this.db.lastArchiveDateUpdate(configId);
    });
  }

  lastReaderResultUpdate(
    configId: number,
    archiveDateId: number,
    categoryId: number
  ): JQueryPromise<number> {
    return this.initializationDone.then(() => {
      return this.db.lastReaderResultUpdate(
        configId,
        archiveDateId,
        categoryId
      );
    });
  }

  loadArchiveDateSummary(
    configId: number,
    archiveDateId: number
  ): JQueryPromise<ArchiveDateSummaryResponse> {
    return this.initializationDone.then(() => {
      return this.db.loadArchiveDateSummary(configId, archiveDateId);
    });
  }

  loadCategories(
    configId: number,
    archiveDateId: number
  ): JQueryPromise<ReaderCategoryResponse[]> {
    return this.initializationDone.then(() => {
      return this.db.loadCategories(configId, archiveDateId);
    });
  }

  loadReaderResults(
    configId: number,
    archiveDateId: number,
    categoryId: number
  ): JQueryPromise<ReaderResultsResponse> {
    return this.initializationDone.then(() => {
      return this.db.loadReaderResults(configId, archiveDateId, categoryId);
    });
  }

  loadArchiveDateContents(
    configId: number,
    archiveDateId: number
  ): JQueryPromise<ArchiveDateContentsResponse> {
    return this.initializationDone.then(() => {
      return this.db.loadArchiveDateContents(configId, archiveDateId);
    });
  }

  updateConfigurations(
    config: ConfigResponse,
    token: string
  ): JQueryPromise<void> {
    return this.initializationDone.then(() => {
      return this.db.updateConfigurations(config, token);
    });
  }

  updateCategories(
    archiveDates: ReaderCategoryResponse[],
    configId: number,
    archiveDateId: number
  ): JQueryPromise<void> {
    return this.initializationDone.then(() => {
      return this.db.updateCategories(archiveDates, configId, archiveDateId);
    });
  }

  updateArchiveDates(
    archiveDates: ArchiveDateResponse[],
    configId: number
  ): JQueryPromise<void> {
    return this.initializationDone.then(() => {
      return this.db.updateArchiveDates(archiveDates, configId);
    });
  }

  updateArchiveDateSummary(
    archiveDateSummary: ArchiveDateSummaryResponse,
    configId: number,
    archiveDateId: number
  ): JQueryPromise<void> {
    return this.initializationDone.then(() => {
      return this.db.updateArchiveDateSummary(
        archiveDateSummary,
        configId,
        archiveDateId
      );
    });
  }

  updateReaderResults(
    results: ReaderResultsResponse,
    configId: number,
    archiveDateId: number,
    categoryId: number
  ): JQueryPromise<void> {
    return this.initializationDone.then(() => {
      return this.db.updateReaderResults(
        results,
        configId,
        archiveDateId,
        categoryId
      );
    });
  }

  updateArchiveDateContents(
    contents: ArchiveDateContentsResponse,
    configId: number,
    archiveDateId: number
  ): JQueryPromise<void> {
    return this.initializationDone.then(() => {
      return this.db.updateArchiveDateContents(
        contents,
        configId,
        archiveDateId
      );
    });
  }

  invalidateConfiguration(token: string | null): void {
    this.initializationDone.then(() => {
      this.db.invalidateConfiguration(token);
    });
  }
}
