import App from './App';

export default class PrintPdfDownloader {
  private clipId: number;
  private app: App;
  constructor(clipId: number, app: App) {
    this.clipId = clipId;
    this.app = app;
  }
  start() {
    var url = this.app.buildPdfDownloadUrl(this.clipId);
    this.app.linkOpener.open(url);
  }
}
