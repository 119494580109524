export default class ColorHandler {
  parseColor(color: string): Array<number> {
    if (color[0] === '#') {
      // hex format
      return this.parseHex(color);
    } else {
      return this.parseRgb(color);
    }
  }

  formatAsRgba(values: Array<number>): string {
    return `rgba(${values.join(',')})`;
  }

  formatAsHex(values: Array<number>): string {
    function numToHexValue(n: number) {
      if (n < 16) {
        return `0${n.toString(16)}`;
      } else {
        return n.toString(16);
      }
    }
    let hex = values.slice(0, 3).map(numToHexValue).join('');
    return `#${hex}`;
  }

  toHex(color: string): string {
    return this.formatAsHex(this.parseColor(color));
  }

  applyAlpha(color: string, alpha: number): string {
    const values = this.parseColor(color);
    values[3] *= alpha;
    return this.formatAsRgba(values);
  }

  darkenHex(color: string, factor: number) {
    let values = this.parseColor(color).slice(0, 3);
    let darkerValues = values.map((n) => Math.floor(n * factor));
    return this.formatAsHex(darkerValues);
  }

  private parseHex(color: string): Array<number> {
    let ndigits: number;
    if (color.length === 7) {
      // 2 digits per color
      ndigits = 2;
    } else if (color.length === 4) {
      // 1 digit per color
      ndigits = 1;
    } else {
      throw 'Unsupported color format';
    }
    const multFactor = ndigits === 2 ? 1 : 17;

    const red = parseInt(color.substr(1, ndigits), 16);
    const green = parseInt(color.substr(1 + ndigits, ndigits), 16);
    const blue = parseInt(color.substr(1 + 2 * ndigits, ndigits), 16);
    return [red * multFactor, green * multFactor, blue * multFactor, 1];
  }

  private parseRgb(color: string): Array<number> {
    const parts = color.split(/[,\(\)]/);
    if (parts.length < 5 || parts.length > 6 || !parts[0].match(/rgb/i)) {
      throw 'Unsupported color format';
    }

    const red = parseInt(parts[1]);
    const green = parseInt(parts[2]);
    const blue = parseInt(parts[3]);
    const alpha = parts.length > 5 ? parseFloat(parts[4]) : 1;
    if (color.match(/%/)) {
      // percentages are used
      return [
        Math.round(red * 2.55),
        Math.round(green * 2.55),
        Math.round(blue * 2.55),
        alpha,
      ];
    } else {
      return [red, green, blue, alpha];
    }
  }
}
