import Result from './Result';

export default class ReaderResult {
  private readerResultId: number;
  public id: number;
  public position: number;
  public result: Result;
  public archiveDateId: number;
  private newResult: boolean;
  public category_name?: string;
  public category_id?: number;

  constructor(id: number, result: Result) {
    this.id = result.id;
    this.readerResultId = id;
    this.position = result.apiIndex;
    this.result = result;
    this.newResult = false;
  }

  // Returns unique id (the id of reader result) fallsback to original
  // implementation of AgentResult id for reasons of possible problems
  // in stored offline data.
  uniqueId(): number {
    return this.readerResultId || this.id;
  }

  title(): string {
    if (this.result != undefined && this.result.title != undefined) {
      return this.result.title;
    } else {
      throw new Error('this.result is not defined');
    }
  }

  clipId(): number {
    if (this.result != undefined && this.result.clipId != undefined) {
      return this.result.clipId;
    } else {
      return this.id;
    }
  }

  publishTime(): moment.Moment {
    if (this.result != undefined) {
      return this.result.publishTime(window.app.config);
    } else {
      throw new Error('this.result is not defined');
    }
  }

  primarySourceTitle(): string {
    if (this.result != undefined) {
      return this.result.primarySourceTitle();
    } else {
      throw new Error('this.result is not defined');
    }
  }

  isNewResult(): boolean {
    return this.newResult;
  }

  markAsNew() {
    this.newResult = true;
  }

  isPrintResult(): boolean {
    if (this.result != undefined) {
      return this.result.isPrintResult();
    } else {
      throw new Error('this.result is not defined');
    }
  }
  isYoutubeContentRemoved(): boolean {
    return this.result.youtube_content_removed;
  }
}
