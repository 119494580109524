export function formatNumber(n: unknown): string {
  if (typeof n === 'number') {
    let unit = '';
    let val = n;
    if (n > 999999) {
      val = Math.round(n / 100000) / 10;
      unit = 'M';
    } else if (n > 9999) {
      val = Math.round(n / 1000);
      unit = 'k';
    }
    return `${val}${unit}`;
  }
  return `${n}`;
}
