import * as React from 'react';

export interface CategoryTitleProps {
  categoryTitle: string;
}

export default class CategoryTitle extends React.Component<
  CategoryTitleProps,
  {}
> {
  render() {
    let style: React.CSSProperties = {};

    if (!this.props.categoryTitle) {
      style = { display: 'none' };
    }

    return (
      <div className="layout__header__category__title" style={style}>
        {this.props.categoryTitle}
      </div>
    );
  }
}
