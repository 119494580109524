import React, { useEffect, useState } from 'react';
import { intlFormatDistance } from 'date-fns';
import Environment from '../../Environment';
import InsightsCarousel from './InsightsCarousel';
import Widget, { Stats, Props as WidgetProps } from './Widget';
import { NumDatum } from './types';
import { formatNumber } from './util';

// For API docs see
// https://insights-api.release-preview.cognita.ch/swagger/index.html

function insightsApiUrl(): string {
  if (Environment.isProduction()) {
    return 'https://insights-api.cognita.ch/api/v1';
  } else if (Environment.isReleasePreview()) {
    return 'https://insights-api.release-preview.cognita.ch/api/v1';
  } else {
    return 'https://insights-api.dev.cognita.ch/api/v1';
  }
}

interface InsightResponse {
  id: number;
  type: 'overview' | 'resonance';
  title: string;
  sequencePosition: number;
  lastUpdated: string;
  clipCount?: number;
  sourceCount?: number;
  timeToRead?: number;
  reachPerDay?: {
    [date: string]: number;
  };
  totalReach?: {
    [date: string]: number;
  };
  trends?: {
    [date: string]: number;
  };
}

interface Props {
  token: string;
  readerId: number;
  archiveDateId: number;
  clientId: number;
}

export default function Insights({
  token,
  readerId,
  clientId,
  archiveDateId,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [insights, setInsights] = useState<InsightResponse[]>([]);

  useEffect(() => {
    const url = `${insightsApiUrl()}/insight/${clientId}/${readerId}/${archiveDateId}`;
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    fetch(url, options)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error('Failed to fetch insights!');
        }
      })
      .then((data: InsightResponse[]) => {
        setInsights(data);
      })
      .catch((error) => {
        // we don't report an API error to the user here
        // see https://bluereport.slack.com/archives/C077PFY67/p1736775427098069?thread_ts=1736774693.429109&cid=C077PFY67
        console.log(error);
      });
    setLoading(false);
  }, [token, readerId, clientId, archiveDateId]);

  if (insights.length === 0) {
    return null;
  }

  const widgets = insights
    .sort((a, b) => a.sequencePosition - b.sequencePosition)
    .map(
      ({
        clipCount,
        lastUpdated,
        reachPerDay,
        sourceCount,
        timeToRead,
        title,
        totalReach,
        trends,
        type,
      }) => {
        let data;
        let stats;

        if (type === 'overview') {
          // TODO: format values
          stats = [
            {
              label: I18n.translate('insights.source_count'),
              value: `${sourceCount}`,
            },
            {
              label: I18n.translate('insights.last_updated'),
              value: lastUpdated,
              format: (date: string) => {
                const timeString = intlFormatDistance(
                  new Date(date),
                  new Date(),
                  { locale: I18n.locale }
                )
                  .replace(/ (heures|Stunden|hours)/, 'h')
                  .replace(/ (Minuten|minutes)/, 'min')
                  .replace(/ (Sekunden|seconds)/, 's');
                return (
                  <span title={date} className="last-updated">
                    {timeString}
                  </span>
                );
              },
            },
            {
              label: I18n.translate('insights.time_to_read'),
              value: `${timeToRead} min`,
            },
          ];
          data = {
            label: I18n.translate('insights.clip_count'),
            value: `${clipCount}`,
          };
        } else if (type === 'resonance') {
          stats = [
            // TODO: this is a placeholder for the reputation KPI which is not
            // yet implemented
            {
              label: '',
              value: '',
            },
            {
              label: I18n.translate('detail_view.reach'),
              value: totalReach,
              format: (n: number) => {
                return <span title={`${n}`}>{formatNumber(n)}</span>;
              },
            },
            {
              label: I18n.translate('insights.trend'),
              value: trends,
              format: (n: number) => {
                let sentiment = '';
                if (n !== 0) {
                  sentiment = n > 0 ? 'positive' : 'negative';
                }
                return (
                  <span className={sentiment} title={`${n}`}>
                    {n > 0 && '+'}
                    {Math.round(n)}%
                  </span>
                );
              },
            },
          ];
          data = Object.entries(reachPerDay || {}).map(([date, value]) => {
            return {
              label: `${date}`,
              value,
            } as NumDatum;
          });
        }
        console.log({ reachPerDay }, Object.entries(reachPerDay || {}));

        return (
          <Widget
            title={title}
            type={I18n.translate(`insights.${type}`)}
            stats={stats as Stats}
            data={data}
          />
        );
      }
    );

  console.log({ insightsApiUrl, insights, token, widgets });

  return (
    <div className="insights-container">
      {loading && (
        <p className="loading">{I18n.translate('insights.loading')}...</p>
      )}
      {!loading && <InsightsCarousel widgets={widgets} />}
    </div>
  );
}
