import OfflineNotificationComponent from '../components/OfflineNotification';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

export default class OfflineNotification {
  private cnt: Element | undefined | null;
  constructor(private nodeId: string) {
    this.nodeId = nodeId;
  }

  mount() {
    let props = {
      closeCallback: () => {
        this.umount();
      },
    };
    let component = React.createElement(OfflineNotificationComponent, props);
    this.cnt = document.getElementById(this.nodeId);
    if (this.cnt != null) {
      ReactDOM.render(component, this.cnt);
    } else {
      throw new Error('nodeId is not in the DOM');
    }
  }

  umount() {
    window.canShowOfflineNotification = false;
    if (this.cnt != undefined && this.cnt != null) {
      ReactDOM.unmountComponentAtNode(this.cnt);
    }
  }
}
