import * as React from 'react';

export interface ErrorProps {
  title: string;
  message: string;
}

export default class ErrorScreen extends React.Component<ErrorProps, {}> {
  render() {
    return (
      <div className="error">
        <div className="error__container">
          <h1 className="error__title">{this.props.title}</h1>
          <p>{this.props.message}</p>
        </div>
      </div>
    );
  }
}
