import App from './App';
import Environment from './Environment';
import Config from './Config';
import ArchiveDate from './ArchiveDate';
import ReaderCategory from './ReaderCategory';
import ReaderResults from './ReaderResults';
import ArchiveDateSummary from './ArchiveDateSummary';
import Client from './api/Client';
import ReaderResultLoader from './ReaderResultLoader';
import ApiClient from './ApiClient';
import { ClientContext } from './ApiClient';
import { UpdatingResultsProps } from './components/UpdatingResults';
import Layout from './Layout';
import Scroller from './Scroller';

/**
 *  Mobile and Offline reader need explicit data updating on app load. This
 *  class is meant to be the interface for updates.
 */
export default class OfflineAndMobileDataUpdateService {
  private client: DataUpdate;
  private offlineClient: OfflineDataUpdate;
  private mobileClient: MobileDataUpdate;

  constructor(app: App) {
    this.client = new DataUpdate(app);
    this.offlineClient = new OfflineDataUpdate(app);
    this.mobileClient = new MobileDataUpdate(app);
  }

  /**
   * Entry point to execute the offline reader logic after the
   * application is loaded.
   */
  updateOfflineReaderOnApplicationLoad() {
    if (this.isOfflineOrMobileEnvironment()) {
      this.offlineClient.updateOnApplicationLoad();
    }
  }

  /**
   * Entry point for mobile refresh on resume.
   */
  updateMobileReaderOnResume() {
    if (this.isOfflineOrMobileEnvironment()) {
      this.mobileClient.refreshOnResume();
    }
  }

  /**
   * TODO: This was called on app load and is called on some push
   *       notifications. Problem is that all those cases can have
   *       different logic on different platforms.
   *       Refactor to less generic name the be clear of intent.
   */
  refresh() {
    if (this.isOfflineOrMobileEnvironment()) {
      this.client.refresh();
    }
  }

  private isOfflineOrMobileEnvironment(): boolean {
    return Environment.isNativeApp() || Environment.useIndexedDB();
  }

  // TODO: Check if we only need this for mobile and offline updates.
  loadResults(
    config: Config,
    archiveDate: ArchiveDate,
    category: ReaderCategory,
    limit: number
  ): JQueryPromise<ReaderResults> {
    return this.client.loadResults(config, archiveDate, category, limit);
  }

  // TODO: Check if we only need this for mobile and offline updates.
  refreshArchiveDates(): JQueryPromise<ArchiveDate[]> {
    return this.client.refreshArchiveDates();
  }

  // TODO: Check if we only need this for mobile and offline updates.
  refreshArchiveDateSummary(
    archiveDate: ArchiveDate
  ): JQueryPromise<ArchiveDateSummary> {
    return this.client.refreshArchiveDateSummary(archiveDate);
  }

  // TODO: Check if we only need this for mobile and offline updates.
  refreshCurrentArchiveDateResults(refreshView: boolean): JQueryPromise<void> {
    return this.client.refreshCurrentArchiveDateResults(refreshView);
  }
}

class DataUpdate {
  protected client: Client;
  protected app: App;
  private resultLoader: ReaderResultLoader;

  constructor(app: App) {
    this.client = ApiClient.create(ClientContext.DataUpdate);
    this.resultLoader = new ReaderResultLoader(app);
    this.app = app;
  }

  refresh() {
    this.app.fetchAndReRenderArchiveDatesWithConditionalSelect().done(() => {
      this.refreshCurrentArchiveDateResults(true);
    });
  }

  protected switchToLatestArchiveDate() {
    return $.when(this.refreshArchiveDates()).then((aDates: ArchiveDate[]) => {
      this.app.navigation.reRenderArchiveDatesAndSwitchToLatest(aDates);
    });
  }

  loadResults(
    config: Config,
    archiveDate: ArchiveDate,
    category: ReaderCategory,
    limit: number
  ): JQueryPromise<ReaderResults> {
    return this.client.getReaderResults(config, archiveDate, category, limit);
  }
  refreshArchiveDates(): JQueryPromise<ArchiveDate[]> {
    return this.client.getArchiveDates(this.app.config);
  }

  refreshArchiveDateSummary(
    archiveDate: ArchiveDate
  ): JQueryPromise<ArchiveDateSummary> {
    return this.client.getArchiveDateSummary(this.app.config, archiveDate);
  }

  refreshCurrentArchiveDateResults(refreshView: boolean): JQueryPromise<void> {
    const hideUpdatingMessage = () => {
      const notificationProps: UpdatingResultsProps = {
        displayNotification: false,
      };
      Layout.renderUpdatingResults(notificationProps);
    };
    const currentArchiveDate = this.app.getCurrentArchiveDate();
    const fetchResults = () => {
      this.resultLoader
        .fetch(currentArchiveDate)
        .done((map: Map<ReaderCategory, ReaderResults>) => {
          if (refreshView) {
            if (currentArchiveDate.id === this.app.getCurrentArchiveDate().id) {
              var scroller = new Scroller();
              scroller.saveScrollPosition();
              map.forEach((results, category) => {
                this.app.markNewReaderResults(results);
                this.app.newReaderResultsLoaded(results.collection);
                this.app.renderNewResults(
                  currentArchiveDate,
                  category,
                  results
                );
              });
              scroller.restoreScrollPosition(false);
            }
            hideUpdatingMessage();
          }
        });
    };
    const onSuccess = () => {
      fetchResults();
    };
    const onFailure = () => {
      if (refreshView) {
        hideUpdatingMessage();
      }
      fetchResults();
    };

    return this.app.dataStore
      .refreshArchiveDateContents()
      .then(onSuccess, onFailure);
  }
}

/**
 * TODO: Refactoring in process
 *
 * All the mobile reader logic is supposed to be here.
 */
class MobileDataUpdate extends DataUpdate {
  /**
   * This is the logic after the mobile app is resumed.
   *
   * It updates the configuration changes to IndexedDB and they take
   * effect on next user interaction.
   *
   * Conditionally switch to latest archive date and do result/summary
   * loading and rendering if that happend.
   *
   * In any case a call to reload and render the summary for selected
   * archive date.
   *
   * TODO: Case for double renreding of summary?
   */
  refreshOnResume() {
    this.app.fetchAndReRenderArchiveDatesWithConditionalSelect().done(() => {
      this.refreshCurrentArchiveDateResults(true);
    });
    this.app.updateAndRenderArchiveDateSummary();
  }
}

/**
 * TODO: Refactoring in process
 *
 * All the offline reader logic is supposed to be here.
 */
class OfflineDataUpdate extends DataUpdate {
  constructor(app: App) {
    super(app);
  }

  /**
   * This is the logic after the application is loaded.
   *
   * It updates the configuration changes to IndexedDB and they take
   * effect on next user interaction.
   *
   * Automatic switch to latest archive date and result/summary loading
   * with rendering.
   */
  updateOnApplicationLoad() {
    this.switchToLatestArchiveDate().done(() => {
      this.refreshCurrentArchiveDateResults(true);
    });
  }
}
