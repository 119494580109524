import { Network } from '@capacitor/network';

// FIXME: This class should be refactored using the @capacitor/network API,
// especially its networkStatusChange event.
// See https://bluereport.atlassian.net/browse/DEV-1180
export default class OfflineHandler {
  private offlineCallback?: () => void;
  private onlineCallback?: () => void;

  start() {
    Network.getStatus().then((status) => {
      console.log('Network status', status);
      if (status.connected) {
        if (this.onlineCallback) {
          this.onlineCallback();
        }
      } else {
        if (this.offlineCallback) {
          this.offlineCallback();
        }
      }
    });

    if (this.offlineCallback) {
      document.addEventListener('offline', this.offlineCallback, false);
    }

    if (this.onlineCallback) {
      document.addEventListener('online', this.onlineCallback, false);
    }
  }

  stop() {
    if (this.onlineCallback) {
      document.removeEventListener('online', this.onlineCallback);
    }
    if (this.offlineCallback) {
      document.removeEventListener('offline', this.offlineCallback);
    }
  }

  setOfflineHandler(f: () => void) {
    this.offlineCallback = f;
  }

  setOnlineHandler(f: () => void) {
    this.onlineCallback = f;
  }
}
