import { ArchiveDateSummary as ArchiveDateSummaryResponse } from '../api/response/ArchiveDateSummary';
import ArchiveDateSummaryModel from '../ArchiveDateSummary';
import { IIDBRecord } from './IIDBRecord';
import CommonFinders from './CommonFinders';

export default class ArchiveDateSummary implements IIDBRecord {
  public identifier: string;
  public archiveDateId: Number;
  public response: ArchiveDateSummaryResponse;

  constructor(
    response: ArchiveDateSummaryResponse,
    configId: number,
    archiveDateId: number
  ) {
    this.response = response;
    this.identifier = `${configId}-${archiveDateId}`;
    this.archiveDateId = archiveDateId;
  }

  static findByConfigIdAndArchiveDateId(
    configId: Number,
    archiveDateId: Number,
    objectStore: IDBObjectStore
  ): JQueryPromise<ArchiveDateSummaryModel> {
    let identifier = `${configId}-${archiveDateId}`;
    return CommonFinders.find(identifier, objectStore);
  }
}
