import {
  SearchResponse,
  SearchResult,
  SummarySearchResult,
} from './api/response/SearchResult';
import ReaderResults from './ReaderResults';
import { ReaderResults as ReaderResultsResponse } from './api/response/ReaderResults';
import SummaryResult from './SummaryResult';
import ReaderResult from './ReaderResult';
import { Config as ConfigResponse } from './api/response/Config';
import Config from './Config';
import ConfigAndArchiveDates from './ConfigAndArchiveDates';
import { ArchiveDate as ArchiveDateResponse } from './api/response/ArchiveDate';
import { UserGroupBranding as UserGroupBrandingResponse } from './api/response/UserGroupBranding';
import UserGroupBranding from './UserGroupBranding';
import { ArchiveDateSummary as ArchiveDateSummaryResponse } from './api/response/ArchiveDateSummary';
import ArchiveDateSummary from './ArchiveDateSummary';
import ArchiveDate from './ArchiveDate';
import { AgentResult as AgentResultResponse } from './api/response/AgentResult';
import { ReaderCategory as ReaderCategoryResponse } from './api/response/ReaderCategory';
import ReaderCategory from './ReaderCategory';
import { Categories as CategoriesResponse } from './api/response/ReaderCategories';
import { Source as SourceResponse } from './api/response/Source';
import Source from './Source';
import Result from './Result';
import { ArchiveDateContents as ArchiveDateContentsResponse } from './api/response/ArchiveDateContents';
import ArchiveDateContents from './ArchiveDateContents';
import { AccessibleReaders as AccessibleReadersResponse } from './api/response/AccessibleReaders';
import AccessibleReader from './AccessibleReader';
import Utils from './Utils';
import * as _ from 'underscore';

export default class ApiResponseParser {
  static parseSearchResults(response: SearchResponse): ReaderResults {
    const results = response.collection;
    let position = results.length + 1;

    const readerResults = results.map((searchResult) => {
      position--;
      if ('agent_result' in searchResult) {
        return ApiResponseParser.parseSearchResult(searchResult, position);
      } else {
        return ApiResponseParser.parseSearchSummaryResult(
          searchResult,
          position
        );
      }
    });

    const collection = new ReaderResults(
      readerResults,
      results.length,
      response.more_results_available
    );
    collection.sort();
    return collection;
  }

  static parseSearchSummaryResult(
    response: SummarySearchResult,
    position: number
  ): SummaryResult {
    return new SummaryResult(
      -response.delivery_time,
      response.title,
      response.text,
      moment(response.delivery_time * 1000)
    );
  }

  static parseSearchResult(
    response: SearchResult,
    position: number
  ): ReaderResult {
    const primarySource = ApiResponseParser.parsePrimarySource(
      response.agent_result
    );
    const result = ApiResponseParser.parseAgentResult(
      response.agent_result,
      primarySource,
      [],
      position
    );

    return new ReaderResult(response.id, result);
  }

  static parseConfig(response: ConfigResponse): Config {
    const config = new Config(response);
    const userGroupBranding = ApiResponseParser.parseUserGroupBranding(
      response.user_group_branding,
      config
    );
    config.user_group_branding = userGroupBranding;
    return config;
  }

  static parseConfigAndArchiveDates(
    response: ConfigResponse
  ): ConfigAndArchiveDates {
    const config = new Config(response);
    const unparsedArchiveDates: ArchiveDateResponse[] = response.archive_dates;
    const archiveDates: ArchiveDate[] = ApiResponseParser.parseArchiveDates(
      unparsedArchiveDates,
      config
    );
    const data = new ConfigAndArchiveDates(config, archiveDates);
    const userGroupBranding = ApiResponseParser.parseUserGroupBranding(
      response.user_group_branding,
      config
    );
    config.user_group_branding = userGroupBranding;
    return data;
  }

  static parseUserGroupBranding(
    response: UserGroupBrandingResponse,
    config: Config
  ): UserGroupBranding | undefined {
    if (Utils.isPresent(response)) {
      return new UserGroupBranding(response);
    } else {
      return;
    }
  }

  static parseArchiveDateSummary(
    response: ArchiveDateSummaryResponse
  ): ArchiveDateSummary {
    return new ArchiveDateSummary(
      response.title,
      response.text,
      response.archive_date_id
    );
  }

  static parseArchiveDateSummaryForContents(
    response: ArchiveDateSummaryResponse,
    archiveDateId: number
  ): ArchiveDateSummary {
    const title = response && response.title;
    const text = response && response.text;
    return new ArchiveDateSummary(title, text, archiveDateId);
  }

  static parseArchiveDates(
    response: ArchiveDateResponse[],
    config: Config
  ): ArchiveDate[] {
    return _.map(response, (archiveDate: ArchiveDateResponse) => {
      return new ArchiveDate(archiveDate, config);
    });
  }

  static parseDeprecatedCategories(
    response: ReaderCategoryResponse[]
  ): ReaderCategory[] {
    return _.map(response, (category: ReaderCategoryResponse) => {
      return new ReaderCategory(category);
    });
  }

  static parseCategories(
    response: CategoriesResponse,
    archiveDateId: number
  ): ReaderCategory[] {
    return _.map(response.collection, (category: ReaderCategoryResponse) => {
      const parsedCategory = new ReaderCategory(category);
      if (category.results) {
        const results: ReaderResults = ApiResponseParser.parseReaderResults(
          category.results,
          archiveDateId
        );
        parsedCategory.readerResults = results;
      }
      return parsedCategory;
    });
  }

  static parseReaderResults(
    response: ReaderResultsResponse,
    archiveDateId: number
  ): ReaderResults {
    let resultsCollection = _.map(response.collection, (result) => {
      let agentResult = result.agent_result;
      let sources = new Array<Source>();
      let primarySource = ApiResponseParser.parsePrimarySource(agentResult);
      sources = _.map(agentResult.metadata.sources, (source: any) => {
        return this.parseSource(source, agentResult);
      });

      let standardResult = ApiResponseParser.parseAgentResult(
        agentResult,
        primarySource,
        sources,
        result.position
      );

      let readerResult = new ReaderResult(result.id, standardResult);

      return readerResult;
    });
    let resultsCount = response.count;
    let readerResults = new ReaderResults(resultsCollection, resultsCount);
    readerResults.sort();
    return readerResults;
  }

  static parseSource(source: SourceResponse, agentResult: AgentResultResponse) {
    let pageNumber: string | undefined;
    if (agentResult.metadata.kind === 'MMOClip') {
      let apiPage = agentResult.metadata.clip_metadata.source_pages;
      if (apiPage.indexOf('Seite ') === 0) {
        pageNumber = apiPage.substr(6);
      } else {
        pageNumber = apiPage;
      }
    } else {
      pageNumber = source.source_metadata.page_number;
    }
    return new Source(
      source.name,
      source.clip_url === null ? undefined : source.clip_url,
      agentResult.metadata.kind,
      source.source_metadata.reach,
      source.source_metadata.circulation_distributed,
      source.source_metadata.thumbnail_url_with_play_button,
      agentResult.metadata.downloadable,
      pageNumber,
      agentResult.metadata.is_print_source,
      agentResult.metadata.radio_tv_clip,
      agentResult.metadata.clip_metadata.image_url,
      source.source_metadata.media_kind
    );
  }

  static parseImageUrl(clip_metadata: any): string {
    const metadata = clip_metadata;

    if (Utils.isPresent(metadata.image_url)) {
      return metadata.image_url;
    } else {
      return '';
    }
  }

  static parseAgentResult(
    response: AgentResultResponse,
    primarySource: Source,
    sources: Source[],
    position: number
  ): Result {
    let importantHighlight =
      response.important && window.app.config.highlight_important_results;

    return new Result(
      response.id,
      response.metadata.title,
      primarySource,
      sources,
      moment(response.metadata.clip_created_at * 1000),
      moment(response.metadata.publish_time * 1000),
      response.metadata.clip_id,
      response.metadata.clip_attachments,
      response.metadata.teaser,
      response.metadata.full_text,
      response.metadata.languages[0],
      importantHighlight,
      position,
      response.metadata.reading_time,
      response.rich_text,
      response.metadata.author,
      response.metadata.print_edition,
      response.metadata.kind,
      Boolean(response.metadata.clip_metadata.youtube_content_removed)
    );
  }

  static parsePrimarySource(response: AgentResultResponse): Source {
    const clipImg = ApiResponseParser.parseImageUrl(
      response.metadata.clip_metadata
    );

    return this.parseSource(response.metadata.primary_source, response);
  }

  static parseArchiveDateContents(
    response: ArchiveDateContentsResponse,
    archiveDateId: number
  ): ArchiveDateContents {
    const summary: ArchiveDateSummary =
      ApiResponseParser.parseArchiveDateSummaryForContents(
        response.summary,
        archiveDateId
      );
    const categories: CategoriesResponse = {
      collection: response.categories,
    };
    const parsedCategories: ReaderCategory[] =
      ApiResponseParser.parseCategories(categories, archiveDateId);
    return new ArchiveDateContents(summary, parsedCategories);
  }

  static parseAccessibleReaders(
    response: AccessibleReadersResponse
  ): AccessibleReader[] {
    return response.collection.map((reader) => {
      return new AccessibleReader(
        reader.id,
        reader.title,
        reader.client,
        reader.token
      );
    });
  }

  static fixCountInArchiveDateContents(
    response: ArchiveDateContentsResponse
  ): ArchiveDateContentsResponse {
    _.each(response.categories, (category) => {
      const results = category.results;
      if (_.isUndefined(results.count)) {
        results.count = results.collection.length;
      }
    });
    return response;
  }
}
