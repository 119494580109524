import ReaderResult from './ReaderResult';
import SummaryResult from './SummaryResult';

export default class ReaderResults {
  constructor(
    public collection: (ReaderResult | SummaryResult)[],
    public count: number,
    public moreAvailable?: boolean // TODO: make it non nullable
  ) {}

  resultCount() {
    if (this.count) {
      return this.count.toString();
    } else {
      return this.collection.length.toString();
    }
  }
  sort() {
    this.collection.sort((a, b) => {
      return b.position - a.position;
    });
  }
}
