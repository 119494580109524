import ArchiveDateSummary from './ArchiveDateSummary';
import ReaderCategory from './ReaderCategory';

export default class ArchiveDateContents {
  public summary: ArchiveDateSummary;
  public categories: ReaderCategory[];
  constructor(summary: ArchiveDateSummary, categories: ReaderCategory[]) {
    this.summary = summary;
    this.categories = categories;
  }
}
