import { IIndexedDatabase } from '../IIndexedDatabase';
import IndexedDatabaseFactory from '../IndexedDBFactory';
import FailureReport from '../FailureReport';
import Config from '../Config';
import ApiResponseParser from '../ApiResponseParser';
import CleanOldOfflineBranding from '../CleanOldOfflineBranding';
import OfflineBranding from '../OfflineBranding';
import ArchiveDate from '../ArchiveDate';
import ArchiveDateContents from '../ArchiveDateContents';
import { ArchiveDateContents as ArchiveDateContentsResponse } from './response/ArchiveDateContents';
import ReaderCategory from '../ReaderCategory';
import ReaderResults from '../ReaderResults';
import { ReaderResults as ReaderResultsResponse } from './response/ReaderResults';
import AccessibleReader from '../AccessibleReader';
import ArchiveDateSummary from '../ArchiveDateSummary';
import Analytics from '../Analytics';
import { SearchResponse, SearchResult } from './response/SearchResult';
import { Settings } from '../Settings';
import RequestUri from './RequestUri';
import Environment from '../Environment';
import Utils from '../Utils';
import * as _ from 'underscore';

export type AccessKind = 'view' | 'click';

export default class Client {
  private token: string;
  private requestUri: RequestUri;
  private readerDB: IIndexedDatabase;

  constructor(token: string) {
    this.token = token;
    this.requestUri = new RequestUri();
    this.readerDB = IndexedDatabaseFactory.getIndexedDatabase();
  }

  logClipAccess(clipId: number, agentResultId: number, accessKind: AccessKind) {
    let params = {
      clip_id: clipId,
      agent_result_id: agentResultId,
      kind: accessKind,
    };
    this.post('clip_access_logs', params);
  }

  get(resource: string, resourceParams = {}) {
    let apiEndPoint = this.requestUri.build(resource);
    let apiParams = this.resourceParameters(resourceParams);
    let requestPayload = {
      apiEndPoint: apiEndPoint,
      apiParams: apiParams,
    };

    return $.get(apiEndPoint, apiParams).fail((error) => {
      FailureReport.addPayload(requestPayload);
      this.runFailureCallbacks(error);
    });
  }

  head(resource: string): JQueryPromise<XMLHttpRequest> {
    const path = this.requestUri.build(resource);
    const query = $.param(this.resourceParameters({}));
    const url = `${path}?${query}`;
    return $.ajax({
      type: 'HEAD',
      async: true,
      url: url,
    });
  }

  loadLogo(path: string): JQueryPromise<Blob> {
    const pathWithSeed = path + '?s=' + moment().unix();
    const deferred = $.Deferred<Blob>();
    const xhr = new XMLHttpRequest();
    xhr.open('GET', pathWithSeed, true);
    xhr.responseType = 'blob';
    xhr.addEventListener('load', () => {
      if (xhr.status === 200) {
        deferred.resolve(xhr.response);
      } else {
        deferred.reject();
      }
    });
    xhr.send();
    return deferred.promise();
  }

  post(resource: string, resourceParams = {}): JQueryPromise<JQueryXHR> {
    let apiEndPoint = this.requestUri.build(resource);
    let apiParams = this.resourceParameters(resourceParams);
    return $.post(apiEndPoint, apiParams);
  }

  put(resource: string, resourceParams = {}): JQueryPromise<XMLHttpRequest> {
    let apiEndPoint = this.requestUri.build(resource);
    let apiParams = this.resourceParameters(resourceParams);
    let opts = {
      type: 'POST',
      url: apiEndPoint,
      data: _.extend(apiParams, { _method: 'PUT' }),
    };
    return $.ajax(opts);
  }

  getConfigAndArchiveDates(): JQueryPromise<Config> {
    return this.getAppParameters().then((appParameters) => {
      return this.get('config/reader', appParameters).then((response) => {
        const ugBrandingResponse = response.user_group_branding;
        const configAndArchiveDates =
          ApiResponseParser.parseConfigAndArchiveDates(response);
        const config = configAndArchiveDates.configuration;
        this.readerDB.updateConfigurations(response, this.token);
        this.readerDB.updateArchiveDates(response.archive_dates, config.id);

        if (Environment.isNativeApp() && Utils.isPresent(ugBrandingResponse)) {
          return this.loadLogo(ugBrandingResponse.logo_path).then(
            (content: Blob) => {
              const cleaner = new CleanOldOfflineBranding(config);
              cleaner.removeBranding(ugBrandingResponse);
              cleaner.removeDifferentConfigBrandings();
              const branding = new OfflineBranding(config);
              branding.storeBranding(ugBrandingResponse);
              let storeLogoPromise = branding.storeLogo(content);
              return storeLogoPromise.then(() => configAndArchiveDates);
            }
          );
        } else {
          return configAndArchiveDates;
        }
      });
    });
  }

  getArchiveDateContents(
    config: Config,
    archiveDate: ArchiveDate
  ): JQueryPromise<ArchiveDateContents> {
    return this.get(this.archiveDateContentsPath(config, archiveDate)).then(
      (response: ArchiveDateContentsResponse) => {
        const fixedResponse =
          ApiResponseParser.fixCountInArchiveDateContents(response);
        this.readerDB.updateArchiveDateContents(
          fixedResponse,
          config.id,
          archiveDate.id
        );
        const contents = ApiResponseParser.parseArchiveDateContents(
          fixedResponse,
          archiveDate.id
        );
        return contents;
      }
    );
  }

  getReaderResults(
    config: Config,
    archiveDate: ArchiveDate,
    category: ReaderCategory,
    queryLimit: number
  ): JQueryPromise<ReaderResults> {
    return this.get(this.readerResultsPath(config, archiveDate, category), {
      limit: queryLimit,
    }).then((r) => {
      var response: ReaderResultsResponse = r;
      this.readerDB.updateReaderResults(
        response,
        config.id,
        archiveDate.id,
        category.id
      );
      let results = ApiResponseParser.parseReaderResults(
        response,
        archiveDate.id
      );
      window.app.dataStore.setResults(category.id, results);
      return results;
    });
  }

  getArchiveDates(config: Config): JQueryPromise<ArchiveDate[]> {
    return this.get(this.archiveDatesIndexPath(config)).then((response) => {
      let archiveDates = ApiResponseParser.parseArchiveDates(response, config);
      this.readerDB.updateArchiveDates(response, config.id);
      window.app.dataStore.setArchiveDates(archiveDates);
      return archiveDates;
    });
  }

  getAccessibleReaders(): JQueryPromise<AccessibleReader[]> {
    return this.get(this.accessibleReadersPath()).then((response) => {
      let accessibleReaders =
        ApiResponseParser.parseAccessibleReaders(response);
      window.app.dataStore.setAccessibleReaders(accessibleReaders);
      return accessibleReaders;
    });
  }

  getArchiveDateSummary(
    config: Config,
    archiveDate: ArchiveDate
  ): JQueryDeferred<ArchiveDateSummary> {
    var path = this.archiveDateSummaryPath(config, archiveDate);
    const request = this.get(path);
    const deferred: JQueryDeferred<ArchiveDateSummary> = $.Deferred();
    request
      .done((response) => {
        const key = 'archive_date_id';
        response[key] = archiveDate.id;
        let summary = ApiResponseParser.parseArchiveDateSummary(response);
        this.readerDB.updateArchiveDateSummary(
          response,
          config.id,
          archiveDate.id
        );
        window.app.dataStore.setSummary(summary);
        deferred.resolve(summary);
      })
      .fail((f) => {
        if (f.status === 404) {
          const summary = new ArchiveDateSummary(
            undefined,
            undefined,
            undefined
          );
          window.app.dataStore.setSummary(summary);
          deferred.resolve(summary);
        } else {
          deferred.reject(f);
        }
      });

    return deferred;
  }

  buildPdfDownloadUrl(clipId: number): string {
    var path = clipId + '.pdf';
    var query = $.param(this.resourceParameters({ inline: true }));
    return this.requestUri.downloadUrl() + path + '?' + query;
  }

  buildHandoutPdfDownloadUrl(config: Config, archiveDateId: number): string {
    const archiveDatesPath = this.archiveDatesPath(config);
    const path = archiveDatesPath + `${archiveDateId}/handout`;
    return path;
  }

  buildAbsoluteHandoutPdfDownloadUrl(
    config: Config,
    archiveDateId: number
  ): string {
    const path = this.buildHandoutPdfDownloadUrl(config, archiveDateId);
    const query = $.param(this.resourceParameters({}));
    return this.requestUri.build(`${path}?${query}`);
  }

  doSearch(query: string): JQueryPromise<ReaderResults> {
    Analytics.logSearch(query);
    const deferred = $.Deferred<ReaderResults>();
    const onSuccess = (r: SearchResponse) => {
      const response: SearchResponse = r;
      const results = ApiResponseParser.parseSearchResults(response);
      deferred.resolve(results);
    };
    const onFailure = () => {
      const response: SearchResponse = {
        collection: new Array<SearchResult>(),
        more_results_available: false,
      };
      const results = ApiResponseParser.parseSearchResults(response);
      deferred.resolve(results);
    };

    $.when(this.get('reader_search', { query: query })).then(
      onSuccess,
      onFailure
    );

    return deferred.promise();
  }

  sendSignupEmail(email: string, name: string) {
    let params = { email: email, name: name };
    let apiEndPoint = this.requestUri.build('reader_signup');
    return $.post(apiEndPoint, params);
  }

  postEmail(email: string): JQueryPromise<JQueryXHR> {
    return this.post('reader_email_authentication', {
      email: email,
      device: Environment.platform(),
    });
  }

  updateSettings(settings: Settings): JQueryPromise<XMLHttpRequest> {
    return this.put('reader_settings', {
      email_notifications_enabled: settings.emailNotificationsEnabled,
      push_notifications_enabled: settings.pushNotificationsEnabled,
    });
  }

  static sameValidConfig(
    storedToken: string,
    newToken: string
  ): JQueryPromise<boolean> {
    const client = new Client(storedToken);
    return client
      .get('same_valid_config', {
        new_reader_token: newToken,
      })
      .then((response) => {
        return !!response.same_valid_config;
      })
      .fail((error: JQueryXHR) => {
        const code = error.status;
        const text = error.statusText;
        const documentState = error.readyState;
        FailureReport.notify(
          `RequestError - SameValidConfig`,
          `Code: ${code} - ${text} - ReadyState: ${documentState}`
        );
      });
  }

  private configurationsPath(config: Config): string {
    return 'reader_configurations/' + config.id + '/';
  }

  private archiveDatesIndexPath(config: Config): string {
    var configPath = this.configurationsPath(config);
    var rest = 'archive_dates?skip_first_if_empty=1';
    return configPath + rest;
  }

  private accessibleReadersPath(): string {
    return 'accessible_readers';
  }

  private archiveDatesPath(config: Config): string {
    return this.configurationsPath(config) + 'archive_dates/';
  }

  private archiveDatePath(config: Config, archiveDate: ArchiveDate): string {
    return this.archiveDatesPath(config) + archiveDate.id + '/';
  }

  private archiveDateSummaryPath(
    config: Config,
    archiveDate: ArchiveDate
  ): string {
    return this.archiveDatesPath(config) + archiveDate.id + '/summary';
  }

  private categoriesPath(config: Config, archiveDate: ArchiveDate): string {
    return this.archiveDatePath(config, archiveDate) + 'categories/';
  }

  private categoryPath(
    config: Config,
    archiveDate: ArchiveDate,
    category: ReaderCategory
  ): string {
    return this.categoriesPath(config, archiveDate) + category.id + '/';
  }

  private readerResultsPath(
    config: Config,
    archiveDate: ArchiveDate,
    category: ReaderCategory
  ): string {
    return this.categoryPath(config, archiveDate, category) + 'reader_results';
  }

  private archiveDateContentsPath(
    config: Config,
    archiveDate: ArchiveDate
  ): string {
    return this.archiveDatePath(config, archiveDate) + 'contents';
  }

  private failureCallbacks: Array<(error: JQueryXHR) => void> = [];
  fail(callback: (error: JQueryXHR) => void): void {
    this.failureCallbacks.push(callback);
  }

  private runFailureCallbacks(error: JQueryXHR) {
    _.each(this.failureCallbacks, (callback) => {
      callback(error);
    });
  }

  private getAppParameters(): JQueryPromise<any> {
    let appParameterPromise = $.Deferred<any>();
    if (Environment.isNativeApp()) {
      appParameterPromise.resolve({
        reader_token: this.token,
        appname: window.app.name,
        appversion: window.app.version,
      });
    } else {
      appParameterPromise.resolve({
        appname: 'desktop',
        appversion: 'current',
      });
    }
    return appParameterPromise;
  }

  private resourceParameters(params: any) {
    return $.extend({ reader_token: this.token }, params);
  }
}
