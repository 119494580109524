import Config from './Config';
import { UserGroupBranding as UserGroupBrandingResponse } from './api/response/UserGroupBranding';
import Environment from './Environment';
import * as _ from 'underscore';

export default class CleanOldOfflineBranding {
  private storageSupported: boolean;

  constructor(private config: Config) {
    this.storageSupported = Environment.isLocalStorageSupported();
  }

  /*
    Removes the brandings that were based on config id rather than
    client id.
    This caused duplication of brandings even though they should be
    the same branding for all of the readers within the client.
  */
  removeBranding(branding: UserGroupBrandingResponse) {
    const brandingKeysValues = _.pairs(branding);
    _.each(brandingKeysValues, (keyValArr: Array<string>) => {
      const itemKey = this.itemBrandingKey(keyValArr[0]);
      this.removeItem(itemKey);
    });
    this.removeItem(this.itemBrandingKey('logo'));
  }

  // clean up branding keys that belong to a different config than
  // the current one
  removeDifferentConfigBrandings() {
    const currentClientId = this.config.client_id.toString();
    for (var key in localStorage) {
      if (
        key.includes('ugBranding-') &&
        !key.includes('ugBranding-' + currentClientId)
      ) {
        this.removeItem(key);
      }
    }
  }

  private itemBrandingKey(item: string): string {
    return `ugBranding-${this.config.id}-${item}`;
  }

  private removeItem(itemKey: string) {
    if (this.storageSupported) {
      window.localStorage.removeItem(itemKey);
    }
  }
}
