import Config from './Config';
import ArchiveDate from './ArchiveDate';

export default class ConfigAndArchiveDates {
  constructor(
    public configuration: Config,
    public archiveDates: ArchiveDate[]
  ) {
    this.configuration = configuration;
    this.archiveDates = archiveDates;
  }
}
