import AccessToken from './AccessToken';
import TokenManager from './TokenManager';
import ActivationToken from './ActivationToken';
import Login from './Login';
import Client from './api/Client';
import ReaderActivation from './ReaderActivation';
import { Activation as ActivationResponse } from './api/response/Activation';
import BrowserChrome from './BrowserChrome';

/** Handles token logic, calls callback when reader has obtained an access
 *  token and is ready to start
 */
export interface ReaderStartCallback {
  (token: AccessToken): void;
}

export default class ReaderTokenInit {
  private tokenManager: TokenManager;
  private login?: Login;

  constructor(private startReaderApp: ReaderStartCallback) {
    this.tokenManager = window.app.tokenManager;
  }

  startReader(activationToken: ActivationToken | undefined) {
    activationToken =
      activationToken || this.tokenManager.parseActivationToken();

    var accessTokenString: string | null = this.tokenManager.getToken();

    if (accessTokenString == null && activationToken != null) {
      // There's an activation token, so try to activate to get an
      // access token
      this.activateAndStartReader(activationToken);
    } else if (accessTokenString != null) {
      // There's an access token available (param or cache)
      const oldCachedToken = this.tokenManager.loggedInTokenString();
      var token = new AccessToken(accessTokenString);
      if (oldCachedToken && this.tokenManager.providedToken()) {
        // there is a cached token and an rtoken param is provided,
        // figure out which one to use
        this.chooseTokenAndStart(oldCachedToken, accessTokenString);
      } else if (
        this.tokenManager.loggedInTokenString() &&
        activationToken != null
      ) {
        // When there's a cached token and an activation token param,
        // just use the activation token param
        this.activateAndStartReader(activationToken);
      } else {
        //A user is accessing the app with an rtoken, use it
        $('#login').hide();
        this.startReaderApp(token);
      }
    } else {
      // there's no token at all available, so show the normal login screen
      this.showEmailActivationView();
    }
  }

  private chooseTokenAndStart(oldToken: string, newToken: string) {
    Client.sameValidConfig(oldToken, newToken).done((sameAndValid: boolean) => {
      if (sameAndValid) {
        this.clearUrlParams();
        this.startReaderApp(new AccessToken(oldToken));
      } else {
        const token = new AccessToken(newToken);
        this.startReaderApp(token);
      }
    });
  }

  // Rewrites the url using pushState api removing the params after
  // hostname
  private clearUrlParams() {
    this.tokenManager.clearQuery();
    window.history.replaceState({}, '', '/');
  }

  private activateAndStartReader(activationToken: ActivationToken) {
    var readerActivation = new ReaderActivation(activationToken);
    readerActivation
      .getAccessToken()
      .done((data: ActivationResponse) => {
        this.storeAccessTokenAndStartReader(new AccessToken(data.reader_token));
      })
      .fail(() => {
        this.showActivationFailureScreen();
      });

    // clear activation token from url after activation so the users
    // can't bookmark it and get confused
    this.clearUrlParams();
  }

  private showActivationFailureScreen() {
    if (!this.login) {
      this.login = new Login(this.startReaderApp);
    }
    this.login.show();
    this.login.showTokenInputErrorScreen();
  }

  private storeAccessTokenAndStartReader(accessToken: AccessToken) {
    this.tokenManager.store(accessToken);
    this.startReaderApp(accessToken);
  }

  showEmailActivationView() {
    new BrowserChrome().transparentStatusBar('default');
    if (!this.login) {
      this.login = new Login(this.startReaderApp);
    }
    this.login.start();
  }
}
