import ArchiveDate from './ArchiveDate';
import Sidebar from './Sidebar';
import App from './App';
import Config from './Config';
import Layout from './Layout';
import ReaderCategory from './ReaderCategory';
import { ResultsByCategoryId } from './ResultsByCategoryId';
import CategoryWithResultCount from './CategoryWithResultCount';
import Results from './Results';
import ArchiveDateSummary from './ArchiveDateSummary';
import * as _ from 'underscore';

export default class Navigation {
  private selectedReaderResultId?: number;
  private navigationContainerClass: string;
  private currentlySelectedArchiveDate?: ArchiveDate;
  public sidebar?: Sidebar;

  constructor(
    private app: App,
    private config: Config,
    private archiveDates: ArchiveDate[]
  ) {
    this.navigationContainerClass = '.layout__navigation';
    this.app = app;
    this.selectedReaderResultId = undefined;
    this.config = config;
    this.archiveDates = archiveDates;
  }

  reRenderArchiveDatesAndSwitchToLatest(archiveDates: ArchiveDate[]) {
    this.archiveDates = archiveDates;
    this.switchToLatestArchiveDate();
  }

  private switchToLatestArchiveDate() {
    const archiveDate = _.first(this.archiveDates);

    if (archiveDate === undefined) {
      throw 'no archive dates';
    }

    this.handleArchiveDateSelect(archiveDate.id);
  }

  findArchiveDateById(archiveDateId: number): ArchiveDate | undefined {
    return _.findWhere(this.archiveDates, { id: Number(archiveDateId) });
  }

  selectByReaderResultId(id: number) {
    Layout.selectReaderResult(id);
    // TODO: Why? Cannot we just use DOM?
    this.selectedReaderResultId = id;
  }

  private getCurrentArchiveDate() {
    return window.app.dataStore.getCurrentArchiveDate();
  }

  private isArchiveDateInDb(ad: ArchiveDate): JQueryPromise<boolean> {
    return this.app.dataStore.isArchiveDateInDb(ad);
  }

  private addResultCountToCategories(
    categories: ReaderCategory[],
    results: ResultsByCategoryId
  ): JQueryPromise<CategoryWithResultCount[]> {
    var self = this;
    var dfd = $.Deferred<CategoryWithResultCount[]>();
    var promises: JQueryPromise<CategoryWithResultCount>[] = _.map(
      categories,
      (cat: ReaderCategory) => {
        var catWithResCount = new CategoryWithResultCount(
          cat,
          +results[cat.id].collection.length
        );
        return $.Deferred<CategoryWithResultCount>().resolve(catWithResCount);
      }
    );

    $.when
      .apply($, promises)
      .done((...categories: CategoryWithResultCount[]) => {
        dfd.resolve(categories);
      });

    return dfd.promise();
  }

  private handleArchiveDateSelect(archiveDateId: number): JQueryPromise<void> {
    var navigation = this;
    navigation.showLoadingIndicator();
    navigation.app.clearResults();
    var selectedDate = _.find(navigation.archiveDates, (archiveDate) => {
      return Number(archiveDate.id) === archiveDateId;
    });

    if (selectedDate === undefined) {
      throw 'archive date not found';
    }

    const currentArchiveDate = navigation.currentlySelectedArchiveDate;
    navigation.currentlySelectedArchiveDate = selectedDate;

    return this.app.dataStore
      .switchArchiveDate(selectedDate)
      .done(() => {
        this.loadCategoryResults();
      })
      .fail(() => {
        navigation.currentlySelectedArchiveDate = currentArchiveDate;
        navigation.hideLoadingIndicator();
      });
  }

  // TODO: Method here is called showLoadingIndicator but it also resets
  //       the category results too? Misleading.
  showLoadingIndicator(): void {
    this.renderNavigation({ isLoading: true });
  }

  hideLoadingIndicator(): void {
    Layout.hideNavigationLoadingIndicator();
  }

  private resultCount(results: Results): string {
    if (results.count) {
      return results.count.toString();
    } else {
      var count = results.collection.length;
      if (this.app.agentResultQueryLimit === count) {
        return `> ${count}`;
      } else {
        return count.toString();
      }
    }
  }

  private clearAllArchiveDateResults() {
    let self = this;
    $.when(window.app.dataStore.getCategories()).done(
      (categories: ReaderCategory[]) => {
        _.each(categories, (category: ReaderCategory) => {
          self.renderNavigation({ isLoading: false });
        });
      }
    );
  }

  private loadCategoryResults() {
    $.when(
      window.app.dataStore.getCategories(),
      window.app.dataStore.getResults(),
      window.app.dataStore.getSummary()
    ).done(
      (
        categories: ReaderCategory[],
        results: ResultsByCategoryId,
        summary: ArchiveDateSummary
      ) => {
        this.app.afterAllResultsLoadCallback(
          this.getCurrentArchiveDate(),
          categories,
          results
        );
      }
    );
  }

  renderNavigation(opts: { isLoading: boolean }) {
    this.app.renderReactNavigation(opts);
  }
}
