import * as React from 'react';
import ArchiveDate from '../ArchiveDate';
import CategoryWithResultCount from '../CategoryWithResultCount';
import ArchiveDateSummary from '../ArchiveDateSummary';
import Config from '../Config';
import { EnvironmentInformation } from '../EnvironmentInformation';
import AccessibleReader from '../AccessibleReader';
import Utils from '../Utils';
import Spinner from './SpinnerComponent';
import ArchiveDateDropdown from './ArchiveDateDropdown';
import SidebarSupportPageComponent from './SidebarSupportPageComponent';
import SidebarSettings from './SidebarSettings';
import Environment from '../Environment';
import OfflineNotification from '../mobile/OfflineNotification';
import Analytics from '../Analytics';
import * as _ from 'underscore';

export interface SidebarProps {
  archiveDates: ArchiveDate[];
  categories: CategoryWithResultCount[];
  currentArchiveDate: ArchiveDate;
  summary: ArchiveDateSummary;
  config: Config;
  isLoading: boolean;
  isOpen: boolean;
  pushNotificationsEnabled: boolean;
  emailNotificationsEnabled: boolean;
  environment: EnvironmentInformation;
  dataLastUpdatedAt: moment.Moment | undefined;
  accessibleReaders: AccessibleReader[];
}

export interface SidebarState {
  hideSignoutSubmenu: boolean;
  supportPageIsClosed: boolean;
  settingsPageIsClosed: boolean;
}

export default class SidebarComponent extends React.Component<
  SidebarProps,
  SidebarState
> {
  constructor(props: SidebarProps) {
    super(props);
    this.state = {
      hideSignoutSubmenu: true,
      supportPageIsClosed: true,
      settingsPageIsClosed: true,
    };
  }

  componentDidMount() {
    this.bindHideSidebarOnEsc();
    new Hammer($(this.sidebarSelector())[0]).on('swipeleft', () => {
      this.store().closeSidebar();
    });
    new Hammer($(this.sidebarSelector())[0]).on('swiperight', () => {
      this.store().openSidebar();
    });
    setTimeout(() => {
      if (this.props.isOpen) {
        this.props.config.onSidebarOpen();
      } else {
        this.props.config.onSidebarClose();
      }
    }, 500);
  }

  render() {
    let sidebarKlass = this.sidebarKlass();
    return (
      <div id="sidebar" className={sidebarKlass}>
        <ul className="sidebar__tree">
          <li className="sidebar__top">
            <div className="sidebar__top__container">
              {this.navContainer()}
              {this.closeButton()}
            </div>
          </li>
        </ul>

        <div id="sidebar__notification__container"></div>
        {this.support()}
        {this.settings()}
        {this.download()}
        {this.categories()}
        {this.signout()}
        {this.actions()}
      </div>
    );
  }

  sidebarShade() {
    return 'dark';
  }

  sidebarKlass() {
    let out = 'layout__sidebar ' + this.sidebarShade();
    if (this.props.isOpen) {
      out += ' sidebar-overlay';
    } else {
      out += ' no-sidebar-overlay';
    }

    return out;
  }

  categories() {
    if (this.props.currentArchiveDate != undefined) {
      return this.sidebarList();
    } else {
      return this.sidebarEmpty();
    }
  }

  store() {
    return window.app.dataStore;
  }

  summary() {
    let summary = this.props.summary;
    let clickHandler = (ev: any) => {
      this.store().sidebar.handleSidebarItemClick(ev.currentTarget);
    };
    if (Utils.isNotBlank(summary.title)) {
      return (
        <li className="sidebar__title button-behavior" onClick={clickHandler}>
          <h2>{summary.title}</h2>
        </li>
      );
    }
  }

  sidebarEmpty() {
    return <div className="sidebar__list-container"></div>;
  }

  sidebarList() {
    var categories = this.props.categories;
    if (this.props.config.hideEmptyCategories()) {
      categories = _.filter(categories, (cat) => {
        return cat.resultCount > 0;
      });
    }

    let cats = categories.map((cat: CategoryWithResultCount) => {
      return this.category(cat);
    });

    if (this.props.isLoading) {
      return <Spinner style="dark" />;
    } else {
      return (
        <div className="sidebar__list-container enable-ios-scrolling">
          <ul className="sidebar__list">
            {this.summary()}
            {cats}
          </ul>
        </div>
      );
    }
  }

  category(cat: CategoryWithResultCount) {
    let category = cat.category;
    let n_count = cat.resultCount;
    let title = category.name;
    let clickHandler = (ev: any) => {
      this.store().sidebar.handleSidebarItemClick(ev.currentTarget);
    };
    return (
      <li data-sidebar-category-result-count={n_count} key={category.id}>
        <div
          className="sidebar__item button-behavior"
          data-category-id={category.id}
          onClick={clickHandler}
        >
          <div className="sidebar__item__title">{title}</div>
          <div className="sidebar__item__count">{n_count}</div>
        </div>
      </li>
    );
  }

  nextButton() {
    let archiveDateIndex = this.store().sidebar.archiveDateIndex(
      this.props.currentArchiveDate
    );
    let isItTheLastDate = archiveDateIndex == 0;
    let klass = 'sidebar__top__date__next button-behavior';
    if (isItTheLastDate) {
      klass += ' is-disabled';
    }
    let button = (
      <div className={klass}>
        <div
          className="sidebar__top__date__next__icon"
          onClick={this.switchToNextArchiveDate.bind(this)}
        ></div>
      </div>
    );
    return button;
  }

  previousButton() {
    let archiveDateIndex = this.store().sidebar.archiveDateIndex(
      this.props.currentArchiveDate
    );
    let isItTheFirstDate =
      archiveDateIndex == this.props.archiveDates.length - 1;
    let klass = 'sidebar__top__date__previous button-behavior';
    if (isItTheFirstDate) {
      klass += ' is-disabled';
    }
    let button = (
      <div className={klass}>
        <div
          className="sidebar__top__date__previous__icon"
          onClick={this.switchToPreviousArchiveDate.bind(this)}
        ></div>
      </div>
    );
    return button;
  }

  navContainer() {
    if (this.store().hasCurrentArchiveDate()) {
      return this.navContent();
    } else {
      return this.navEmpty();
    }
  }

  navContent() {
    if (this.props.config.one_time_reader) {
      return <div className="sidebar__nav__container"></div>;
    } else {
      return (
        <div className="sidebar__nav__container">
          {this.previousButton()}
          <div id="sidebar__top__date" className="sidebar__top__date">
            <ArchiveDateDropdown archiveDates={this.props.archiveDates} />
          </div>
          {this.nextButton()}
        </div>
      );
    }
  }

  navEmpty() {
    return <div className="sidebar__nav__container"></div>;
  }

  closeButton() {
    let button = (
      <div
        className="sidebar__top__close button-behavior"
        onClick={this.hide.bind(this)}
      >
        <div className="sidebar__top__close__icon"></div>
      </div>
    );
    return button;
  }

  switchToPreviousArchiveDate() {
    this.store().sidebar.switchToPreviousArchiveDate();
  }

  switchToNextArchiveDate() {
    this.store().sidebar.switchToNextArchiveDate();
  }

  hide() {
    window.app.dataStore.closeSidebar();
  }

  // Same reason as before, this does not belong to this component
  private sidebar(): JQuery {
    return $(this.sidebarSelector());
  }

  sidebarSelector() {
    return '#sidebar';
  }

  private bindHideSidebarOnEsc() {
    $(document).on('keyup', (e) => {
      if (e.keyCode == 27) {
        // 27 is escape key
        this.hide();
      }
    });
  }

  // support block
  // TODO abstract later in a separate component
  //
  support() {
    let lastUpdate = 'n/a';
    if (this.props.dataLastUpdatedAt) {
      lastUpdate = this.props.dataLastUpdatedAt.format('L LTS');
    }
    let supportPageText: string | undefined = undefined;
    if (this.props.config.user_group_branding) {
      supportPageText = this.props.config.user_group_branding.supportPageText();
    }
    return (
      <SidebarSupportPageComponent
        isOpen={!this.state.supportPageIsClosed}
        onCloseClick={this.onCloseSupportButtonClick.bind(this)}
        environment={this.props.environment}
        isNativeApp={false}
        readerName={this.props.config.title}
        readerID={this.props.config.id.toString()}
        lastUpdate={lastUpdate}
        supportPageText={supportPageText}
      />
    );
  }

  settings() {
    let klass = 'sidebar__settings-container';
    if (this.state.settingsPageIsClosed) {
      klass += ' is-closed';
    } else {
      klass += ' is-opened';
    }

    let settings = (
      <div className={klass}>
        <div className="sidebar__content__container">
          <SidebarSettings
            closeSettingsPage={this.onCloseSettingsButtonClick.bind(this)}
            emailNotificationsEnabled={this.props.emailNotificationsEnabled}
            pushNotificationsEnabled={this.props.pushNotificationsEnabled}
          />
        </div>
      </div>
    );
    return settings;
  }

  // download block
  download() {
    let readerHandoutHeadline = I18n.t('sidebar.pages.reader_handout.headline');
    let readerHandoutContent = I18n.t('sidebar.pages.reader_handout.content');
    let download = (
      <div className="sidebar__download-container is-closed">
        <div className="sidebar__top__container">
          <div className="sidebar__support__headline">
            {readerHandoutHeadline}
          </div>
          <div className="sidebar__top__close button-behavior">
            <div className="sidebar__top__close__icon"></div>
          </div>
        </div>

        <div className="sidebar__content__container">
          {readerHandoutContent}
        </div>
      </div>
    );
    return download;
  }

  //signout block
  signout() {
    var klass = 'sidebar__signout_submenu enable-ios-scrolling';

    if (this.state.hideSignoutSubmenu) {
      klass += ' invisible';
    }

    let buttonConfirm = I18n.t('sidebar.buttons.logout_confirm');
    let signout = (
      <ul className={klass} data-signout-submenu>
        {this.accessibleReaders()}
        <li
          className="sidebar__signout_submenu__item button-behavior"
          onClick={this.handleClickSignoutSubmenu.bind(this)}
        >
          <div
            className="sidebar__signout_submenu__item__label"
            data-button-confirm-signout
          >
            {buttonConfirm}
          </div>
        </li>
      </ul>
    );

    return signout;
  }

  private accessibleReaders() {
    const clientNames = this.props.accessibleReaders.map((r) => r.client);
    const showClientName = _.unique(clientNames).length > 1;

    return this.props.accessibleReaders.map((reader) => {
      return (
        <li
          key={reader.id}
          className="sidebar__signout_submenu__item button-behavior"
          onClick={() => this.handleClickReaderSwitch(reader)}
        >
          <div
            className="sidebar__signout_submenu__item__label"
            data-button-switch-reader
            data-switch-to-id={reader.id}
          >
            {reader.title}
            {showClientName && (
              <span className="sidebar__signout_submenu__item__label-company">
                {reader.client}
              </span>
            )}
          </div>
        </li>
      );
    });
  }

  private handleClickSignoutSubmenu() {
    this.setState({
      hideSignoutSubmenu: true,
      supportPageIsClosed: this.state.supportPageIsClosed,
      settingsPageIsClosed: true,
    });
    this.store().sidebar.tokenManager.expireCache();
    window.location.reload();
  }

  private handleClickReaderSwitch(reader: AccessibleReader) {
    this.store().sidebar.tokenManager.replaceToken(reader.accessToken());
    // if query parameters for deep links exist we need to clear them,
    // otherwise we just reload
    if (window.location.search) {
      window.location.search = '';
    } else {
      window.location.reload();
    }
  }

  private toggleSignoutSubmenu() {
    this.sidebar().find('[data-signout-submenu]').toggle();
  }

  //actions block
  actions() {
    let tokenGiven = this.store().sidebar.tokenManager.tokenGiven();
    let doesNotHaveHandout = !this.props.config.hasHandout();
    let refreshKlass = 'sidebar__actions__refresh button-behavior';
    let supportKlass = 'sidebar__actions__support button-behavior';
    let downloadKlass = 'sidebar__actions__download button-behavior';
    let signoutKlass = 'sidebar__actions__signout button-behavior';
    let settingsKlass = 'sidebar__actions__settings button-behavior';

    if (tokenGiven) {
      signoutKlass += ' invisible';
      settingsKlass += ' invisible';
    }
    if (doesNotHaveHandout) {
      downloadKlass += ' invisible';
    }
    if (!this.state.supportPageIsClosed) {
      supportKlass += ' is-opened';
    }
    if (!this.state.settingsPageIsClosed) {
      settingsKlass += ' is-opened';
    }

    let actions = (
      <div className="sidebar__actions">
        <div
          className={refreshKlass}
          data-button-refresh
          onClick={this.onRefreshButtonClick.bind(this)}
        >
          <div className="sidebar__actions__refresh__icon"></div>
        </div>
        <div
          className={supportKlass}
          data-button-support
          onClick={this.onSupportButtonClick.bind(this)}
        >
          <div className="sidebar__actions__support__icon"></div>
        </div>
        <div
          className={settingsKlass}
          data-button-settings
          onClick={this.onSettingsButtonClick.bind(this)}
        >
          <div className="sidebar__actions__settings__icon"></div>
        </div>
        <div
          className={downloadKlass}
          data-button-download
          onClick={this.onDownloadButtonClick.bind(this)}
        >
          <div className="sidebar__actions__download__icon"></div>
        </div>
        <div
          className={signoutKlass}
          data-button-signout
          onClick={this.onSignoutButtonClick.bind(this)}
        >
          <div className="sidebar__actions__signout__icon"></div>
        </div>
      </div>
    );
    return actions;
  }

  private onSettingsButtonClick() {
    const settingsPageIsOpened = !this.state.settingsPageIsClosed;

    this.setState({
      hideSignoutSubmenu: true,
      supportPageIsClosed: true,
      settingsPageIsClosed: settingsPageIsOpened,
    });
    if (!settingsPageIsOpened && Environment.isMobileOffline()) {
      const offlineNotification = new OfflineNotification(
        'sidebar__settings__notification__container'
      );
      offlineNotification.mount();
    }
  }

  private onSupportButtonClick() {
    if (this.state.supportPageIsClosed) {
      this.sendSupportEventToAnalytics();
      this.setStateSupportPageIsClosed(false);
    } else {
      this.setStateSupportPageIsClosed(true);
    }
  }

  private onCloseSettingsButtonClick() {
    this.closeAllThePagesExceptCategoryList();
  }

  private onCloseSupportButtonClick() {
    this.closeAllThePagesExceptCategoryList();
  }

  private closeAllThePagesExceptCategoryList() {
    this.setState({
      hideSignoutSubmenu: true,
      supportPageIsClosed: true,
      settingsPageIsClosed: true,
    });
  }

  private onSignoutButtonClick() {
    this.sidebar().find('[data-signout-submenu]').toggleClass('invisible');
  }

  private onDownloadButtonClick() {
    this.store().sidebar.onDownloadButtonClick();
  }

  private onRefreshButtonClick() {
    this.store().sidebar.onRefreshButtonClick();
  }

  private sendSupportEventToAnalytics() {
    Analytics.sendEvent('reader_support');
  }

  private setStateSupportPageIsClosed(value: boolean) {
    this.setState({
      hideSignoutSubmenu: this.state.hideSignoutSubmenu,
      supportPageIsClosed: value,
      settingsPageIsClosed: true,
    });
  }
}
