import Spinner from './SpinnerComponent';
import * as React from 'react';

export interface UpdatingResultsProps {
  displayNotification: boolean;
}

export default class UpdatingResults extends React.Component<
  UpdatingResultsProps,
  {}
> {
  render() {
    const updating = I18n.t('notifications.update_in_progress');
    let style: React.CSSProperties = { display: 'none' };

    if (this.props.displayNotification) {
      style = {};
    }

    return (
      <div className="notification__loading_results" style={style}>
        <div className="notification__loading_results-spinner">
          <Spinner style="light" />
        </div>
        <div className="notification__loading_results-text">{updating}</div>
      </div>
    );
  }
}
