import * as _ from 'underscore';
import { Capacitor } from '@capacitor/core';
import FailureReport from './FailureReport';

/**
 * Environment is a class that knows the global environmental things of current
 * session.
 */
export default class Environment {
  static enablePullToRefresh() {
    if (Environment.isNativeApp()) {
      const app = window.app;
      const contentEl = document.getElementById('pull-to-refresh-content');
      const ptrEl = document.getElementById('pull-to-refresh-notification');

      if (contentEl != undefined && ptrEl != undefined) {
        WebPullToRefresh.init({
          loadingFunction: app.pullToRefreshUpdate.bind(app),
          contentEl: contentEl,
          ptrEl: ptrEl,
        });
      } else {
        throw new Error(
          '#pull-to-refresh-content or #pull-to-refresh-notification are not in the DOM'
        );
      }
    }
  }

  static deviceReady(): JQueryPromise<void> {
    if (typeof window.app.deviceReadyPromise === 'undefined') {
      let deviceReadyPromise = $.Deferred<void>();

      if (Environment.isNativeApp()) {
        document.addEventListener('deviceready', () => {
          deviceReadyPromise.resolve();
        });
      } else {
        deviceReadyPromise.resolve();
      }

      window.app.deviceReadyPromise = deviceReadyPromise;
    }

    return window.app.deviceReadyPromise;
  }

  static usePullToRefresh() {
    return Environment.isTouchDevice();
  }

  // We are more than 50% sure of it
  static isTouchDevice() {
    return 'ontouchstart' in window || navigator.MaxTouchPoints > 0;
  }

  static isProduction(): boolean {
    return (
      !!window.app?.id.match(/^ch\.cognita\.reader\.bluereport$/i) ||
      !!window.location.host.match(/bluereport\.net$/)
    );
  }

  static isReleasePreview(): boolean {
    return (
      !!window.app?.id.match(/^ch\.cognita\.reader\.bluereport\.staging$/i) ||
      !!window.location.host.match(/release-preview\.bluereport\.de$/)
    );
  }

  static isLargeScreen(): boolean {
    return $(document).width() > 570;
  }

  static isLocalStorageSupported(): boolean {
    try {
      localStorage.setItem('testing-write', '');
      return true;
    } catch (e: unknown) {
      if (e instanceof Error) {
        if (e.message.match(/QuotaExceededError/)) {
          return false;
        }
      }
      return true;
    }
  }

  static storeToLocalStorage(key: string, value: string): void {
    if (this.isLocalStorageSupported()) {
      try {
        window.localStorage.setItem(key, value);
      } catch (e) {
        FailureReport.notify(
          'Localstorage.setItem failed!',
          `Could not store ${key}`
        );
      }
    }
  }

  static deviceIsReady(): JQueryPromise<void> {
    let deferred = $.Deferred<void>();
    if (this.isNativeApp()) {
      document.addEventListener('deviceready', function () {
        deferred.resolve();
      });
    } else {
      deferred.resolve();
    }
    return deferred.promise();
  }

  static errorReportingEnvironment(): string {
    if (!!window.location.host.match(/staging\.reader/)) {
      return 'staging';
    } else if (!!window.location.host.match(/production\.reader/)) {
      return 'preproduction';
    } else if (!!window.location.host.match(/local|vagrant\.reader/)) {
      return 'development';
    } else {
      return 'production';
    }
  }

  static isNativeApp(): boolean {
    return Capacitor.isNativePlatform();
  }

  static isMobileSafari(): boolean {
    const userAgent = navigator.userAgent;
    const isIOS = userAgent.match(/iPad|iPod|iPhone/i);
    const isWebKit = userAgent.match(/WebKit/i);
    const isNotChrome = !userAgent.match(/CriOS/i);

    if (isIOS != undefined && isWebKit != undefined && isNotChrome) {
      return isIOS && isWebKit && isNotChrome;
    } else {
      return false;
    }
  }

  static isIosDevice(): boolean {
    return Capacitor.getPlatform() === 'ios';
  }

  static isIE8OrIE9(): boolean {
    return !!window.navigator.userAgent.match(/MSIE\s+[89]/);
  }

  static isIE(): boolean {
    return !!window.navigator.userAgent.match(/Trident/);
  }

  static platform(): 'android' | 'ios' | 'web' {
    return Capacitor.getPlatform() as 'android' | 'ios' | 'web';
  }

  static useMessagePusher(): boolean {
    return !this.isNativeApp();
  }

  static useIndexedDB(): boolean {
    return (
      !_.isUndefined(window.indexedDB) &&
      // On iOS it is okay to use mobile safari when we are going via
      // cordova.
      (this.isNativeApp() || !this.isMobileSafari()) &&
      !this.isSafariPrivateMode()
    );
  }

  static isSafariPrivateMode(): boolean {
    try {
      window.localStorage.setItem('testingPrivateMode', '5');
      return false;
    } catch (e) {
      return true;
    }
  }

  static isMobileOffline(): boolean {
    return Environment.isNativeApp() && window.isMobileOffline;
  }
}
