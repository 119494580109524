import * as _ from 'underscore';
import { StatusBar, Style } from '@capacitor/status-bar';
import TokenManager from './TokenManager';
import App from './App';
import OfflineNotification from './mobile/OfflineNotification';
import PdfNotAvailableNotification from './mobile/PdfNotAvailableNotification';
import DataStore from './DataStore';
import ArchiveDate from './ArchiveDate';
import { ArchiveDateSwitchType } from './ArchiveDateSwitchType';
import Environment from './Environment';
import ApiClient from './ApiClient';
import { ClientContext } from './ApiClient';
import Analytics from './Analytics';
import CategoryWithResultCount from './CategoryWithResultCount';
import ReaderCategory from './ReaderCategory';
import Utils from './Utils';

interface HandleArchiveDateSelectCallback {
  (archiveDateId: number): JQueryPromise<void>;
}

export default class Sidebar {
  private archiveDateSwitchTimer?: number;
  public tokenManager: TokenManager;
  private app: App;
  private offlineNotification?: OfflineNotification;
  private pdfNotAvailableNotification?: PdfNotAvailableNotification;
  // tracks the number of archive dates the user has moved forward
  // (positive number) or backward in the archive date list
  // (negative number). Used for google analytics archive date switch
  // tracking
  private archiveDateSwitchCounter: number;
  private _isOpen: boolean;
  private _isLoading: boolean;
  private store: DataStore;

  constructor(store: DataStore) {
    this.store = store;
    this.tokenManager = window.app.tokenManager;
    this.app = window.app;
    this.archiveDateSwitchCounter = 0;

    this._isOpen = false;
    this._isLoading = false;
  }

  public isOpen() {
    return this._isOpen;
  }

  public isLoading() {
    return this._isLoading;
  }

  // TODO: put those in appstate
  public open() {
    this._isOpen = true;
    this.store.rerender();
  }

  public close() {
    this._isOpen = false;
    this.store.rerender();
  }

  public loading() {
    this._isLoading = true;
    this.store.rerender();
  }

  public notLoading() {
    this._isLoading = false;
    this.store.rerender();
  }

  sidebarSelector() {
    return '#sidebar';
  }

  currentArchiveDate() {
    return this.store.selectedArchiveDate;
  }

  //we still need this
  archiveDateSwitchedCallback(ad: ArchiveDate) {
    let oldIdx = _.indexOf(this.store.archiveDates, this.currentArchiveDate());
    let newIdx = _.indexOf(this.store.archiveDates, ad);
    let count = newIdx - oldIdx;
    let switchType =
      count > 0 ? ArchiveDateSwitchType.Back : ArchiveDateSwitchType.Forward;
    this.archiveDateSwitchCounter = count < 0 ? -count - 1 : count - 1;
    this.switchToArchiveDateAfterDelay(ad, switchType);
  }

  private unbindSidebarOnEsc() {
    $(document).off('keyup');
  }

  public onRefreshButtonClick() {
    if (!Environment.isMobileOffline()) {
      this.close();
      this.store.rerender();
      this.app.pullToRefreshUpdate();
    } else {
      this.showOfflineNotification();
    }
  }

  onDownloadButtonClick() {
    const apiClient = ApiClient.create(ClientContext.App);
    const url = apiClient.buildAbsoluteHandoutPdfDownloadUrl(
      this.app.config,
      this.currentArchiveDate().id
    );
    this.notifyUserAndAnalyticsAboutTheDownload();
    return this.app.linkOpener.open(url);
  }

  private notifyUserAndAnalyticsAboutTheDownload(): JQueryPromise<void> {
    let deferred = $.Deferred<void>();
    const apiClient = ApiClient.create(ClientContext.App);
    const url = apiClient.buildHandoutPdfDownloadUrl(
      this.app.config,
      this.currentArchiveDate().id
    );

    apiClient
      .head(url)
      .done((_data, _text, xhr) => {
        if (xhr.status != 200) {
          this.pdfDownloadHasFailed();
        } else {
          Analytics.sendEvent('reader_download_available');
        }
        deferred.resolve();
      })
      .fail((xhr) => {
        this.pdfDownloadHasFailed();
        deferred.resolve();
      });
    return deferred.promise();
  }

  private pdfDownloadHasFailed() {
    this.showPdfNotAvailableNotification();
    Analytics.sendEvent('reader_pdf_not_available');
  }

  switchToNextArchiveDate() {
    this.switchToArchiveDateAfterDelay(
      this.nextArchiveDate(),
      ArchiveDateSwitchType.Forward
    );
  }

  switchToPreviousArchiveDate() {
    this.switchToArchiveDateAfterDelay(
      this.previousArchiveDate(),
      ArchiveDateSwitchType.Back
    );
  }

  isArchiveDateInDb(ad: ArchiveDate) {
    return this.app.dataStore.isArchiveDateInDb(ad);
  }

  /**
   * Runs first or second callback depending on whether we are offline &
   * whether the given archive date is present in the database
   */
  private runIfArchiveDateNotInDb(
    ad: ArchiveDate,
    onlineOrInDbCallback: () => void,
    offlineAndNotInDbCallback: () => void
  ) {
    $.when(this.isArchiveDateInDb(ad)).done((isAdInDb: boolean) => {
      if (Environment.isMobileOffline() && !isAdInDb) {
        offlineAndNotInDbCallback();
      } else {
        onlineOrInDbCallback();
      }
    });
  }

  private showOfflineNotification() {
    this.offlineNotification = new OfflineNotification(
      'sidebar__notification__container'
    );
    this.offlineNotification.mount();
  }

  private showPdfNotAvailableNotification() {
    this.pdfNotAvailableNotification = new PdfNotAvailableNotification(
      'sidebar__notification__container'
    );
    this.pdfNotAvailableNotification.mount();
  }

  private currentArchiveDateIndex(): number {
    return this.archiveDateIndex(this.currentArchiveDate());
  }

  public archiveDateIndex(archiveDate: ArchiveDate): number {
    let timestamps = _.map(this.store.archiveDates, (ad) => {
      return ad.date().unix();
    });
    return _.indexOf(timestamps, archiveDate.date().unix());
  }

  private nextArchiveDate(): ArchiveDate {
    let currentIdx = this.currentArchiveDateIndex();
    let nextIdx = currentIdx - 1 >= 0 ? currentIdx - 1 : 0;

    let archiveDate = this.store.archiveDates[nextIdx];

    return archiveDate;
  }

  private previousArchiveDate(): ArchiveDate {
    let currentIdx = this.currentArchiveDateIndex();
    let maxIdx = this.store.archiveDates.length - 1;
    let nextIdx = currentIdx + 1 <= maxIdx ? currentIdx + 1 : currentIdx;
    let archiveDate = this.store.archiveDates[nextIdx];

    return archiveDate;
  }

  private switchDelay(): number {
    return 1500;
  }

  private sidebarIsOpen(): boolean {
    return this.sidebar().hasClass('sidebar-overlay');
  }

  // TODO: nav needs loading animation when switching to new ad
  private switchToArchiveDateAfterDelay(
    archiveDate: ArchiveDate,
    switchType: ArchiveDateSwitchType
  ) {
    this.increaseArchiveDateSwitchCounter();

    if (this.archiveDateSwitchTimer) {
      clearTimeout(this.archiveDateSwitchTimer);
      this.archiveDateSwitchTimer = undefined;
    }

    let self = this;
    const previousArchiveDate = this.store.getCurrentArchiveDate();
    this.store.setSelectedArchiveDate(archiveDate);
    this.loading();
    this.archiveDateSwitchTimer = window.setTimeout(() => {
      self.trackArchiveDateSwitch(switchType);
      self.switchToArchiveDate(archiveDate).fail(() => {
        this.store.setSelectedArchiveDate(previousArchiveDate);
        this.store.rerender();
      });
    }, this.switchDelay());
  }

  private bindRefreshButton() {
    let btn = $(this.sidebarSelector()).find('[data-button-refresh]');
    btn.addClass('button-behavior');
    btn.removeClass('button-behavior-disabled');
    btn.click(() => {
      this.onRefreshButtonClick();
    });
  }

  private increaseArchiveDateSwitchCounter() {
    this.archiveDateSwitchCounter = this.archiveDateSwitchCounter + 1;
  }

  private trackArchiveDateSwitch(switchType: ArchiveDateSwitchType) {
    Analytics.trackArchiveDateSwitch(this.archiveDateSwitchCounter, switchType);
    this.archiveDateSwitchCounter = 0;
  }

  private switchToArchiveDate(archiveDate: ArchiveDate): JQueryPromise<void> {
    let self = this;
    let maybeRender = () => {
      let self = this;
      if (self.isOpen()) {
        self.notLoading();
      }
    };

    let switchFailed = () => {
      if (self.isOpen()) {
        self.notLoading();
      }
      self.showOfflineNotification();
    };

    return this.store.handleArchiveDateSelect(archiveDate.id).then(
      () => {
        if (Environment.isNativeApp()) {
          this.runIfArchiveDateNotInDb(archiveDate, maybeRender, switchFailed);
        } else {
          maybeRender();
        }
      },
      () => {
        switchFailed();
      }
    );
  }

  public handleSidebarItemClick(el: React.MouseEvent<HTMLElement>) {
    let categoryId = +$(el).data('category-id');
    if (categoryId) {
      this.scrollNavigation(categoryId);
      if (this.resultCountForCategoryId(categoryId) > 0) {
        this.scrollDetail(categoryId);
      }
    } else {
      // scroll nav to top in case we clicked the summary

      $('.navigation__list, .layout__content').animate({ scrollTop: 0 });
    }

    this.close();
  }

  private resultCountForCategoryId(categoryId: number): number {
    let cats = this.addResultCountsToCategories(
      this.store.results,
      this.store.categories
    );
    let category = _.find(cats, (obj: CategoryWithResultCount) => {
      return obj.category.id === categoryId;
    });

    if (category === undefined) {
      throw 'category is undefined';
    }

    return category.resultCount;
  }

  private addResultCountsToCategories(
    results: any,
    categories: ReaderCategory[]
  ): CategoryWithResultCount[] {
    return _.map(categories, (category: ReaderCategory) => {
      var resultCount: number = 0;
      if (
        Utils.isPresent(results[category.id]) &&
        Utils.isPresent(results[category.id].resultCount())
      ) {
        resultCount = +results[category.id].resultCount();
      }

      let catWithCount = new CategoryWithResultCount(category, resultCount);
      return catWithCount;
    });
  }

  private scrollNavigation(categoryId: number) {
    let scrollingHandler = window.scrollingHandler;
    scrollingHandler.scrollNavigationToCategory(categoryId);
  }

  private scrollDetail(categoryId: number) {
    let navElementSelector =
      '.layout__navigation .navigation__group[data-category-id=' +
      categoryId +
      ']' +
      ' .navigation__item:first';

    let scrollingHandler = window.scrollingHandler;
    scrollingHandler.handleNavigationItemClick($(navElementSelector), false);
  }

  private sidebarBackground(): JQuery {
    return $('.layout__sidebar__background');
  }

  private sidebar(): JQuery {
    return $(this.sidebarSelector());
  }

  formatArchiveDate(archiveDate: ArchiveDate): string {
    let date = archiveDate.date();
    let today = moment();
    let yesterday = moment().subtract(1, 'day');
    let todayLabel = I18n.t('date.today');
    let yesterdayLabel = I18n.t('date.yesterday');
    let fmt = 'DD.MM.YYYY';

    if (date.isSame(today, 'day')) {
      return todayLabel + ', ' + date.format(fmt);
    } else if (date.isSame(yesterday, 'day')) {
      return yesterdayLabel + ', ' + date.format(fmt);
    } else {
      return date.format('ddd, ' + fmt);
    }
  }
}
