import { AppState } from '../AppState';
import SearchComponent from './SearchComponent';
import OfflineNotification from './OfflineNotification';
import CategoryTitle from './CategoryTitle';
import Utils from '../Utils';
import * as React from 'react';

export interface HeaderProps {
  state: AppState;
  categoryTitle: string;
  showCategoryTitle: boolean;
  lightColors?: boolean;
  showOfflineNotification: boolean;
  onOfflineNotificationClose: () => void;
}

export default class Header extends React.Component<HeaderProps, {}> {
  render() {
    let back = I18n.t('detail_view.back');

    return (
      <div className="layout__header_wrapper">
        <header className={this.headerClass()}>
          <a className="layout__headerback button-behavior">{back}</a>
          {this.hamburgerIcon()}
          {this.categoryTitleElement()}
          {this.search()}
          {this.offlineNotification()}
        </header>
      </div>
    );
  }

  headerClass() {
    let out = 'layout__header';

    if (this.props.lightColors) {
      out += ' inverted';
    }

    return out;
  }

  search() {
    return (
      <SearchComponent
        state={this.props.state}
        lightColors={this.props.lightColors}
      />
    );
  }

  hamburgerIcon() {
    let onClick = () => {
      this.store().openSidebar();
    };
    return (
      <a className="navigation__collapse button-behavior" onClick={onClick}></a>
    );
  }

  store() {
    return window.app.dataStore;
  }

  offlineNotification() {
    if (this.props.showOfflineNotification) {
      let callback = () => {
        window.app.dataStore.hideOfflineNotification();
      };
      return <OfflineNotification closeCallback={callback} />;
    }
  }

  categoryTitleElement() {
    if (
      this.props.showCategoryTitle &&
      Utils.isNotBlank(this.props.categoryTitle)
    ) {
      return <CategoryTitle categoryTitle={this.props.categoryTitle} />;
    }
  }

  componentDidMount() {
    /* Bind back button functionality in both mobile and web reader versions */
    let backButtonCallback = function () {
      window.scrollingHandler.updateNavigationSelection();
      $('.layout').removeClass('is-opened');
    };

    $('.layout__headerback').click(function () {
      backButtonCallback();
    });

    new Hammer($('#resultsDetail')[0]).on('swiperight', backButtonCallback);

    if (document.addEventListener) {
      document.addEventListener('backbutton', backButtonCallback);
    }
  }
}
