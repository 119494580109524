import React, { useState } from 'react';
import { Datum, NumDatum } from './types';
import Chart from './Chart';

export type Stats = [Datum, Datum, Datum];

export interface Props {
  title: string;
  type?: string;
  stats?: Stats;
  data?: Datum | NumDatum[];
}

export default function Widget({ title, stats, data, type }: Props) {
  const [days, setDays] = useState<number>(14);

  const formattedStats =
    stats?.map(({ label, value, format }) => {
      let val;
      if (typeof value === 'string' || typeof value === 'number') {
        val = value;
      } else {
        val = value[`${days}` as '7' | '14' | '28'];
      }
      return { label, value: format ? format(val) : val };
    }) || [];

  let dataValue;
  if (data && !Array.isArray(data)) {
    if (typeof data.value === 'string' || typeof data.value === 'number') {
      dataValue = data.value;
    } else {
      dataValue = data.value[`${days}` as '7' | '14' | '28'];
    }
  }

  return (
    <div className="widget">
      <header>{title} </header>

      <div className="select-days">
        <div>
          {Array.isArray(data) &&
            [7, 14, 28].map((n) => (
              <button
                key={n}
                className={n === days ? 'active' : ''}
                onClick={() => setDays(n)}
              >
                {n} {I18n.translate('insights.days')}
              </button>
            ))}
        </div>
      </div>

      <div className="type">{type && <small>{type}</small>}</div>

      {formattedStats.map(({ label, value }) => (
        <div className="stat" key={label}>
          <span className="value">{value}</span>
          <span className="label">{label}</span>
        </div>
      ))}

      <div className="main">
        {Array.isArray(data) && <Chart data={data.slice(data.length - days)} />}
        {data && !Array.isArray(data) && dataValue && (
          <>
            <span
              className={`value ${typeof dataValue === 'string' || dataValue > 99 ? 'large' : ''}`}
            >
              {data.format ? data.format(dataValue) : dataValue}
            </span>
            <span className="label">{data.label}</span>
          </>
        )}
      </div>
    </div>
  );
}
