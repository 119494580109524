import { Settings } from './Settings';

export default class OfflineSettings {
  private emailNotificationsEnabled?: boolean;
  private pushNotificationsEnabled?: boolean;

  store(settings: Settings) {
    this.emailNotificationsEnabled = settings.emailNotificationsEnabled;
    this.pushNotificationsEnabled = settings.pushNotificationsEnabled;
  }

  persist() {
    if (
      this.emailNotificationsEnabled != undefined &&
      this.pushNotificationsEnabled != undefined
    ) {
      const settings: Settings = {
        emailNotificationsEnabled: this.emailNotificationsEnabled,
        pushNotificationsEnabled: this.pushNotificationsEnabled,
      };
      window.app.dataStore.saveNotificationSettings(settings);
      this.emailNotificationsEnabled = undefined;
      this.pushNotificationsEnabled = undefined;
    }
  }
}
