import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { IosSilentNotifications } from '@aparajita/capacitor-ios-silent-notifications';
import { Badge } from '@capawesome/capacitor-badge';
import AccessToken from '../AccessToken';
import RequestUri from '../api/RequestUri';
import FailureReport from '../FailureReport';
import App from '../App';

/**
 * Interface for data being sent with the mobile notification
 * (the `data: any` in capacitor's PushNotificationSchema)
 */
export interface PushNotificationData {
  event_type?:
    | 'archive_date_published'
    | 'archive_date_results_changed'
    | 'archive_date_summary_changed'
    | 'reader_result_changed'
    | 'push_notification_settings_changed';
  archive_date_id?: string;
  configuration_id?: string;
  result_id?: string;
  agent_result_id?: string;
  push_notifications_enabled?: boolean;
  email_notifications_enabled?: boolean;
  // The following properties are part of the data sent via firebase but
  // currently not used. They are added here for informational purposes.
  body?: string;
  message?: string;
  icon?: string; // "notificationicon"
  title?: string; // "blueReport"
  'content-available'?: string;
  'gcm.message_id'?: string;
  'google.c.a.e'?: string;
  'google.c.fid'?: string;
  'google.c.sender.id'?: string;
}

/** Interface for callbacks which can be registered and run with
 *  the Notifications#addListener method
 */
export interface NotificationListener {
  run(data: PushNotificationData): void;
}

interface AndroidOptions {
  senderID: string;
  icon?: string;
}

export default class Notifications {
  private accessToken: AccessToken;
  private app: App;
  private listeners: NotificationListener[];

  constructor(accessToken: AccessToken, app: App) {
    this.accessToken = accessToken;
    this.listeners = [];
    this.app = app;
  }

  addListeners(listeners: NotificationListener[]) {
    listeners.forEach((listener) => this.listeners.push(listener));
  }

  startListening() {
    document.addEventListener('deviceready', () => this.onDeviceReady(), false);
  }

  private async onDeviceReady() {
    await PushNotifications.addListener('registration', (token) => {
      console.info('Registration token: ', token.value);
      this.onRegistration(token.value);
    });

    await PushNotifications.addListener('registrationError', (err) => {
      console.error('Registration error: ', err.error);
    });

    await PushNotifications.addListener(
      'pushNotificationReceived',
      (notification) => {
        console.log('Push notification received: ', notification);
        Badge.clear();
        if (Capacitor.getPlatform() !== 'ios') {
          // on iOS this is handled  by 'onSilentNotification'
          this.listeners.forEach((listener) => {
            listener.run(notification.data as PushNotificationData);
          });
        }
      }
    );

    await PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (action) => {
        console.log('Push notification action performed', action);
        if (Capacitor.getPlatform() !== 'ios') {
          // on iOS this is handled  by 'onSilentNotification'
          this.listeners.forEach((listener) => {
            listener.run(action.notification.data as PushNotificationData);
          });
        }
      }
    );

    if (Capacitor.getPlatform() === 'ios') {
      // Note: the onSilentNotification event is triggered for each notification
      // carrying data no matter if it is data-only or actually has a
      // notification object. Therefore we don't run the listeners for
      // 'pushNotificationReceived' and 'pushNotificationActionPerformed' above
      // under iOS.
      await IosSilentNotifications.addListener(
        'onSilentNotification',
        (notification) => {
          console.log('Data notification', notification);
          this.listeners.forEach((listener) => {
            listener.run(notification.data as PushNotificationData);
          });
        }
      );
    }

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      console.log('User denied permissions!');
    }

    await PushNotifications.register();
  }

  private onRegistration(token: string) {
    const self = this;

    var requestUri = new RequestUri();
    var url = requestUri.build('reader_notification_registrations');
    var params = {
      token: self.accessToken.toString(),
      device_id: token,
      device_kind: 'fcm', // only single device kind across any platform now
      configuration_id: this.app.currentlyActiveConfig().id,
      application_name: self.packageNameToApplicationName(window.app.id),
      application_version: window.app.version,
      device_platform: Capacitor.getPlatform(),
    };
    console.log('registering device');
    console.dir(params);
    $.post(url, params)
      .done((response) => {
        console.log('registration successful');
        console.dir(response);
      })
      .fail((error) => {
        console.log('registration failed');
        console.dir(error);
        FailureReport.notify('Registration error', JSON.stringify(error));
      });
  }

  private packageNameToApplicationName(pkgName: string) {
    return pkgName.substring('ch.cognita.'.length).toLowerCase();
  }
}
