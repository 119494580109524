import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { DetailListProps } from './components/DetailList';
import DetailList from './components/DetailList';
import Environment from './Environment';
import { HeaderProps } from './components/Header';
import Header from './components/Header';
import { UpdatingResultsProps } from './components/UpdatingResults';
import UpdatingResults from './components/UpdatingResults';

export default class Layout {
  static removeNotificationPadding() {
    $('#search-results .navigation__list').removeClass('notification-padding');
  }

  static addNotificationPadding() {
    $('#search-results .navigation__list').addClass('notification-padding');
  }

  static selectReaderResult(readerResultId: number) {
    const navigationHandle = '[data-reader-result-id=' + readerResultId + ']';
    const detailHandle =
      '.detail[data-reader-result-id=' + readerResultId + ']';
    const element = $('.navigation__grouplist').find(navigationHandle);
    const detailElement = $(detailHandle);

    $('.navigation__grouplist .is-selected').removeClass('is-selected');
    element.addClass('is-selected');
    $('.detail.selected-item').removeClass('selected-item');
    detailElement.addClass('selected-item');
  }

  static showDetailLoadingIndicator() {
    $('.layout__content [data-detail-loader]').show();
  }

  static hideDetailLoadingIndicator() {
    $('.layout__content [data-detail-loader]').hide();
  }

  static toggleSearchHighlighting(searchMode: boolean) {
    $('.layout__content').toggleClass('search-results-details', searchMode);
  }

  static makeNavigationGroupsVisible() {
    const list = $('.layout__navigation').find('.navigation__list');

    $.when(window.app.dataStore.getConfig()).done((config) => {
      if (config.hideEmptyCategories()) {
        list
          .find('.navigation__group.has-results.invisible')
          .removeClass('invisible');
      } else {
        list.find('.navigation__group.invisible').removeClass('invisible');
      }
    });
  }

  static hideNavigationLoadingIndicator() {
    const navigationContainer = $('.layout__navigation');

    const loader = navigationContainer.find('[data-sidebar-loader]');
    loader.hide();
  }

  static removeDetailResults() {
    let props: DetailListProps = {
      readerResults: [],
      categories: [],
      summary: undefined,
      config: undefined,
    };

    let element: any = DetailList;
    let component = React.createElement(element, props);
    let cnt = document.getElementById('resultsDetail');
    if (cnt != null) {
      ReactDOM.render(component, cnt);
    } else {
      throw new Error('#resultsDetail not found in the DOM');
    }
  }

  static detailResults() {
    return $('[data-detail-results]');
  }

  static showLoadingScreen() {
    $(document).ready(() => {
      $('[data-loading-layer]').show();
    });
  }

  static hideLoadingScreen() {
    $(document).ready(() => {
      $('[data-loading-layer]').hide();
    });
  }

  static addPlatformToBody() {
    Environment.deviceIsReady().done(() => {
      $('body').addClass('device__' + Environment.platform());
    });
  }

  static addIEToBody() {
    if (Environment.isIE()) {
      $('html').addClass('ie');
    }
  }

  static addAppNameToBody() {
    $('body').addClass('app-bluereport');
  }

  static renderHeader(props: HeaderProps) {
    let component = React.createElement(Header, props);
    let cnt = document.getElementById('header-container');
    if (cnt) {
      ReactDOM.render(component, cnt);
    }
  }

  static renderUpdatingResults(props: UpdatingResultsProps) {
    const cnt = document.getElementById('pull__loading-results');
    const updating = React.createElement(UpdatingResults, props);
    if (cnt != null) {
      ReactDOM.render(updating, cnt);
    } else {
      throw new Error('#pull__loading-results not found in the DOM');
    }
  }

  static rerenderHeader() {
    Layout.renderHeader(Layout.defaultHeaderProps());
  }

  static closeDetailView() {
    $('.layout').removeClass('is-opened');
  }

  static defaultHeaderProps(): HeaderProps {
    let showOffline =
      Environment.isNativeApp() &&
      window.isMobileOffline &&
      window.canShowOfflineNotification;

    const props: HeaderProps = {
      categoryTitle: window.categoryTitleCache,
      showCategoryTitle: window.app.dataStore.showCategoryTitle(),
      lightColors: window.app.config.useLightColors(),
      showOfflineNotification: showOffline,
      onOfflineNotificationClose: () => {
        this.onOfflineNotificationClose();
      },
      state: window.app.dataStore.lastAppState,
    };
    return props;
  }

  static onOfflineNotificationClose() {
    window.canShowOfflineNotification = false;
    Layout.rerenderHeader();
  }
}
