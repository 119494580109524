import Client from './api/Client';
import Layout from './Layout';
import FailureReport from './FailureReport';

/**
 *  Whenever you are in need of an API client, just ask me and I give you one.
 */
export enum ClientContext {
  DataLoader,
  DataStore,
  EmailActivation,
  DataUpdate,
  Search,
  App,
}

export default class ApiClient {
  static create(context: ClientContext): Client {
    const tokenManager = window.app.tokenManager;
    const client = new Client(tokenManager.getToken() || '');

    client.fail((error: JQueryXHR) => {
      const code = error.status;
      const text = error.statusText;
      const documentState = error.readyState;

      if (code === 401) {
        if (tokenManager.tokenGiven()) {
          window.app.dataStore.invalidateConfiguration(tokenManager.getToken());
          // TODO: localize error message
          window.app.showErrorScreen({
            title: 'Access expired',
            message:
              'Please use your daily pass to access your Executive Reader.',
          });
        } else {
          tokenManager.expireCache();
          Layout.hideLoadingScreen();
          window.app.showEmailActivationView();
          // This hack is needed so that polling is stopped for the moment
          // while the user is in the login screen, otherwise the polling
          // would periodically get a 401 and reset the login screen while
          // the user is waiting for his activation token.
          window.app.userIsAway();
        }
      } else {
        FailureReport.notify(
          `RequestError - ${ClientContext[context]}`,
          `Code: ${code} - ${text} - ReadyState: ${documentState}`
        );
      }
    });

    return client;
  }
}
