import { IIDBRecord } from './IIDBRecord';
import { ReaderResults as ReaderResultsResponse } from '../api/response/ReaderResults';
import CommonFinders from './CommonFinders';

export default class Result implements IIDBRecord {
  public identifier: string;
  public response: ReaderResultsResponse;
  public updatedAt: number; /** ms since epoch */

  constructor(
    response: ReaderResultsResponse,
    configId: number,
    archiveDateId: number,
    categoryId: number,
    updatedAt: number
  ) {
    this.identifier = `${configId}-${archiveDateId}-${categoryId}`;
    this.response = response;
    this.updatedAt = updatedAt;
  }

  static findByConfigIdAndArchiveDateIdAndCategoryId(
    configId: number,
    archiveDateId: number,
    categoryId: number,
    objectStore: IDBObjectStore
  ) {
    let identifier = `${configId}-${archiveDateId}-${categoryId}`;
    return CommonFinders.find(identifier, objectStore);
  }

  /** Returns the number of ms since epoch that passed since the
   *  last reader results update
   */
  static lastUpdatedAt(
    configId: number,
    archiveDateId: number,
    categoryId: number,
    objectStore: IDBObjectStore
  ): JQueryPromise<number> {
    let identifier = `${configId}-${archiveDateId}-${categoryId}`;
    let recordPromise: JQueryPromise<Result> = CommonFinders.findRecord(
      identifier,
      objectStore
    );

    return recordPromise.then((r: Result) => {
      return r.updatedAt || moment().valueOf();
    });
  }
}
