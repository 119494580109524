import { IIDBRecord } from './IIDBRecord';
import { ArchiveDate as ArchiveDateResponse } from '../api/response/ArchiveDate';
import CommonFinders from './CommonFinders';

export default class ArchiveDate implements IIDBRecord {
  public identifier: string;
  public response: ArchiveDateResponse[];
  public updatedAt: number; /** ms since epoch */

  constructor(
    response: ArchiveDateResponse[],
    configId: Number,
    updatedAt: number
  ) {
    this.identifier = `${configId}`;
    this.response = response;
    this.updatedAt = updatedAt;
  }

  static findByConfigId(
    configId: Number,
    objectStore: IDBObjectStore
  ): JQueryPromise<ArchiveDateResponse[]> {
    let identifier = `${configId}`;
    return CommonFinders.find(identifier, objectStore);
  }

  /** Returns the number of ms since epoch that passed since the
   *  last  archive date store update
   */
  static lastUpdatedAt(
    configId: Number,
    objectStore: IDBObjectStore
  ): JQueryPromise<number> {
    let identifier = `${configId}`;
    let recordPromise: JQueryPromise<ArchiveDate> = CommonFinders.findRecord(
      identifier,
      objectStore
    );

    return recordPromise.then((r: ArchiveDate) => {
      return r.updatedAt;
    });
  }
}
