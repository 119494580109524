import * as React from 'react';
import { EnvironmentInformation } from '../EnvironmentInformation';

export interface SidebarSupportPageProps {
  isOpen: boolean;
  onCloseClick: () => void;
  environment: EnvironmentInformation;
  isNativeApp: boolean;
  readerName: string;
  readerID: string;
  lastUpdate: string | undefined;
  supportPageText: string | undefined;
}

export default class SidebarSupportPageComponent extends React.Component<
  SidebarSupportPageProps,
  {}
> {
  render() {
    let supportHeadline = I18n.t('sidebar.pages.support.headline');
    let emailParams = {
      reader: `${this.readerName()} (ID: ${this.readerID()})`,
      lastUpdate: this.props.lastUpdate,
      name: 'n/a',
      version: 'n/a',
    };
    if (this.props.environment.type == 'app') {
      emailParams.name = this.props.environment.appName();
      emailParams.version = this.props.environment.appVersion();
    } else {
      emailParams.name = this.props.environment.browserName();
      emailParams.version = this.props.environment.browserVersion();
    }
    let supportContent = I18n.t('sidebar.pages.support.content', emailParams);
    if (this.props.supportPageText !== undefined) {
      supportContent = this.props.supportPageText;
    }
    let supportContainerKlass =
      'sidebar__support-container enable-ios-scrolling';
    if (this.props.isOpen) {
      supportContainerKlass += ' is-opened';
    } else {
      supportContainerKlass += ' is-closed';
    }
    let support = (
      <div className={supportContainerKlass}>
        <div className="sidebar__top__container">
          <div className="sidebar__support__headline">{supportHeadline}</div>
          <div
            className="sidebar__top__close button-behavior"
            onClick={this.props.onCloseClick}
          >
            <div className="sidebar__top__close__icon"></div>
          </div>
        </div>
        <div className="sidebar__content__container">
          <div
            className="sidebar__content__container__custom_text"
            dangerouslySetInnerHTML={{ __html: supportContent }}
          />
          <hr />
          <table className="sidebar__content__container__support_info">
            <tbody>
              {this.appOrBrowserName()}
              {this.appOrBrowserVersion()}
              {this.readerInfo()}
              {this.lastUpdate()}
            </tbody>
          </table>
        </div>
      </div>
    );
    return support;
  }

  appOrBrowserName() {
    if (this.props.environment.type === 'app') {
      let label = I18n.t(
        'sidebar.pages.support.support_information.labels.app_name'
      );
      return (
        <tr>
          <td>{label}</td>
          <td>{this.props.environment.appName()}</td>
        </tr>
      );
    } else {
      let label = I18n.t(
        'sidebar.pages.support.support_information.labels.browser_name'
      );
      return (
        <tr>
          <td>{label}</td>
          <td>{this.props.environment.browserName()}</td>
        </tr>
      );
    }
  }

  appOrBrowserVersion() {
    if (this.props.environment.type === 'app') {
      let label = I18n.t(
        'sidebar.pages.support.support_information.labels.app_version'
      );
      return (
        <tr>
          <td>{label}</td>
          <td>{this.props.environment.appVersion()}</td>
        </tr>
      );
    } else {
      let label = I18n.t(
        'sidebar.pages.support.support_information.labels.browser_version'
      );
      return (
        <tr>
          <td>{label}</td>
          <td>{this.props.environment.browserVersion()}</td>
        </tr>
      );
    }
  }

  readerInfo() {
    let label = I18n.t(
      'sidebar.pages.support.support_information.labels.reader_info'
    );
    let name = this.readerName();
    let id = this.readerID();
    return (
      <tr>
        <td>{label}</td>
        <td>
          {name} (ID: {id})
        </td>
      </tr>
    );
  }

  lastUpdate() {
    let label = I18n.t(
      'sidebar.pages.support.support_information.labels.last_update'
    );
    return (
      <tr>
        <td>{label}</td>
        <td>{this.props.lastUpdate}</td>
      </tr>
    );
  }

  readerName(): string {
    return this.props.readerName;
  }

  readerID(): string {
    return this.props.readerID;
  }
}
