import * as React from 'react';
export interface OfflineNotificationProps {
  closeCallback: () => void;
}
export default class OfflineNotification extends React.Component<
  OfflineNotificationProps,
  {}
> {
  render() {
    let f = () => {
      this.restoreClicked();
    };
    let label = I18n.t('notifications.no_internet_connection');
    return (
      <div className="notification__offline">
        <div className="notification__message">{label}</div>
        <div className="notification__offline__button" onClick={f}></div>
      </div>
    );
  }

  restoreClicked() {
    this.props.closeCallback();
  }
}
