import { IIndexedDatabase } from './IIndexedDatabase';
import { Settings } from './Settings';
import { Config as ConfigResponse } from './api/response/Config';
import { ArchiveDate as ArchiveDateResponse } from './api/response/ArchiveDate';
import { ArchiveDateSummary as ArchiveDateSummaryResponse } from './api/response/ArchiveDateSummary';
import { ReaderCategory as ReaderCategoryResponse } from './api/response/ReaderCategory';
import { ReaderResults as ReaderResultsResponse } from './api/response/ReaderResults';
import { ArchiveDateContents as ArchiveDateContentsResponse } from './api/response/ArchiveDateContents';

/**
 * Dummy implementation of IndexedDB for platforms that do not support
 * IndexedDB, meaning it fails by default
 */
export default class DummyDB implements IIndexedDatabase {
  saveNotificationSettings(
    token: string,
    settings: Settings
  ): JQueryPromise<ConfigResponse> {
    return this.rejectedPromise<ConfigResponse>();
  }

  loadConfigurations(token: string): JQueryPromise<ConfigResponse> {
    return this.rejectedPromise<ConfigResponse>();
  }

  loadConfigurationAndArchiveDates(
    token: string
  ): JQueryPromise<ConfigResponse> {
    return this.rejectedPromise<ConfigResponse>();
  }

  loadArchiveDates(configId: number): JQueryPromise<ArchiveDateResponse[]> {
    return this.rejectedPromise<ArchiveDateResponse[]>();
  }

  lastArchiveDateUpdate(configId: number): JQueryPromise<number> {
    var archiveDatesUpdatedAt = window.app.dummyStorage.getItem(
      'archiveDatesUpdatedAt'
    );
    return $.when(+archiveDatesUpdatedAt);
  }

  lastReaderResultUpdate(
    configId: number,
    archiveDateId: number,
    categoryId: number
  ): JQueryPromise<number> {
    let key =
      configId +
      '-' +
      archiveDateId +
      '-' +
      categoryId +
      '-' +
      'resultsUpdatedAt';
    var lastUpdatedAt = window.app.dummyStorage.getItem(key);
    return $.when(+lastUpdatedAt);
  }

  loadArchiveDateSummary(
    configId: number,
    archiveDate: number
  ): JQueryPromise<ArchiveDateSummaryResponse> {
    return this.rejectedPromise<ArchiveDateSummaryResponse>();
  }

  loadCategories(
    configId: number,
    archiveDateId: number
  ): JQueryPromise<ReaderCategoryResponse[]> {
    return this.rejectedPromise<ReaderCategoryResponse[]>();
  }

  loadReaderResults(
    configId: number,
    archiveDateId: number,
    categoryId: number
  ): JQueryPromise<ReaderResultsResponse> {
    return this.rejectedPromise<ReaderResultsResponse>();
  }

  loadArchiveDateContents(
    configId: number,
    archiveDateId: number
  ): JQueryPromise<ArchiveDateContentsResponse> {
    return this.rejectedPromise<ArchiveDateContentsResponse>();
  }

  updateConfigurations(
    config: ConfigResponse,
    token: string
  ): JQueryPromise<void> {
    return this.resolvedPromise();
  }

  updateCategories(
    archiveDates: ReaderCategoryResponse[],
    configId: number
  ): JQueryPromise<void> {
    return this.resolvedPromise();
  }

  updateArchiveDates(
    archiveDates: ArchiveDateResponse[],
    configId: number
  ): JQueryPromise<void> {
    window.app.dummyStorage.setItem(
      'archiveDatesUpdatedAt',
      moment().valueOf().toString()
    );
    return this.resolvedPromise();
  }

  updateArchiveDateSummary(
    summary: ArchiveDateSummaryResponse,
    configId: number,
    archiveDateId: number
  ): JQueryPromise<void> {
    return this.resolvedPromise();
  }

  updateReaderResults(
    results: ReaderResultsResponse,
    configId: number,
    archiveDateId: number,
    categoryId: number
  ): JQueryPromise<void> {
    window.app.dummyStorage.setItem(
      configId +
        '-' +
        archiveDateId +
        '-' +
        categoryId +
        '-' +
        'resultsUpdatedAt',
      moment().valueOf().toString()
    );
    return this.resolvedPromise();
  }

  updateArchiveDateContents(
    contents: ArchiveDateContentsResponse,
    configId: number,
    archiveDateId: number
  ): JQueryPromise<void> {
    return this.resolvedPromise();
  }

  invalidateConfiguration(token: string | null): void {}

  private resolvedPromise() {
    let deferred = $.Deferred<void>();
    deferred.resolve();
    return deferred.promise();
  }

  private rejectedPromise<T>() {
    let deferred = $.Deferred<T>();
    deferred.reject();
    return deferred.promise();
  }
}
