import * as React from 'react';
import Utils from '../Utils';
import * as _ from 'underscore';

export interface SearchNotificationProps {
  expireTime: number;
  moreAvailable: boolean;
  displayNotification: boolean;
  noResults: boolean;
}

export default class SearchNotification extends React.Component<
  SearchNotificationProps,
  {}
> {
  private reRenderTimeoutId?: number;

  render() {
    return <div>{this.notification()}</div>;
  }

  notification() {
    let message = this.parseNotificationMessage();

    if (Utils.isNotBlank(message) && this.props.displayNotification) {
      window.app.dataStore.searchNotificationDisplayed();
      if (this.reRenderTimeoutId !== undefined) {
        window.clearTimeout(this.reRenderTimeoutId);
      }
      this.reRenderTimeoutId = window.setTimeout(() => {
        $('.layout__header__search__notification')
          .fadeOut()
          .promise()
          .done(() => {
            window.app.rerender();
          });
      }, this.expireTime());
      return (
        <div className="layout__header__search__notification">
          <p id="not-all-search-results-shown">{message}</p>
        </div>
      );
    }
  }

  private expireTime(): number {
    const defaultExpireTime = 2500;
    return this.props.expireTime || defaultExpireTime;
  }

  private parseNotificationMessage(): string {
    const moreAvailable = this.props.moreAvailable;
    const noResults = this.props.noResults;

    if (moreAvailable) {
      return I18n.t('notifications.more_search_results_available');
    } else if (noResults) {
      return I18n.t('notifications.no_search_results_available');
    } else {
      return '';
    }
  }
}
