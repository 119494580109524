import ReaderResult from '../ReaderResult';
import SummaryResult from '../SummaryResult';
import Config from '../Config';
import { ClipAttachment } from '../api/response/ClipAttachment';
import MentionHighlighting from '../MentionHighlighting';
import * as _ from 'underscore';
import * as H from '../RichTextMentionHighlighting';
import Utils from '../Utils';
import Source from '../Source';
import { SummarySource } from '../SummaryResult';
import VimeoClient from '../VimeoClient';
import TimeFormatter from '../TimeFormatter';
import * as React from 'react';

export interface DetailComponentProps {
  readerResult: ReaderResult | SummaryResult;
  config: Config;
  key: number;
  isLastItem: boolean;
  onShowFullscreenImage?: (url: string) => void;
}

interface State {
  currentGalleryImage: string | null;
}

export default class DetailComponent extends React.Component<
  DetailComponentProps,
  State
> {
  state = {
    currentGalleryImage: null,
  };

  render() {
    return (
      <div className={this.detailClassName()} {...this.detailAttrs()}>
        {this.topics()}
        {this.primarySourceInfo()}
        {this.primarySourceUrlInfo()}
        {this.detailInfos()}
        <div className="detail__content-wrapper">
          {this.embeddedVideo()}
          {this.textElement()}
        </div>
        {this.downloadButton()}
        {this.detailSources()}
      </div>
    );
  }

  _highlightSearchInput(text: string): string {
    if (window.app.dataStore.isSearchActive()) {
      let searchQuery = window.app.dataStore.searchQuery;
      return text.replace(
        new RegExp('(' + searchQuery + ')'),
        "<span class='query-highlight'>$1</span>"
      );
    } else {
      return text;
    }
  }

  _highlightSearchWords(text: string): string {
    if (window.app.dataStore.isSearchActive()) {
      let searchQuery = window.app.dataStore.searchQuery;
      let searchWords = searchQuery.split(' ');
      _.each(searchWords, (word: string) => {
        text = text.replace(
          new RegExp('(' + word + ')'),
          "<span class='query-highlight'>$1</span>"
        );
      });
    }
    return text;
  }

  _isHighlighted(text: string | undefined): boolean {
    if (text) {
      if (window.app.dataStore.isSearchActive()) {
        let searchQuery = window.app.dataStore.searchQuery;
        if (new RegExp(searchQuery).test(text)) {
          return true;
        }

        let searchWords = searchQuery.split(' ');
        return !!_.find(searchWords, (word: string) => {
          return new RegExp(word).test(text);
        });
      } else {
        return /detail__highlight/.test(text);
      }
    } else {
      return false;
    }
  }

  images(): ClipAttachment[] {
    if (this.clipAttachmentImages().length > 0) {
      return this.clipAttachmentImages();
    }

    const primarySource = this.primarySource();
    if (primarySource.imageUrl) {
      return [{ url: primarySource.imageUrl, file_type: 'image' }];
    }

    return [];
  }

  componentDidMount() {
    if (this.images().length > 0) {
      const highlight = MentionHighlighting.highlightMentionsFn(
        this.props.config
      );

      const data = this.images().map((obj) => {
        let text = '';
        let caption = '';

        if (obj.caption) {
          caption = highlight(obj.caption);
          caption = this._highlightSearchInput(caption);
          caption = this._highlightSearchWords(caption);
          text = caption.replace(/<\/?[^>]+(>|$)/g, '');
        }

        return {
          caption: caption,
          title: text,
          href: obj.url,
          thumbnail: obj.url,
        };
      });

      let self = this;
      const firstHighlightedCaption = _.find(data, function (d) {
        return self._isHighlighted(d.caption);
      });
      let startIndex = 0;
      if (firstHighlightedCaption) {
        startIndex = _.indexOf(data, firstHighlightedCaption);
      }

      let options = {
        container: '#' + this.blueimpGalleryId(),
        carousel: true,
        index: startIndex,
        startSlideshow: false,
        enableKeyboardNavigation: false,
        nextClass: 'next-' + this.blueimpGalleryId(),
        prevClass: 'prev-' + this.blueimpGalleryId(),
        onslide: (index: number, slide: any) => {
          this.setState({ currentGalleryImage: slide.childNodes[0].src });
        },
      };
      blueimp.Gallery(data, options);
    }
  }

  detailAttrs() {
    let result = this.props.readerResult;
    let primarySource = this.primarySource();

    if (
      result != undefined &&
      result.result != undefined &&
      primarySource.kind != undefined
    ) {
      return {
        'data-unique-result-id': result.uniqueId(),
        'data-category-id': result.category_id,
        'data-reader-result-id': result.id,
        'data-result-id': result.result.id,
        'data-clip-id': result.clipId(),
        'data-kind': primarySource.kind,
        'data-reader-archive-date-id': result.archiveDateId,
      };
    }
  }

  detailClassName() {
    var out = 'detail visible enable-ios-scrolling';

    if (this.props.isLastItem) {
      out += ' last-item';
    }

    return out;
  }

  topics() {
    return (
      <div className="detail__topics">
        <a className="detail__topic">{this.result().category_name}</a>
      </div>
    );
  }

  result(): ReaderResult | SummaryResult {
    return this.props.readerResult;
  }

  primarySourceTitle() {
    if (
      this.props.readerResult != undefined &&
      this.props.readerResult.result != undefined
    ) {
      let primarySourceTitle = this.props.readerResult.primarySourceTitle();
      let additionalSourceCount =
        this.props.readerResult.result.additionalSourceCount();

      let labelClosed = I18n.t('detail_view.additional_sources_show', {
        count: additionalSourceCount,
      });
      let labelOpen = I18n.t('detail_view.additional_sources_show_less');
      if (this.displaySourceCount()) {
        return (
          <div>
            <span className="detail__primarysourcetitle__label">
              {primarySourceTitle}
            </span>
            <div
              className="detail__additionalSourceCount"
              onClick={this.handleAdditionalSourceCountClick.bind(this)}
            >
              <div className="detail__additionalSourceCount__labelClosed">
                {labelClosed}
              </div>
              <div className="detail__additionalSourceCount__labelOpen">
                {labelOpen}
              </div>
            </div>
          </div>
        );
      } else {
        return <div>{this.props.readerResult.primarySourceTitle()}</div>;
      }
    }
  }

  handleAdditionalSourceCountClick(el: any) {
    let target = $(el.target).parent();
    let names = target
      .parents('.detail__sourceInfo')
      .find('.detail__additionalSourceNames')[0];
    let $names = $(names);

    if ($names.hasClass('is-open')) {
      // close
      target.removeClass('is-open');
      $names.removeClass('is-open');
    } else {
      // open
      target.addClass('is-open');
      $names.addClass('is-open');
    }
    $names.slideToggle({
      duration: 'slow',
      step: function () {
        if ($(this).is(':visible')) {
          $(this).css('display', 'block');
        }
      },
      complete: function () {
        if ($(this).is(':visible')) {
          $(this).css('display', 'block');
        }
      },
    });
  }

  primarySourceInfo() {
    return (
      <div className="detail__sourceInfo">
        <div className="detail__primarysourcetitle">
          {this.primarySourceTitle()}
        </div>
        {this.additionalSourceNames()}
      </div>
    );
  }

  primarySourceUrlInfo() {
    if (
      this.props.readerResult != undefined &&
      this.props.readerResult.result != undefined &&
      this.props.readerResult.result.primarySource != undefined
    ) {
      let primarySource = this.props.readerResult.result.primarySource;
      let url = primarySource.url;
      let title = this.props.readerResult.title();

      if (this.props.readerResult.isYoutubeContentRemoved()) {
        title = I18n.t('detail_view.youtube_content_removed_hint');
      }

      if (primarySource.url) {
        return (
          <div>
            <a href={url} data-open-href className="detail__title">
              {title}
            </a>
          </div>
        );
      } else if (Utils.isNotBlank(primarySource.pdf)) {
        let handleClick = (ev: React.MouseEvent<HTMLElement>) => {
          this.logClipAccess($(ev.target));
        };
        return (
          <div>
            <a
              className="detail__title"
              href={primarySource.pdf}
              data-download-pdf={this.props.readerResult.clipId()}
              onClick={handleClick}
            >
              {title}
            </a>
          </div>
        );
      } else {
        return (
          <div>
            <h1 className="detail__title">{title}</h1>
          </div>
        );
      }
    }
  }

  embeddedVideo() {
    if (this.isYoutubeClip()) {
      return this.youtubeClip();
    } else if (this.isVimeoClip()) {
      return this.vimeoClip();
    } else if (this.isInstagramVideo()) {
      return this.instagramVideo();
    } else if (this.images().length > 0) {
      return this.blueImpGallery();
    }
  }

  clipAttachmentImages(): ClipAttachment[] {
    if (
      this.props.readerResult != undefined &&
      this.props.readerResult.result != undefined
    ) {
      return this.props.readerResult.result
        .clipAttachments()
        .filter((at) => at.file_type == 'image');
    } else {
      return [];
    }
  }

  blueImpGallery() {
    const additionalClass =
      this.clipAttachmentImages().length > 1 ? '' : 'single';
    const classes =
      'blueimp-gallery blueimp-gallery-carousel blueimp-gallery-controls ' +
      additionalClass;
    const nextClass = 'next next-' + this.blueimpGalleryId();
    const prevClass = 'prev prev-' + this.blueimpGalleryId();

    const { onShowFullscreenImage } = this.props;
    const { currentGalleryImage } = this.state;

    return (
      <div id={this.blueimpGalleryId()} className={classes}>
        <div
          className="slides"
          onClick={() => {
            onShowFullscreenImage &&
              currentGalleryImage &&
              onShowFullscreenImage(currentGalleryImage || '');
          }}
        ></div>
        <div className="slides-background"></div>
        <span className="counter"></span>
        <span className="caption"></span>

        <a className={prevClass}>
          <i className="prevIcon fa fa-caret-left"></i>
        </a>
        <a className={nextClass}>
          <i className="nextIcon fa fa-caret-right"></i>
        </a>
      </div>
    );
  }

  blueimpGalleryId() {
    return (
      this.props.readerResult.uniqueId().toString() +
      '-' +
      this.props.readerResult.category_id
    );
  }

  displaySourceCount() {
    // TODO: we can remove all those silly checks if we stop using ReaderResult for
    // SearchResults which may not have those properties
    if (
      this.props.readerResult != undefined &&
      this.props.readerResult.result != undefined
    ) {
      return this.props.readerResult.result.additionalSourceCount();
    }
  }

  isInstagramVideo() {
    if (
      this.props.readerResult != undefined &&
      this.props.readerResult.result != undefined
    ) {
      return this.props.readerResult.result.isInstagramVideo();
    }
  }

  isVimeoClip() {
    if (
      this.props.readerResult != undefined &&
      this.props.readerResult.result != undefined
    ) {
      return this.props.readerResult.result.isVimeoClip();
    }
  }

  isYoutubeClip() {
    if (
      this.props.readerResult != undefined &&
      this.props.readerResult.result != undefined
    ) {
      return this.props.readerResult.result.isYoutubeClip();
    }
  }

  isIE11() {
    return !!window.MSInputMethodContext && !!document.documentMode;
  }

  textElement() {
    const limit = 50000;
    const ieLimit = 30000;

    if (this.props.readerResult.isYoutubeContentRemoved()) {
      return (
        <div className="hyphenate">
          <div className="detail__snipplet">
            {I18n.t('detail_view.youtube_content_removed_hint')}
          </div>
        </div>
      );
    }

    if (
      this.props.readerResult != undefined &&
      this.props.readerResult.result != undefined &&
      this.props.readerResult.result.text != undefined
    ) {
      let text =
        this.props.readerResult.result.richText ||
        this.props.readerResult.result.text;
      let highlight = this.props.readerResult.result.richText
        ? H.highlightMentionsFn(this.props.config)
        : MentionHighlighting.highlightMentionsFn(this.props.config);
      let highlighted = highlight(text);
      let truncated = highlighted;
      if (this.isIE11()) {
        const source = this.primarySource();
        if (source.url != undefined && Utils.isNotBlank(source.url)) {
          truncated = this.truncate(highlighted, ieLimit);
        }
      } else {
        truncated = this.truncate(highlighted, limit);
      }
      if (Utils.isNotBlank(text)) {
        return (
          <div className="hyphenate">
            <div
              className="detail__snipplet"
              dangerouslySetInnerHTML={{ __html: truncated }}
            ></div>
          </div>
        );
      }
    }
  }

  removeOrCloseOpenTags(text: string): string {
    let doc = document.createElement('div');
    doc.innerHTML = text;
    return doc.innerHTML;
  }

  truncate(text: string, maxLength: number): string {
    let len = text.length;
    if (text.length > maxLength) {
      const truncated = text.slice(0, maxLength) + '…';
      return this.removeOrCloseOpenTags(truncated);
    } else {
      return text;
    }
  }

  additionalSourceNames() {
    if (
      this.props.readerResult != undefined &&
      this.props.readerResult.result != undefined
    ) {
      let names = this.props.readerResult.result.additionalSourceNames();

      if (this.displaySourceCount()) {
        return (
          <div
            className="detail__additionalSourceNames"
            dangerouslySetInnerHTML={{ __html: names }}
          ></div>
        );
      }
    }
  }

  downloadButton() {
    let primarySource = this.primarySource();
    let readerResult = this.props.readerResult;
    let downloadLabel = I18n.t('detail_view.pdf_download');
    let handleClick = (ev: React.MouseEvent<HTMLElement>) => {
      this.logClipAccess($(ev.target));
    };

    if (primarySource.pdf != undefined && Utils.isNotBlank(primarySource.pdf)) {
      return (
        <a
          className="detail__pdf"
          href={primarySource.pdf}
          data-download-pdf={readerResult.clipId()}
          onClick={handleClick}
        >
          {downloadLabel}
          <div className="readon__arrow">
            <svg viewBox="0 0 16 30">
              <path
                className="arrow"
                d="M2.36,29.19L0.92,27.81,13.23,15,0.92,2.19,2.36,0.81,15.18,14.14a1,1,0,0,1,.46.84,1,1,0,0,1-.39.81Z"
              />
            </svg>
          </div>
        </a>
      );
    } else if (
      primarySource.url != undefined &&
      Utils.isNotBlank(primarySource.url)
    ) {
      if (!primarySource.videoId) {
        let linkLabel = I18n.t('detail_view.link') as string;
        return (
          <a
            className="detail__readon"
            href={primarySource.url}
            data-open-href
            onClick={handleClick}
          >
            {linkLabel}
            <div className="readon__arrow">
              <svg viewBox="0 0 16 30">
                <path
                  className="arrow"
                  d="M2.36,29.19L0.92,27.81,13.23,15,0.92,2.19,2.36,0.81,15.18,14.14a1,1,0,0,1,.46.84,1,1,0,0,1-.39.81Z"
                />
              </svg>
            </div>
          </a>
        );
      }
    }
  }

  detailSources() {
    if (
      this.props.readerResult != undefined &&
      this.props.readerResult.result != undefined &&
      this.props.readerResult.result.sources != undefined
    ) {
      let list = this.props.readerResult.result.sources.map(
        (source: Source | SummarySource) => {
          return this.detailSource(source);
        }
      );

      return <ol className="detail__sources">{list}</ol>;
    }
  }

  detailSource(source: Source | SummarySource) {
    var attrs: any = {};

    if (source.url != undefined && Utils.isNotBlank(source.url)) {
      attrs['href'] = source.url;
    }

    let key = this.props.readerResult.id + source.title;

    return (
      <li className="detail__source" key={key}>
        <a className="detail__sourcetitle" {...attrs}>
          {source.title}
        </a>
        <span className="detail__sourceinfo">{source.info}</span>
      </li>
    );
  }

  youtubeClip() {
    if (!this.videoId()) {
      return;
    }

    let src = 'https://www.youtube.com/embed/' + this.videoId();
    let props: any = {
      src: src,
      frameBorder: '0',
      allowFullScreen: '',
      webkitallowfullscreen: '',
      mozallowfullscreen: '',
    };
    return (
      <div className="detail__video">
        <iframe {...props}></iframe>
      </div>
    );
  }

  vimeoClip() {
    const uniqueId = this.props.readerResult.uniqueId();
    const vimeoId = this.videoId();
    const uniqueVideoId = `${uniqueId}-${vimeoId}`;
    const vimeoPlayerId = `${uniqueId}-vimeo-${vimeoId}`;
    const vimeoContainerId = `${uniqueId}-vimeo-container-${vimeoId}`;
    const container = `div#${vimeoContainerId} div#${vimeoPlayerId}`;
    const imageId = `play-${uniqueVideoId}`;
    const imageClickHandler = () => {
      let el = $(`img#${imageId}`);
      el.hide();
      this.logClipAccess(el);
      new Vimeo.Player(vimeoPlayerId, { autoplay: true });
    };

    if (vimeoId != undefined) {
      this.loadVimeoPlayScreen().done((imageUrl: string) => {
        const imageIsNotInDom = $(`img#${imageId}`).length === 0;
        if (imageIsNotInDom) {
          const stillImage = document.createElement('img');
          stillImage.setAttribute('id', imageId);
          stillImage.setAttribute('src', imageUrl);
          stillImage.setAttribute('class', 'detail__video__preview__image');
          stillImage.setAttribute('alt', '');
          stillImage.onclick = imageClickHandler;
          $(container).append(stillImage);
        }
      });
    }

    return (
      <div id={vimeoContainerId}>
        <div
          className="detail__video"
          data-vimeo-id={vimeoId}
          id={vimeoPlayerId}
        ></div>
      </div>
    );
  }

  private loadVimeoPlayScreen(): JQueryPromise<string> {
    const thumbUrl = this.primarySource().thumbnailUrl;

    if (_.isEmpty(thumbUrl)) {
      return VimeoClient.loadVimeoPlayScreen(String(this.videoId()));
    } else {
      const vimeoPlayScreen = String(thumbUrl);
      const deferred = $.Deferred<string>();
      setTimeout(() => {
        deferred.resolve(vimeoPlayScreen);
      }, 100);
      return deferred.promise();
    }
  }

  private logClipAccess(el: JQuery): void {
    let parent = $(el).parents('.detail');
    let selectedClipId = +parent.data('clip-id');
    let agentResultId = +parent.data('result-id');
    let resultKind = String(parent.data('kind'));

    window.app.logClipAccessRequest(
      selectedClipId,
      agentResultId,
      resultKind,
      'click'
    );
  }

  instagramVideo() {
    let url = this.primarySource().url;
    let imageUrl = this.primarySource().imageUrl;
    return (
      <div className="detail__image">
        <a href={url} className="detail__video__link" target="_blank">
          <img src={imageUrl} />
        </a>
      </div>
    );
  }

  isInstagramImage() {
    if (
      this.props.readerResult != undefined &&
      this.props.readerResult.result != undefined
    ) {
      return this.props.readerResult.result.isInstagramImage();
    }
  }

  videoId() {
    if (this.primarySource().videoId != undefined) {
      return this.primarySource().videoId;
    }
  }

  primarySource(): Source | SummarySource {
    if (
      this.props.readerResult != undefined &&
      this.props.readerResult.result != undefined &&
      this.props.readerResult.result.primarySource != undefined
    ) {
      return this.props.readerResult.result.primarySource;
    } else {
      throw new Error('no primary source present');
    }
  }

  detailInfos() {
    let primarySource = this.primarySource();
    let title = primarySource.title;
    let publishTime = this.publishTime();

    if (this.props.readerResult.isYoutubeContentRemoved()) {
      title = I18n.t('detail_view.youtube_content_removed_hint');
    }

    return (
      <ul className="detail__infos">
        <li className="detail__info">
          <span className="detail__infosource">
            {title}
            {I18n.t('detail_view.published_at')}
          </span>
          <time className="detail__infotime" dateTime={publishTime}>
            {publishTime}
          </time>
        </li>
        {this.mediaKind()}
        {this.readingTime()}
        {this.pageNumber()}
        {this.author()}
        {this.printEdition()}
        {this.reach()}
      </ul>
    );
  }

  readingTime() {
    let result = this.props.readerResult.result;
    if (result != undefined && result.readingTimePresent()) {
      return (
        <li className="detail__info detail__info__readingtime">
          {' '}
          {this.readingTimeShort()}{' '}
        </li>
      );
    }
  }

  readingTimeShort() {
    let result = this.props.readerResult.result;
    if (result != undefined) {
      if (result.readingTimeShort()) {
        return I18n.t('detail_view.reading_time_short');
      } else {
        return I18n.t('detail_view.reading_time', {
          reading_time: result.readingTime,
          count: result.readingTime,
        });
      }
    }
  }

  publishTime() {
    let result = this.props.readerResult;
    return TimeFormatter.relativeTime(result, true);
  }

  mediaKind() {
    let { mediaKind, isRtv, isPrintSource, kind } = this.primarySource();

    let value;
    if (isPrintSource) {
      value = 'Print';
    } else if (isRtv) {
      value = mediaKind === 'Radio' ? 'Radio' : 'TV';
    } else if (kind.match(/^SMT/)) {
      value = 'Social media';
    } else if (
      kind === 'TextClip' ||
      (kind === 'ImportedClip' && !isPrintSource && !isRtv)
    ) {
      value = 'Online';
    }

    if (value) {
      return (
        <li className="detail__info">
          {I18n.t('detail_view.media_kind')}: {value}
        </li>
      );
    }
  }

  pageNumber() {
    let primarySource = this.primarySource();

    if (Utils.isPresent(primarySource.pageNumber)) {
      let text = I18n.t('detail_view.page_number', {
        page_number: primarySource.pageNumber,
      });
      return <li className="detail__info">{text}</li>;
    }
  }

  author() {
    let result = this.props.readerResult;
    if (!this.isSummaryResult(result)) {
      let author = result.result.author;
      let kind = result.result.kind;

      if (
        (kind == 'ImportedClip' || kind == 'DataLake::Clip') &&
        Utils.isPresent(author)
      ) {
        let label = I18n.t('detail_view.author');
        return (
          <li className="detail__info">
            {label}: {author}
          </li>
        );
      }
    }
  }

  reach() {
    const { reachCount, circulationDistributed, isPrintSource, isRtv, kind } =
      this.primarySource();

    if (reachCount) {
      let i18nKey;

      if (isPrintSource) {
        i18nKey = 'detail_view.reach';
      } else if (kind === 'RtvClip') {
        i18nKey = 'detail_view.reach_per_day';
      } else if (
        // online
        kind === 'TextClip' ||
        (kind === 'ImportedClip' && !isPrintSource && !isRtv)
      ) {
        i18nKey = 'detail_view.visits_per_month';
      }

      if (i18nKey) {
        return (
          <li className="detail__info">
            {I18n.translate(i18nKey)}
            {`: `}
            {I18n.toNumber(reachCount, { precision: 0 })}
          </li>
        );
      }
    } else if (circulationDistributed) {
      return (
        <li className="detail__info">
          {I18n.translate('detail_view.circulation')}
          {`: `}
          {I18n.toNumber(circulationDistributed, { precision: 0 })}
        </li>
      );
    }
  }

  printEdition() {
    let result = this.props.readerResult;
    if (!this.isSummaryResult(result)) {
      let edition = result.result.print_edition;
      let kind = result.result.kind;

      if (kind == 'ImportedClip' && Utils.isPresent(edition)) {
        let label = I18n.t('detail_view.print_edition');
        return (
          <li className="detail__info">
            {label}: {edition}
          </li>
        );
      }
    }
  }

  isSummaryResult(
    result: ReaderResult | SummaryResult
  ): result is SummaryResult {
    return result instanceof SummaryResult;
  }
}
