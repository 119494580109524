import App from './App';
import Utils from './Utils';

/**
 * Keeps and restores the scrolling position in both navigation and detail
 * divs.
 *
 * When new results are pushed to the reader, this class handles keeps
 * the previously selected result properly on screen.
 *
 * See ScrollingHandler if you want functionality about marking a result
 * as selected while scrolling the result list.
 */
export default class Scroller {
  private resultId?: number;

  saveScrollPosition() {
    this.setResultId();
  }
  setResultId() {
    var element = $('.navigation__item.is-selected');
    if (element.length > 0) {
      this.resultId = +element.data('result-id');
    }
  }
  restoreScrollPosition(openDetailView: boolean) {
    let selectedNavItem = this.selectedNavigationItem();
    if (Utils.jqueryIsPresent(selectedNavItem)) {
      window.scrollingHandler.handleNavigationItemClick(
        selectedNavItem,
        openDetailView
      );
    }
  }
  selectedNavigationItem() {
    return $('.navigation__grouplist').find(this.pattern());
  }
  pattern(): string {
    return '[data-result-id = ' + this.resultId + ']';
  }

  static scrollToResult(
    resultId: number,
    archiveDateId: number,
    app: App,
    openDetailView: boolean
  ) {
    let scroller = new Scroller();
    $('.layout').addClass('is-opened');
    app.openAllCategories(archiveDateId);
    app.navigation.selectByReaderResultId(resultId);
    scroller.setResultId();
    scroller.restoreScrollPosition(openDetailView);
  }

  /**
   * Push notification can only be pushed to latest archive date.
   */
  scrollToReaderResult(resultId: number, archiveDateId: number, app: App) {
    if (window.app.dataStore.getCurrentArchiveDate().id === archiveDateId) {
      Scroller.scrollToResult(resultId, archiveDateId, app, true);
    } else {
      app.switchToLatestArchiveDateAndScrollToResult({
        result_id: resultId,
        archive_date_id: archiveDateId,
      });
    }
  }
}
