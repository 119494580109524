import * as _ from 'underscore';

export default class FontsLoadedChecker {
  private deferred: JQueryDeferred<void>;

  constructor() {
    this.deferred = $.Deferred<void>();
    this.callCheckFonts();
  }

  promise(): JQueryPromise<void> {
    return this.deferred.promise();
  }

  private callCheckFonts() {
    window.setTimeout(() => {
      this.checkFonts(["'Open Sans'", "'Noto Serif'"]);
    }, 500);
  }

  private checkFonts(fontNames: Array<string>) {
    const html =
      '<div id="fonttest">' +
      _.map(fontNames, this.elementHtml) +
      this.elementHtml('defaultFont', -1) +
      '</div>';
    $('body').prepend(html);
    const values = _.map(fontNames, this.collectValues);
    const defaultValues = this.collectValues('', -1);
    if (_.all(values, this.compareWithDefault(defaultValues))) {
      this.deferred.resolve();
    } else {
      this.callCheckFonts();
    }
    $('#fonttest').remove();
  }

  private elementHtml(name: string, index: number) {
    return `<span id = "fonttest-${index}" style = "
font-family: ${name};
font-size: 200px;
visibility: hidden;
">a</span>`;
  }

  private collectValues(_name: string, index: number): Array<number> {
    const element = $(`#fonttest-${index}`);
    return [element.height(), element.width()];
  }

  private compareWithDefault(defaultValues: Array<number>) {
    return (values: Array<number>): boolean => {
      return (
        Math.abs(values[0] - defaultValues[0]) > 1 ||
        Math.abs(values[1] - defaultValues[1]) > 1
      );
    };
  }
}
