import { createElement } from 'react';
import _ from 'underscore';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import Pusher, { Channel, Options } from 'pusher-js/with-encryption';
import App from './App';
import RequestUri from './api/RequestUri';
import Environment from './Environment';
import Scroller from './Scroller';

// see https://gitlab.com/cognita/bluereport/-/blob/master/rails_app/app/interactors/push_archive_date_summary_change_events.rb
// https://gitlab.com/cognita/bluereport/-/blob/master/rails_app/app/interactors/push_archive_date_result_change_events.rb
// https://gitlab.com/cognita/bluereport/-/blob/master/rails_app/app/interactors/push_archive_date_publishing_event.rb
interface PusherDataArchiveDate {
  archive_date_id: number;
  configuration_id: number;
}

// see https://gitlab.com/cognita/bluereport/-/blob/master/rails_app/app/interactors/push_reader_result_changed_event.rb
interface PusherDataReaderResult {
  archive_date_id: number;
  configuration_id: number;
  reader_result_id: number;
  agent_result_id: number;
  reader_result_updated_at: string;
  agent_result_updated_at: string;
  archive_date_updated_at: string;
  newsroom_event: string;
}

// see https://gitlab.com/cognita/bluereport/-/blob/master/rails_app/app/interactors/push_notification_settings_changed_event.rb
interface PusherDataSettings {
  push_notifications_enabled: boolean;
  email_notifications_enabled: boolean;
  configuration_id: number;
}

export default class MessagePusher {
  private pusher: Pusher;
  private channelName: string;
  private channel: Channel;
  private configId: number;
  private app: App;

  constructor(token: string, config_id: number, app: App) {
    let opts: Options = {
      authEndpoint: new RequestUri().build('reader_pusher'),
      cluster: 'eu',
      auth: {
        params: { reader_token: token },
      },
    };
    this.app = app;
    this.configId = config_id;
    this.channelName = `private-configuration-${this.configId}`;
    let key = this.getKey();
    this.pusher = new Pusher(key, opts);

    this.channel = this.pusher.subscribe(this.channelName);

    const onResultsChanged = _.throttle(
      (data: PusherDataArchiveDate) => {
        console.log('archive-date-results-changed');
        this.onArchiveDateResultsChanged(data);
      },
      30000,
      { leading: false }
    );
    this.channel.bind('archive-date-results-changed', onResultsChanged);
    this.channel.bind('archive-date-summary-changed', onResultsChanged);

    this.channel.bind(
      'push_notification_settings_changed',
      _.throttle(
        (data: PusherDataSettings) => {
          console.log('push_notification_settings_changed');
          window.app.dataStore.onNotificationSettingsChanged();
        },
        1000,
        { leading: false }
      )
    );

    this.channel.bind(
      'archive-date-published',
      _.throttle(
        (data: PusherDataArchiveDate) => {
          console.log('archive-date-published');
          this.onArchiveDatePublished(data);
        },
        30000,
        { leading: false }
      )
    );

    this.channel.bind(
      'reader_result_changed',
      (data: PusherDataReaderResult) => {
        console.log('reader_result_changed', data);
        this.onReaderResultChanged(data);
      }
    );
  }

  startListening() {
    this.pusher.connect();
  }

  stopListening() {
    this.channel.unbind_all();
    this.pusher.disconnect();
  }

  onArchiveDateResultsChanged(data: any) {
    this.app.refreshCurrentArchiveDateResults(data.archive_date_id);
  }

  onArchiveDatePublished(data: any) {
    this.app.fetchAndReRenderArchiveDatesWithConditionalSelect();
  }

  onReaderResultChanged(data: PusherDataReaderResult) {
    const { archive_date_id, agent_result_id } = data;
    this.app.refreshCurrentArchiveDateResults(archive_date_id);
    enqueueSnackbar(I18n.t('notifications.reader_result_changed'), {
      variant: 'info',
      persist: true,
      action: (notistackId) => [
        createElement('button', {
          key: 'show',
          children: I18n.t('notifications.show'),
          onClick: () => {
            Scroller.scrollToResult(
              agent_result_id,
              archive_date_id,
              this.app,
              true
            );
            closeSnackbar(notistackId);
          },
        }),
        createElement('button', {
          key: 'dismiss',
          children: ' ',
          className: 'dismiss',
          onClick: () => {
            console.log(`ID: ${notistackId}`);
            closeSnackbar(notistackId);
          },
        }),
      ],
    });
  }

  private getKey(): string {
    if (Environment.isProduction()) {
      return '631a961f0c14f87ddd7f';
    } else if (Environment.isReleasePreview()) {
      return 'adcb12d295837719d44f';
    } else {
      return '77695734ed48212d1c22';
    }
  }
}
