import * as React from 'react';
export default class AppOutdatedView extends React.Component<{}, {}> {
  render() {
    let locale: string;
    if (I18n.locale == 'de') {
      locale = 'de';
    } else if (I18n.locale == 'fr') {
      locale = 'fr';
    } else {
      locale = 'en';
    }

    let googleUrl = `/images/appstore-badges/google/${locale}.svg`;
    return (
      <div className="appstore-badge appstore-badge--google">
        <a
          className="appstore-badge-link"
          aria-label={I18n.t('navigation.downloadPlayStore')}
          href="https://play.google.com/store/apps/details?id=ch.cognita.reader.bluereport"
        >
          <img className="appstore-badge-image" src={googleUrl} />
        </a>
      </div>
    );
  }
}
