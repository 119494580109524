import * as _ from 'underscore';

export interface VimeoVideoOptions {
  type: string;
  version: string;
  provider_name: string;
  provider_url: string;
  title: string;
  author_name: string;
  author_url: string;
  is_plus: string;
  html: string;
  width: number;
  height: number;
  duration: number;
  description: string;
  thumbnail_url: string;
  thumbnail_width: number;
  thumbnail_height: number;
  thumbnail_url_with_play_button: string;
  upload_date: string;
  video_id: number;
  uri: string;
}

export interface VimeoMetadataCache {
  [vimeoId: string]: VimeoVideoOptions;
}

export class VimeoMetadataCacheDepot {
  static vimeoMetadataCache: VimeoMetadataCache;

  static init() {
    VimeoMetadataCacheDepot.vimeoMetadataCache = {};
  }

  static store(vimeoId: string, options: VimeoVideoOptions) {
    VimeoMetadataCacheDepot.vimeoMetadataCache[vimeoId] = options;
  }

  static retrieve(vimeoId: string): VimeoVideoOptions {
    return VimeoMetadataCacheDepot.vimeoMetadataCache[vimeoId];
  }
}

export default class VimeoClient {
  static loadVimeoPlayScreen(vimeoId: string): JQueryPromise<string> {
    const deferred = $.Deferred<string>();
    const metadata = VimeoMetadataCacheDepot.retrieve(vimeoId);

    if (_.isUndefined(metadata)) {
      $.get('https://vimeo.com/api/oembed.json', {
        url: 'https://vimeo.com/' + vimeoId,
      })
        .done((videoOptions: VimeoVideoOptions) => {
          VimeoMetadataCacheDepot.store(vimeoId, videoOptions);
          deferred.resolve(videoOptions.thumbnail_url_with_play_button);
        })
        .fail(() => {
          deferred.reject('');
        });
    } else {
      setTimeout(() => {
        deferred.resolve(metadata.thumbnail_url_with_play_button);
      }, 100);
    }

    return deferred.promise();
  }
}
