import Config from './Config';

let MentionHighlighting = {
  // Highlights mentions in the given text
  highlightMentions(text: string): string {
    return MentionHighlighting.processMentions(text, 'detail__highlight');
  },

  // Remove <b> tags from the given text, do not hightlight mentions
  supressMentions(text: string): string {
    return MentionHighlighting.processMentions(text);
  },

  // Wraps mentions in result text in <span> tags and optionally
  // assigns the given class name to span
  processMentions(text: string, klass?: string): string {
    var span = klass ? '<span class="' + klass + '">' : '<span>';
    var highlightedText = text
      .replace(/\&lt\;b\&gt\;/g, span)
      .replace(/\&lt\;\/b\&gt\;/g, '</span>');
    var paragraphedHightlightedText = highlightedText.replace(
      /\r?\n\r?\n/g,
      '</p><p>'
    );
    return '<p>' + paragraphedHightlightedText + '</p>';
  },

  // Returns a function that does the highlightng
  highlightMentionsFn(config: Config): (text: string) => string {
    return config.search_term_highlighting
      ? MentionHighlighting.highlightMentions
      : MentionHighlighting.supressMentions;
  },
};

export default MentionHighlighting;
