import { Browser } from '@capacitor/browser';
import Environment from './Environment';
import App from './App';
import Utils from './Utils';

export default class LinkOpener {
  constructor(private app: App) {}

  start() {
    const preventDefaultOnExternalLinks = (e: JQueryEventObject) => {
      e.preventDefault();
      this.handleClick(e);
      return false;
    };

    $(document).ready(() => {
      $('body').on('click', '[data-open-href]', preventDefaultOnExternalLinks);
      $('body').on('tap', '[data-open-href]', preventDefaultOnExternalLinks);
    });
  }

  private handleClick(event: JQueryEventObject) {
    if (!event.currentTarget) return;
    var target: JQuery = $(event.currentTarget);
    Utils.logClipAccess(target);
    var href: string = target.attr('href');
    this.open(href);
  }

  open(url: string) {
    if (Environment.isNativeApp()) {
      Browser.open({ url });
    } else {
      window.open(url);
    }
  }
}
