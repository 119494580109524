import Config from './Config';
import ArchiveDateSummary from './ArchiveDateSummary';
import ArchiveDate from './ArchiveDate';
import ReaderCategory from './ReaderCategory';
import CategoryWithResultCount from './CategoryWithResultCount';
import UrlParamParser from './UrlParamParser';
import { ResultsByCategoryId } from './ResultsByCategoryId';
import AccessibleReader from './AccessibleReader';
import ReaderResults from './ReaderResults';
import ReaderResult from './ReaderResult';
import { EnvironmentInformation } from './EnvironmentInformation';
import Sidebar from './Sidebar';
import { AppState } from './AppState';
import { AppInformation } from './EnvironmentInformation';
import { BrowserInformation } from './EnvironmentInformation';
import QueryHighlighter from './QueryHighlighter';
import ApiClient from './ApiClient';
import { ClientContext } from './ApiClient';
import DataLoader from './DataLoader';
import { Settings } from './Settings';
import ConfigAndArchiveDates from './ConfigAndArchiveDates';
import ArchiveDateContents from './ArchiveDateContents';
import Environment from './Environment';
import * as _ from 'underscore';

export default class DataStore {
  // TODO: wrap all those in AppState, use immutable.js
  private configPromise?: JQueryPromise<Config>; // TODO: non-nullable?
  public summary?: ArchiveDateSummary;
  public archiveDates: ArchiveDate[];
  public categories: ReaderCategory[];
  public categoriesWithResultCounts: CategoryWithResultCount[];
  public urlParamParser: UrlParamParser;
  public results: ResultsByCategoryId;
  public accessibleReaders: AccessibleReader[];
  private accessibleReadersPromise?: JQueryPromise<AccessibleReader[]>;
  public selectedArchiveDate: ArchiveDate;
  public _currentCategoryTitle: string;
  private archiveDateContentsPromise: JQueryDeferred<void>;

  // Search
  public isSearchOpen: boolean;
  public isSearchInputEnabled: boolean;
  public searchResults?: ReaderResults;
  public searchQuery: string;
  private showSearchNotification: boolean;

  // nav
  public isNavigationLoading: boolean;

  // Settings
  private emailNotificationsEnabled: boolean;
  private pushNotificationsEnabled: boolean;
  private settingsChangedRemote: boolean;
  private initialApplicationLoad: boolean;

  private environment: EnvironmentInformation;
  private dataLastUpdatedAt: moment.Moment | undefined;

  // those should ideally be modules with functions of type
  // AppState -> AppState
  public sidebar: Sidebar;
  public lastAppState: AppState;

  constructor() {
    this.results = {};
    this.accessibleReaders = [];
    this.isSearchOpen = false;
    this.isSearchInputEnabled = true;
    this.isNavigationLoading = false;
    this.settingsChangedRemote = false;
    this.initialApplicationLoad = true;
    this.sidebar = new Sidebar(this);
    this.setEnvironmentInformation();
    this.showSearchNotification = false;
    this.archiveDateContentsPromise = $.Deferred<void>();
  }

  /*
    Inform the app state that search notification was displayed.
  */
  public searchNotificationDisplayed() {
    this.showSearchNotification = false;
  }

  /*
    Should the app display search notification?
  */
  public displaySearchNotification(): boolean {
    return this.showSearchNotification;
  }

  private setEnvironmentInformation() {
    if (Environment.isNativeApp()) {
      this.environment = new AppInformation();
    } else {
      this.environment = new BrowserInformation();
    }
  }

  public hideOfflineNotification() {
    window.canShowOfflineNotification = false;
    this.rerender();
  }

  public showCategoryTitle(): boolean {
    return !this.isSearchActive();
  }

  public openSearch() {
    this.isSearchOpen = true;
    this.rerender();
  }

  public closeSearch() {
    this.isSearchOpen = false;
    this.searchResults = undefined;
    this.searchQuery = '';
    this.rerender().done(() => {
      QueryHighlighter.unHighlightLastQuery();
      setTimeout(() => {
        window.scrollingHandler.onContentChanged();
        window.scrollingHandler.updateVisibleItemPositionCache();
      });
    });
  }

  public runSearch(query: string) {
    if (Environment.isNativeApp() && window.isMobileOffline) {
      window.canShowOfflineNotification = true;
      this.rerender();
      return;
    }

    this.isSearchInputEnabled = false;
    this.isNavigationLoading = true;
    this.searchQuery = query;
    this.rerender();
    let self = this;
    ApiClient.create(ClientContext.Search)
      .doSearch(query)
      .done((results: ReaderResults) => {
        self.isSearchInputEnabled = true;
        self.isNavigationLoading = false;
        self.searchResults = results;
        self.showSearchNotification = true;
        self.rerender().done(() => {
          window.scrollingHandler.onContentChanged();
          window.scrollingHandler.updateVisibleItemPositionCache();
        });
        QueryHighlighter.highlightSearchQuery(self.searchQuery);
      });
  }

  public isSearchActive() {
    return this.searchResults != undefined;
  }

  public setCurrentCategoryTitle(name: string) {
    this._currentCategoryTitle = name;
    this.rerender();
  }

  public setCurrentSearchQuery(query: string) {
    this.searchQuery = query;
    this.rerender();
  }

  public headerLabel() {
    if (this.isSearchActive()) {
      return this.searchQuery;
    } else {
      return this._currentCategoryTitle;
    }
  }

  public openSidebar() {
    if (this.isSearchOpen) {
      this.closeSearch();
    }
    this.sidebar.open();
    this.rerender();
  }

  public closeSidebar() {
    this.sidebar.close();
  }

  public rerender() {
    return window.app.rerender();
  }

  public getState() {
    const dfd = $.Deferred();
    $.when(
      window.app.dataStore.getResults(),
      window.app.dataStore.getArchiveDates(),
      window.app.dataStore.getCategories(),
      window.app.dataStore.getSummary(),
      window.app.dataStore.getConfig(),
      window.app.dataStore.getAccessibleReaders()
    ).done(
      (
        results: any,
        archiveDates: ArchiveDate[],
        categories: ReaderCategory[],
        summary: ArchiveDateSummary,
        config: Config,
        accessibleReaders: AccessibleReader[]
      ) => {
        let appState: AppState = {
          searchResults: this.searchResults,
          results: results,
          archiveDates: archiveDates,
          categories: categories,
          summary: summary,
          config: config,

          isSidebarOpen: this.sidebar.isOpen(),
          isSidebarLoading: this.sidebar.isLoading(),

          isSearchOpen: this.isSearchOpen,
          showCategoryTitle: this.showCategoryTitle(),

          isNavigationLoading: this.isNavigationLoading,
          currentArchiveDate: this.selectedArchiveDate,
          currentCategoryTitle: this.headerLabel(),

          pushNotificationsEnabled: this.pushNotificationsEnabled,
          emailNotificationsEnabled: this.emailNotificationsEnabled,

          environment: this.environment,
          dataLastUpdatedAt: this.dataLastUpdatedAt,

          accessibleReaders: accessibleReaders,
        };

        this.lastAppState = appState;

        return dfd.resolve(appState);
      }
    );
    return dfd.promise();
  }

  public findArchiveDateById(archiveDateId: number): ArchiveDate | undefined {
    return _.findWhere(this.archiveDates, { id: Number(archiveDateId) });
  }

  private dataClient(): DataLoader {
    return new DataLoader();
  }

  public invalidateConfiguration(token: string | null): void {
    this.dataClient().invalidateConfiguration(token);
  }

  public saveNotificationSettings(
    settings: Settings
  ): JQueryPromise<XMLHttpRequest> {
    this.configPromise = undefined;
    this.pushNotificationsEnabled = settings.pushNotificationsEnabled;
    this.emailNotificationsEnabled = settings.emailNotificationsEnabled;
    if (Environment.isMobileOffline()) {
      window.app.offlineSettings.store(settings);
    }
    return this.dataClient().saveNotificationSettings(settings);
  }

  public onNotificationSettingsChanged(): void {
    this.configPromise = undefined;
    this.settingsChangedRemote = true;
    this.rerender();
  }

  public setCurrentArchiveDate(archiveDates: ArchiveDate[]) {
    this.urlParamParser = new UrlParamParser();
    const urlParams = this.urlParamParser.deparam(window.location.search);

    if (_.isUndefined(urlParams.archiveDateId)) {
      const currentArchiveDate = _.first(this.archiveDates);
      if (currentArchiveDate === undefined) {
        throw 'No archive dates exist';
      }
      this.selectedArchiveDate = currentArchiveDate;
    } else {
      const archiveDateId = Number(urlParams.archiveDateId);
      const currentArchiveDate = _.findWhere(archiveDates, {
        id: archiveDateId,
      });
      if (currentArchiveDate === undefined) {
        throw 'No archive dates exist';
      }
      this.selectedArchiveDate = currentArchiveDate;
    }
  }

  public setSelectedArchiveDate(archiveDate: ArchiveDate) {
    this.selectedArchiveDate = archiveDate;
  }

  public getConfig(): JQueryPromise<Config> {
    if (typeof this.configPromise === 'undefined') {
      const deferred = $.Deferred<Config>();
      const changeSettings =
        this.initialApplicationLoad || this.settingsChangedRemote;
      this.dataClient()
        .loadConfigurationAndArchiveDates()
        .done((data: ConfigAndArchiveDates) => {
          const configuration = data.configuration;
          if (changeSettings) {
            this.emailNotificationsEnabled =
              data.configuration.emailNotificationsEnabled();
            this.pushNotificationsEnabled =
              data.configuration.pushNotificationsEnabled();
          }
          this.setConfiguration(configuration);
          this.setArchiveDates(data.archiveDates);
          if (this.settingsChangedRemote) {
            this.settingsChangedRemote = false;
          }
          if (this.initialApplicationLoad) {
            this.initialApplicationLoad = false;
          }
          deferred.resolve(configuration);
        });
      this.configPromise = deferred.promise();
    }
    return this.configPromise;
  }

  public getSummary(): JQueryPromise<ArchiveDateSummary> {
    return this.archiveDateContentsPromise.then(() => {
      if (this.summary) {
        return this.summary;
      } else {
        throw 'summary not loaded';
      }
    });
  }

  public getArchiveDates(): JQueryPromise<ArchiveDate[]> {
    return $.Deferred<ArchiveDate[]>().resolve(this.archiveDates);
  }

  public getCategories(): JQueryPromise<ReaderCategory[]> {
    return this.archiveDateContentsPromise.then(() => {
      return this.categories;
    });
  }

  // TODO: fix this type
  public getResults(): JQueryPromise<any> {
    return this.archiveDateContentsPromise.then(() => {
      return this.results;
    });
  }

  public getCurrentArchiveDate(): ArchiveDate {
    if (!this.hasCurrentArchiveDate()) {
      throw new Error('this.selectedArchiveDate is undefined');
    } else {
      return this.selectedArchiveDate;
    }
  }

  public getAccessibleReaders(): JQueryPromise<AccessibleReader[]> {
    if (this.accessibleReadersPromise === undefined) {
      this.accessibleReadersPromise = this.dataClient().loadAccessibleReaders();
    }
    return this.accessibleReadersPromise;
  }

  // TODO: Setters are only temporary here, to do this incrementally
  public setResults(category_id: number, results: ReaderResults) {
    this.results[category_id] = results;
  }

  // TODO: Setters are only temporary here, to do this incrementally
  public setCategories(categories: ReaderCategory[]) {
    this.categories = categories;
  }

  // TODO: Setters are only temporary here, to do this incrementally
  public setArchiveDates(archiveDates: ArchiveDate[]) {
    this.archiveDates = archiveDates;
  }

  // TODO: Setters are only temporary here, to do this incrementally
  public setSummary(summary: ArchiveDateSummary) {
    this.summary = summary;
  }

  // TODO: Setters are only temporary here, to do this incrementally
  public setAccessibleReaders(readers: AccessibleReader[]) {
    this.accessibleReaders = readers;
  }

  private setConfiguration(configuration: Config) {
    window.app.config = configuration;
  }

  public isArchiveDateInDb(archiveDate: ArchiveDate): JQueryPromise<boolean> {
    let self = this;
    return this.getConfig().then((config: Config) => {
      return self.dataClient().isArchiveDateInDb(config, archiveDate);
    });
  }

  // TODO: We can get rid of ReaderResultLoader class now.
  public switchArchiveDate(archiveDate: ArchiveDate): JQueryPromise<void> {
    const deferred = $.Deferred<void>();
    this.configPromise = undefined;

    $.when(this.switchToResultsInArchiveDate(archiveDate))
      .done(() => {
        deferred.resolve();
      })
      .fail(() => {
        deferred.reject();
      });

    return deferred.promise();
  }

  public switchToResultsInArchiveDate(
    archiveDate: ArchiveDate
  ): JQueryPromise<void> {
    const deferred = $.Deferred<void>();
    const currentArchiveDate = this.selectedArchiveDate;
    const currentCategories = this.categories;
    this.setCategories(new Array<ReaderCategory>());
    this.selectedArchiveDate = archiveDate;

    this.refreshArchiveDateContents()
      .done(() => {
        $.when(this.getCategories(), this.getResults()).done(
          (categories, results) => {
            _.each(categories, (category: ReaderCategory) => {
              const resultsSet = results[category.id];
              _.each(resultsSet.collection, (r: ReaderResult) => {
                r.category_name = category.name;
                r.category_id = category.id;
              });
            });
          }
        );
        deferred.resolve();
      })
      .fail(() => {
        this.selectedArchiveDate = currentArchiveDate;
        this.categories = currentCategories;
        deferred.reject();
      });

    return deferred.promise();
  }

  private readerResultLimit(): number {
    return 200;
  }

  public refreshArchiveDateSummary(): JQueryPromise<void> {
    const deferred = $.Deferred<void>();

    $.when(this.getConfig()).done((config) => {
      let archiveDate = this.getCurrentArchiveDate();
      this.dataClient()
        .loadArchiveDateSummary(config, archiveDate)
        .done((summary) => {
          this.summary = summary;
          deferred.resolve();
        });
    });

    return deferred.promise();
  }

  public refreshArchiveDates(): JQueryPromise<void> {
    const deferred = $.Deferred<void>();

    $.when(this.getConfig()).done((config) => {
      this.dataClient()
        .loadArchiveDates(config)
        .done((archiveDates: ArchiveDate[]) => {
          this.archiveDates = archiveDates;
          deferred.resolve();
        });
    });

    return deferred.promise();
  }

  // to do: make getConfig() just return the stored
  // value without calling the data loader
  public refreshConfigAndArchiveDates(): JQueryPromise<void> {
    const deferred = $.Deferred<void>();
    this.getConfig().done(() => {
      deferred.resolve();
    });
    return deferred.promise();
  }

  public refreshArchiveDateContents(): JQueryPromise<void> {
    const deferred = $.Deferred<void>();
    this.getConfig().done((config: Config) => {
      const archiveDate = this.getCurrentArchiveDate();
      this.dataClient()
        .loadArchiveDateContents(config, archiveDate, (time) => {
          this.dataLastUpdatedAt = time;
        })
        .done((contents: ArchiveDateContents) => {
          const categories = contents.categories;
          this.setCategories(contents.categories);
          this.setSummary(contents.summary);
          _.each(categories, (category) => {
            const results: ReaderResults = category.readerResults;
            this.setResults(category.id, results);
          });
          deferred.resolve();
          this.archiveDateContentsPromise.resolve();
        })
        .fail(() => {
          deferred.reject();
        });
    });
    return deferred;
  }

  public refreshAccessulbeReaders(): JQueryPromise<AccessibleReader[]> {
    return this.dataClient()
      .loadAccessibleReaders()
      .then((readers) => {
        this.accessibleReaders = readers;
        return readers;
      });
  }

  public hasCurrentArchiveDate(): boolean {
    return this.selectedArchiveDate !== undefined;
  }

  reRenderArchiveDatesWithConditionalSelect(archiveDates: ArchiveDate[]) {
    const latestArchiveDateBeforeUpdate = _.first(this.archiveDates);
    if (latestArchiveDateBeforeUpdate === undefined) {
      throw 'latestArchiveDateBeforeUpdate is undefined';
    }
    const userSelectedArchiveDate =
      window.app.dataStore.getCurrentArchiveDate();
    const userWasViewingLatestArchiveDate =
      latestArchiveDateBeforeUpdate.id == userSelectedArchiveDate.id;
    const firstArchiveDate = _.first(archiveDates);
    if (firstArchiveDate === undefined) {
      throw 'no archive dates';
    }
    const newerArchiveDatePresent =
      firstArchiveDate.id !== userSelectedArchiveDate.id;
    const switchIsPossible =
      userWasViewingLatestArchiveDate && newerArchiveDatePresent;
    const switchShouldBeDone = Environment.isNativeApp();

    if (switchIsPossible && switchShouldBeDone) {
      this.switchToLatestArchiveDate();
    }
  }

  private switchToLatestArchiveDate() {
    const archiveDate = _.first(this.archiveDates);
    if (archiveDate === undefined) {
      throw 'no archive dates';
    }

    this.handleArchiveDateSelect(archiveDate.id);
  }

  handleArchiveDateSelect(archiveDateId: number): JQueryPromise<void> {
    var selectedDate = _.find(this.archiveDates, (archiveDate) => {
      return Number(archiveDate.id) === archiveDateId;
    });

    if (selectedDate === undefined) {
      throw 'archive date not found';
    }

    return this.switchArchiveDate(selectedDate).done(() => {
      window.app.afterAllResultsLoadCallback(
        this.selectedArchiveDate,
        this.categories,
        this.results
      );
    });
  }

  runAfterAllResultsLoadCallback() {
    window.app.afterAllResultsLoadCallback(
      this.selectedArchiveDate,
      this.categories,
      this.results
    );
  }
}
