import * as React from 'react';

export interface VisitWebsiteViewProps {
  userName: string;
}
export default class VisitWebsiteView extends React.Component<
  VisitWebsiteViewProps,
  {}
> {
  render() {
    let niceToMeetYou = I18n.t('login.niceToMeetYou');
    let weAreHappy = I18n.t('login.weAreHappy');

    let learnMore = I18n.t('login.learnMore');

    let buttonLabel = I18n.t('login.visitWebsiteButtonLabel');

    return (
      <div className="loginViewBackground">
        <div className="loginViewWrapper">
          <div className="loginView">
            <div className="loginView__signup__complete">
              <div>
                {niceToMeetYou} <b>{this.props.userName}</b>.
              </div>
              <br />
              <div dangerouslySetInnerHTML={{ __html: weAreHappy }}></div>
            </div>
            <button
              className="loginView__signup__button__visit-website"
              onClick={this.handleClick.bind(this)}
            >
              {buttonLabel}
            </button>
            <div
              className="loginView__footer__learnmore"
              dangerouslySetInnerHTML={{ __html: learnMore }}
            />
          </div>
        </div>
      </div>
    );
  }

  handleClick() {
    window.open('http://www.bluereport.net', '_system');
  }
}
