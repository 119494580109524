import PdfNotAvailableNotificationComponent from '../components/PdfNotAvailableNotification';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

export default class PdfNotAvailableNotification {
  private cnt?: Element | null;
  constructor(private nodeId: string) {
    this.nodeId = nodeId;
  }

  mount() {
    let props = {
      closeCallback: () => {
        this.umount();
      },
    };
    let component = React.createElement(
      PdfNotAvailableNotificationComponent,
      props
    );
    this.cnt = document.getElementById(this.nodeId);
    if (this.cnt != null) {
      ReactDOM.render(component, this.cnt);
    } else {
      throw new Error('cnt is null');
    }
  }

  umount() {
    if (this.cnt != null) {
      ReactDOM.unmountComponentAtNode(this.cnt);
    } else {
      throw new Error('cnt is null');
    }
  }
}
