import ArchiveDateSummary from '../ArchiveDateSummary';
import * as React from 'react';

export interface DetailSummaryProps {
  summary: ArchiveDateSummary;
}

export default class DetailSummary extends React.Component<
  DetailSummaryProps,
  {}
> {
  render() {
    let summary = this.props.summary;
    let title = summary.title;
    let text = summary.text;

    return (
      <div className="navigation__summary">
        <h1 className="navigation__summary-title">{title}</h1>

        {this.summaryText()}
      </div>
    );
  }

  summaryText() {
    if (this.props.summary.text != undefined) {
      return (
        <div
          className="navigation__summary-text"
          dangerouslySetInnerHTML={{ __html: this.props.summary.text }}
        ></div>
      );
    }
  }
}
