import * as React from 'react';
import * as ReactDOM from 'react-dom';
import GoogleAppStoreBadge from './GoogleAppStoreBadge';
import AppleAppStoreBadge from './AppleAppStoreBadge';
import Environment from '../Environment';

export default class AppOutdatedView extends React.Component<{}, {}> {
  render() {
    let learnMore = I18n.t('login.learnMore');
    let learnMoreDiv = (
      <div
        className="loginView__footer__learnmore"
        dangerouslySetInnerHTML={{ __html: learnMore }}
      />
    );

    let updateRequired = I18n.t('error.app_outdated.headline');
    let pleaseUpdate = I18n.t('error.app_outdated.description');
    let badge = Environment.isIosDevice() ? (
      <AppleAppStoreBadge />
    ) : (
      <GoogleAppStoreBadge />
    );

    return (
      <div className="loginViewBackground">
        <div className="loginViewWrapper">
          <div className="loginView">
            <div className="loginView__reader-logo"></div>
            <div className="loginView__subclaim">{updateRequired}</div>
            <p className="loginView__error">{pleaseUpdate}</p>
            {badge}
            {learnMoreDiv}
          </div>
        </div>
      </div>
    );
  }
}
