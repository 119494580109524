import { Config as ConfigResponse } from './api/response/Config';
import BrowserChrome from './BrowserChrome';
import UserGroupBranding from './UserGroupBranding';
import * as _ from 'underscore';

export default class Config {
  constructor(configuration: ConfigResponse) {
    this.title = configuration.title;
    this.hide_empty_categories = configuration.hide_empty_categories;
    this.handout = configuration.handout;
    this.locale = configuration.locale;
    this.analytics = configuration.analytics;
    this.application_version = configuration.application_version;
    this.user_id = configuration.user_id;
    this.client_id = configuration.client_id;
    this.show_publish_time = configuration.show_publish_time;
    this.show_all_articles = configuration.show_all_articles;
    this.user_group_branding = configuration.user_group_branding;
    this.search_available = configuration.search_available;
    this.email_notifications_enabled =
      configuration.email_notifications_enabled;
    this.push_notifications_enabled = configuration.push_notifications_enabled;
    this.search_term_highlighting = configuration.search_term_highlighting;
    this.minimum_version = configuration.minimum_version;
    this.highlight_important_results =
      configuration.highlight_important_results;
    this.id = configuration.id;
    this.browserChrome = new BrowserChrome();
    this.one_time_reader = configuration.one_time_reader;
    this.insights_api_bearer = configuration.insights_api_bearer;
  }

  emailNotificationsEnabled() {
    return this.email_notifications_enabled;
  }

  pushNotificationsEnabled() {
    return this.push_notifications_enabled;
  }

  applyUserGroupBranding() {
    if (this.user_group_branding) {
      this.user_group_branding.apply();
    }
    this.applyBrandingToChrome();
  }

  private applyBrandingToChrome() {
    if (this.user_group_branding) {
      this.user_group_branding.applyBrandingToBrowserChrome();
    } else {
      this.applyDefaultBrandingToChrome();
    }
  }

  private applyDefaultBrandingToChrome() {
    this.browserChrome.setColor('#f4f4f4');
    this.browserChrome.setStatusBarStyle('default');
  }

  onSidebarOpen() {
    this.browserChrome.setColor('#555555');
    this.browserChrome.setStatusBarStyle('light');
  }

  onSidebarClose() {
    this.applyBrandingToChrome();
  }

  useLightColors(): boolean {
    if (this.user_group_branding) {
      return this.user_group_branding.categoryTitleColorInverted();
    } else {
      return false;
    }
  }

  hideEmptyCategories(): boolean {
    return this.hide_empty_categories;
  }

  hasHandout(): boolean {
    return this.handout;
  }

  isMobileVersionOutdated(
    appVersion: string | null // '0.8.7' for example
  ): boolean {
    if (appVersion == null) {
      return false;
    } else {
      const toNum = (s: string) => s.split('.').map((x) => parseInt(x));

      const actual = toNum(appVersion);
      const majorActual = actual[0];
      const minorActual = actual[1];
      const patchActual = actual[2];

      const min = toNum(this.minimum_version);
      const majorMin = min[0];
      const minorMin = min[1];
      const patchMin = min[2];

      return (
        majorActual < majorMin ||
        (majorActual == majorMin && minorActual < minorMin) ||
        (majorActual == majorMin &&
          minorActual == minorMin &&
          patchActual < patchMin)
      );
    }
  }

  public user_group_branding: UserGroupBranding | undefined;
  public locale: string;
  public title: string;
  public user_id: number | null;
  public client_id: number;
  public application_version: string;
  public analytics: boolean;
  private hide_empty_categories: boolean;
  private handout: boolean;
  public highlight_important_results: boolean;
  public id: number;
  public show_publish_time: boolean;
  public show_all_articles: boolean;
  public search_available: boolean;
  private email_notifications_enabled: boolean;
  private push_notifications_enabled: boolean;
  public search_term_highlighting: boolean;
  private browserChrome: BrowserChrome;
  public minimum_version: string;
  public one_time_reader: boolean;
  public insights_api_bearer: string;
}
