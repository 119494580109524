import React, { useCallback, useRef } from 'react';
import QuickPinchZoom, {
  UpdateAction,
  make3dTransformValue,
} from 'react-quick-pinch-zoom';

interface Props {
  url: string;
  onClose: () => void;
}

export default function FullscreenImageView({ url, onClose }: Props) {
  const imgRef = useRef<HTMLImageElement>(null);
  const onUpdate = useCallback(({ x, y, scale }: UpdateAction) => {
    const { current: img } = imgRef;
    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty('transform', value);
    }
  }, []);

  return (
    <div className="fullscreen-image" onClick={() => onClose()}>
      <QuickPinchZoom onUpdate={onUpdate}>
        <img ref={imgRef} src={url} alt="" />
      </QuickPinchZoom>
    </div>
  );
}
