import * as React from 'react';
import Environment from '../Environment';
import * as _ from 'underscore';
import GoogleAppStoreBadge from './GoogleAppStoreBadge';
import AppleAppStoreBadge from './AppleAppStoreBadge';

export interface LoginViewProps {
  error?: string;
  errorPadding?: boolean;
  firstButtonLabel: string;
  firstButtonClicked: (inputValue: string) => void;
  showMessageInsteadOfLogin: boolean;
  messageToShow?: string;
  secondButtonLabel?: string;
  secondButtonClicked?: (inputValue: string) => void;
  inputPrompt?: string;
  inputPlaceholder?: string;
  initialInputValue?: string;
  inputClass?: string;
  inputType?: string;
  screen?: string;
  screenLinkLabel?: string;
  screenLoginAs?: string;
  screenBackLinkLabel?: string;
  screenLinkClicked?: () => void;
  showLocalStorageWarning: boolean;
}

export interface LoginViewState {
  inputValue: string;
  firstButtonEnabled: boolean;
  secondButtonEnabled: boolean;
}

export default class LoginView extends React.Component<
  LoginViewProps,
  LoginViewState
> {
  constructor(props: LoginViewProps, context: any) {
    super(props, context);
    this.state = this.initialState(props);
  }

  initialState(props: LoginViewProps) {
    let initialState: LoginViewState = {
      inputValue: props.initialInputValue || '',
      firstButtonEnabled: true,
      secondButtonEnabled: true,
    };

    return initialState;
  }

  UNSAFE_componentWillReceiveProps(nextProps: LoginViewProps) {
    this.setState(this.initialState(nextProps));
  }

  render() {
    let worldClassMonitoring = I18n.t('login.worldClassMonitoring');
    let learnMore = I18n.t('login.learnMore');
    let learnMoreDiv = (
      <div
        className="loginView__footer__learnmore"
        dangerouslySetInnerHTML={{ __html: learnMore }}
      />
    );
    if (Environment.isIosDevice()) {
      learnMoreDiv = <div />;
    }
    let cssClass = 'loginViewBackground';
    if (Environment.isNativeApp()) {
      cssClass += ' app-bluereport';
    } else {
      cssClass += ' app-web';
    }
    return (
      <div className={cssClass}>
        <div className="loginViewWrapper">
          <div className="loginView">
            <div className="loginView__reader-logo"></div>
            <div
              className="loginView__subclaim"
              dangerouslySetInnerHTML={{ __html: worldClassMonitoring }}
            />
            {this.loginFormOrMessage()}
            {this.localStorageWarning()}
            {this.screenLink()}
            <div className="largeMobile-inner">
              {this.appStoreLinks()}
              {learnMoreDiv}
            </div>
          </div>
        </div>
        <div className="largeMobile-outer">
          {this.appStoreLinks()}
          {learnMoreDiv}
        </div>
      </div>
    );
  }

  loginFormOrMessage() {
    if (this.props.showMessageInsteadOfLogin) {
      return this.message();
    } else {
      return this.loginForm();
    }
  }

  message() {
    if (this.props.messageToShow != undefined) {
      return (
        <div className="loginView__form__message loginView__form__message__error">
          <span
            dangerouslySetInnerHTML={{ __html: this.props.messageToShow }}
          />
        </div>
      );
    }
  }

  loginForm() {
    return (
      <div className="loginView__form">
        <div className="loginView__form__message">{this.error()}</div>
        <div className="loginView__form__label">{this.inputPrompt()}</div>
        <div>{this.input()}</div>
        <div>{this.firstButton()}</div>
        <div>{this.secondButton()}</div>
      </div>
    );
  }

  screenLink() {
    let clickHandler = (ev: React.MouseEvent<HTMLElement>) => {
      if (this.props.screenLinkClicked != undefined) {
        this.props.screenLinkClicked();
      }
    };

    if (this.props.screenLinkLabel) {
      if (this.props.screenLoginAs) {
        return (
          <div>
            <span className="loginView__goto__tokenform">
              {this.props.screenLoginAs}
            </span>
            <br />
            <a
              onClick={clickHandler}
              className="loginView__goto__tokenform"
              dangerouslySetInnerHTML={{ __html: this.props.screenLinkLabel }}
            />
          </div>
        );
      } else {
        return (
          <a
            onClick={clickHandler}
            className="loginView__goto__tokenform"
            dangerouslySetInnerHTML={{ __html: this.props.screenLinkLabel }}
          />
        );
      }
    } else if (this.props.screenBackLinkLabel) {
      return (
        <a
          onClick={clickHandler}
          className="loginView__goto__emailform"
          dangerouslySetInnerHTML={{ __html: this.props.screenBackLinkLabel }}
        />
      );
    }
  }

  appStoreLinks() {
    if (
      this.props.screen == 'emailInput' &&
      !this.props.secondButtonLabel &&
      !Environment.isNativeApp()
    ) {
      return (
        <div className="appstore-badges">
          <AppleAppStoreBadge />
          <GoogleAppStoreBadge />
        </div>
      );
    }
  }

  localStorageWarning() {
    if (this.props.showLocalStorageWarning) {
      return (
        <div className="loginView__warning__private-mode">
          {I18n.t('login.disabledLocalstoreMessage')}
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  inputPrompt() {
    if (this.props.inputPrompt) {
      return <p>{this.props.inputPrompt}</p>;
    }
  }

  firstButton() {
    return (
      <button
        onClick={this.firstButtonClickHandler.bind(this)}
        className={this.firstButtonClass()}
      >
        {this.props.firstButtonLabel}
      </button>
    );
  }

  firstButtonClickHandler() {
    this.setState({
      inputValue: this.state.inputValue,
      firstButtonEnabled: false,
      secondButtonEnabled: true,
    });
    this.props.firstButtonClicked(this.state.inputValue);
  }

  firstButtonClass() {
    let out = 'loginView__form__button-email';
    if (!this.state.firstButtonEnabled) {
      out += ' send';
    }

    return out;
  }

  secondButton() {
    if (this.props.secondButtonLabel) {
      let self = this;
      let clickHandler = (ev: React.MouseEvent<HTMLElement>) => {
        this.setState(
          _.extend(this.state, {
            secondButtonEnabled: false,
          })
        );
        if (self.props.secondButtonClicked != undefined) {
          self.props.secondButtonClicked(self.state.inputValue);
        }
      };
      return (
        <button
          onClick={clickHandler.bind(this)}
          className={this.secondButtonClass()}
        >
          {this.props.secondButtonLabel}
        </button>
      );
    }
  }

  secondButtonClass() {
    let out = 'loginView__form__button-signup';
    if (!this.state.secondButtonEnabled) {
      out += ' send';
    }

    return out;
  }

  error() {
    if (this.props.error) {
      return (
        <div className="loginView__form__message__error">
          {this.props.error}
        </div>
      );
    } else if (this.props.errorPadding) {
      return <div className="loginView__form__message__error">&nbsp;</div>;
    }
  }

  input() {
    return (
      <input
        placeholder={this.props.inputPlaceholder}
        onChange={this.handleInputChange.bind(this)}
        value={this.state.inputValue}
        className={this.inputClass()}
        {...this.inputDisabled()}
        onKeyPress={this.handleKeyPress.bind(this)}
        type={this.props.inputType}
      />
    );
  }

  handleKeyPress(e: any) {
    if (e.key == 'Enter') {
      this.firstButtonClickHandler();
    }
  }

  inputDisabled() {
    if (!this.state.firstButtonEnabled || !this.state.secondButtonEnabled) {
      return { disabled: true };
    }
  }

  inputClass() {
    let out = '';

    if (!this.state.firstButtonEnabled || !this.state.secondButtonEnabled) {
      out += ' send ';
    }

    if (this.props.error) {
      out += ' error ';
    }

    if (this.props.inputClass) {
      out += this.props.inputClass;
    }

    return out;
  }

  handleInputChange(ev: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      inputValue: ev.target.value,
      firstButtonEnabled: true,
      secondButtonEnabled: true,
    });
  }
}
