import Config from './Config';
import Environment from './Environment';
import { UserGroupBranding as UserGroupBrandingResponse } from './api/response/UserGroupBranding';
import * as _ from 'underscore';

export default class OfflineBranding {
  private storageSupported: boolean;

  constructor(private config: Config) {
    this.storageSupported = Environment.isLocalStorageSupported();
  }

  storeBranding(branding: UserGroupBrandingResponse) {
    // TODO: _.mapObject introduced in 1.8.0. Time for underscore upgrade?
    const brandingKeysValues = _.pairs(branding);
    _.each(brandingKeysValues, (keyValArr: Array<string>) => {
      const itemKey = this.itemBrandingKey(keyValArr[0]);
      const value = keyValArr[1];
      if (value !== null) {
        this.storeItem(itemKey, keyValArr[1]);
      }
    });
  }

  storeLogo(content: Blob): JQueryPromise<void> {
    let deferred = $.Deferred<void>();
    const reader = new FileReader();
    reader.onload = (evt) => {
      const eTarget = <FileReader>evt.target;
      const logoBrandingKey = this.itemBrandingKey('logo');
      this.storeItem(logoBrandingKey, eTarget.result as string);
      deferred.resolve();
    };
    reader.readAsDataURL(content);
    return deferred.promise();
  }

  getLogo(): string | null {
    return this.getItemByKey('logo');
  }

  getLogoSize(): number | null {
    let value = this.getItemByKey('reader_logo_size');
    if (value === null) {
      return null;
    }
    return parseInt(value, 10);
  }

  getPrimaryColor(): string | null {
    return this.getItemByKey('primary_color');
  }

  getSecondaryColor(): string | null {
    return this.getItemByKey('secondary_color');
  }

  getLinkColor(): string | null {
    return this.getItemByKey('link_color');
  }

  getInverted(): boolean {
    let result = this.getItemByKey('category_title_color_inverted');
    return result === 'true' ? true : false;
  }

  getSupportPageText(): string | null {
    return this.getItemByKey('support_page_text');
  }

  private itemBrandingKey(item: string): string {
    return `ugBranding-${this.config.client_id}-${item}`;
  }

  private getItemByKey(key: string): string | null {
    let itemBrandingKey = this.itemBrandingKey(key);
    let item: string | null = window.localStorage.getItem(itemBrandingKey);
    // If the string is just `'null'`, it is likely because of a bug
    // where we didn't check for `null` values before writing to
    // localStorage. We should ignore these values.
    if (item === 'null') {
      item = null;
    }
    return item;
  }

  private storeItem(itemKey: string, itemValue: string) {
    Environment.storeToLocalStorage(itemKey, itemValue);
  }
}
