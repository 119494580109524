// global environment information
export type EnvironmentInformation = BrowserInformation | AppInformation;

export class BrowserInformation {
  type: 'browser';

  private uaParser: UAParser;

  constructor() {
    this.type = 'browser';
    this.uaParser = new UAParser();
  }

  browserName(): string {
    return this.uaParser.getBrowser().name;
  }

  browserVersion(): string {
    return this.uaParser.getBrowser().version;
  }
}

export class AppInformation {
  type: 'app';

  private name: string;
  private version: string;

  constructor() {
    this.type = 'app';
    this.name = window.app?.name || 'n/a';
    this.version = window.app?.version || 'n/a';
  }

  appName(): string {
    return this.name;
  }

  appVersion(): string {
    return this.version;
  }
}
