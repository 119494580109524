import * as React from 'react';
import ArchiveDateSummary from '../ArchiveDateSummary';
import Utils from '../Utils';

export interface NavigationSummaryProps {
  summary: ArchiveDateSummary;
}

export default class NavigationSummary extends React.Component<
  NavigationSummaryProps,
  {}
> {
  render() {
    return (
      <div className="navigation__summary">
        {this.title()}
        {this.text()}
      </div>
    );
  }

  title() {
    if (
      Utils.isPresent(this.props.summary) &&
      Utils.isNotBlank(this.props.summary.title)
    ) {
      return (
        <h1 className="navigation__summary-title">
          {this.props.summary.title}
        </h1>
      );
    }
  }

  text() {
    if (
      Utils.isPresent(this.props.summary) &&
      Utils.isNotBlank(this.props.summary.text) &&
      this.props.summary.text != undefined
    ) {
      return (
        <div
          className="navigation__summary-text"
          dangerouslySetInnerHTML={{ __html: this.props.summary.text }}
        ></div>
      );
    }
  }
}
