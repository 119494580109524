import TokenCache from './TokenCache';
import UrlParamParser from './UrlParamParser';
import AccessToken from './AccessToken';
import ActivationToken from './ActivationToken';
import Environment from './Environment';

/** Token extraction logic */
// TODO: fix undefined/null types here
export default class TokenManager {
  private tokenCache: TokenCache;
  private urlParamParser: UrlParamParser;
  private query: string;
  private token?: string;
  private tokenStoringDeferred: JQueryDeferred<AccessToken>;

  constructor() {
    this.tokenCache = new TokenCache();
    this.urlParamParser = new UrlParamParser();
    this.query = window.location.search;
    this.tokenStoringDeferred = $.Deferred<AccessToken>();
  }

  isTokenStorageSupported(): boolean {
    return Environment.isLocalStorageSupported();
  }

  // Needed for times we change the url while the app is running
  clearQuery(): void {
    this.query = '';
  }

  tokenGiven(): boolean {
    return /\?(token|rtoken)/.test(this.query);
  }

  // obtain token from request query or token cache
  // returns null if no token is present
  getToken(): string | null {
    if (this.providedToken() != null) {
      return this.providedToken();
    } else {
      return this.token || this.tokenCache.getToken();
    }
  }

  parseActivationToken(): ActivationToken | undefined {
    if (this.activationTokenGiven()) {
      return new ActivationToken(this.query.split('?activationtoken=')[1]);
    }
  }
  private activationTokenGiven(): boolean {
    return /\activationtoken/.test(this.query);
  }

  store(token: AccessToken) {
    this.token = token.toString();
    if (this.isTokenStorageSupported()) {
      this.tokenCache.store(this.token);
    }
    this.tokenStoringDeferred.resolve(token);
  }

  expireCache() {
    this.tokenCache.removeToken();
  }

  replaceToken(newToken: AccessToken) {
    this.expireCache();
    this.store(newToken);
  }

  loggedInTokenString(): string | null {
    return this.tokenCache.getToken();
  }

  providedToken(): string | null {
    return this.parseToken();
  }

  private parseToken(): string | null {
    if (/[\?&]rtoken/.test(this.query)) {
      var params = this.urlParamParser.deparam(this.query);
      if (params.rtoken != undefined) {
        return params.rtoken;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  public tokenPromise(): JQueryPromise<AccessToken> {
    return this.tokenStoringDeferred.promise();
  }
}
