import TokenManager from './TokenManager';
import Client from './api/Client';
import { IIndexedDatabase } from './IIndexedDatabase';
import { ClientContext } from './ApiClient';
import IndexedDatabaseFactory from './IndexedDBFactory';
import ApiClient from './ApiClient';
import { Settings } from './Settings';
import ConfigAndArchiveDates from './ConfigAndArchiveDates';
import { Config as ConfigResponse } from './api/response/Config';
import ApiResponseParser from './ApiResponseParser';
import AccessibleReader from './AccessibleReader';
import Config from './Config';
import ArchiveDate from './ArchiveDate';
import ArchiveDateContents from './ArchiveDateContents';
import { ArchiveDateContents as ArchiveDateContentsResponse } from './api/response/ArchiveDateContents';
import { ArchiveDate as ArchiveDateResponse } from './api/response/ArchiveDate';
import ArchiveDateSummary from './ArchiveDateSummary';
import { ArchiveDateSummary as ArchiveDateSummaryResponse } from './api/response/ArchiveDateSummary';
import ReaderCategory from './ReaderCategory';
import ReaderResults from './ReaderResults';
import { ReaderResults as ReaderResultsResponse } from './api/response/ReaderResults';
import ReaderResult from './ReaderResult';
import Utils from './Utils';

/*
  An interface for the app to load data, so that app itself does not need
  to care where the data comes from.
*/
export default class DataLoader {
  private tokenManager: TokenManager;
  private client: Client;
  private indexedDB: IIndexedDatabase;
  private initialDataLoadedFromIndexedDB: boolean;

  constructor() {
    this.tokenManager = window.app.tokenManager;
    this.client = ApiClient.create(ClientContext.DataLoader);
    this.indexedDB = IndexedDatabaseFactory.getIndexedDatabase();
    this.initialDataLoadedFromIndexedDB = false;
  }

  offlineDataNeedsUpdate() {
    return this.initialDataLoadedFromIndexedDB;
  }

  // TODO: fix this type/function
  saveNotificationSettings(settings: Settings): JQueryPromise<XMLHttpRequest> {
    let done: any = (request: XMLHttpRequest) => {
      return request;
    };

    let fail: any = (request: XMLHttpRequest) => {
      let token = window.app.tokenManager.getToken();

      if (token != null) {
        return this.indexedDB.saveNotificationSettings(token, settings);
      }
    };
    return this.client.updateSettings(settings).then(done, fail);
  }

  loadConfigurationAndArchiveDates(): JQueryPromise<ConfigAndArchiveDates> {
    return this.client.getConfigAndArchiveDates().then(
      (configAndArchiveDates: ConfigAndArchiveDates) => {
        this.initialDataLoadedFromIndexedDB = false;
        return configAndArchiveDates;
      },
      () => {
        const deferred = $.Deferred<ConfigAndArchiveDates>();
        // TODO: fix those types
        const onSuccess: any = (response: ConfigResponse) => {
          const config = ApiResponseParser.parseConfigAndArchiveDates(response);
          this.initialDataLoadedFromIndexedDB = true;
          deferred.resolve(config);
        };
        const onFailure: any = () => {
          throw 'Loading config failed!';
        };

        let token = this.tokenManager.getToken();
        if (token != null) {
          this.indexedDB
            .loadConfigurationAndArchiveDates(token)
            .then(onSuccess, onFailure)
            .fail(() => {
              this.initialDataLoadedFromIndexedDB = false;
            });
        }

        return deferred.promise();
      }
    );
  }

  loadAccessibleReaders(): JQueryPromise<AccessibleReader[]> {
    return this.client.getAccessibleReaders();
  }

  invalidateConfiguration(token: string | null): void {
    this.indexedDB.invalidateConfiguration(token);
  }

  loadArchiveDateContents(
    config: Config,
    archiveDate: ArchiveDate,
    onRefreshCallback: (updateTime: moment.Moment) => void
  ): JQueryPromise<ArchiveDateContents> {
    return this.client.getArchiveDateContents(config, archiveDate).then(
      (archiveDateContents: ArchiveDateContents) => {
        onRefreshCallback(moment());
        return archiveDateContents;
      },
      () => {
        const deferred = $.Deferred<ArchiveDateContents>();
        const onSuccess = (response: ArchiveDateContentsResponse) => {
          const contents = ApiResponseParser.parseArchiveDateContents(
            response,
            archiveDate.id
          );
          deferred.resolve(contents);
        };
        const onFailure = () => {
          deferred.reject();
        };
        this.indexedDB
          .loadArchiveDateContents(config.id, archiveDate.id)
          .then(onSuccess, onFailure);
        return deferred.promise();
      }
    );
  }

  loadArchiveDates(config: Config): JQueryPromise<ArchiveDate[]> {
    return this.client.getArchiveDates(config).then(
      (archiveDates: ArchiveDate[]) => {
        return archiveDates;
      },
      () => {
        const deferred = $.Deferred<ArchiveDate[]>();
        const onSuccess = (response: ArchiveDateResponse[]) => {
          const archiveDates = ApiResponseParser.parseArchiveDates(
            response,
            config
          );
          deferred.resolve(archiveDates);
        };
        const onFailure = () => {
          const archiveDates = new Array<ArchiveDate>();
          deferred.resolve(archiveDates);
        };

        this.indexedDB.loadArchiveDates(config.id).then(onSuccess, onFailure);

        return deferred.promise();
      }
    );
  }

  isArchiveDateInDb(
    config: Config,
    archiveDate: ArchiveDate
  ): JQueryPromise<boolean> {
    return this.areCategoriesInDb(config, archiveDate);
  }

  areCategoriesInDb(
    config: Config,
    archiveDate: ArchiveDate
  ): JQueryPromise<boolean> {
    let deferred = $.Deferred<boolean>();
    let onSuccess = (response: any) => {
      let isPresent =
        Utils.isPresent(response) &&
        ((Utils.isPresent(response.collection) &&
          response.collection.length > 0) ||
          (Utils.isPresent(response.length) && response.length > 0));
      deferred.resolve(isPresent);
    };
    let onFailure = () => {
      deferred.resolve(false);
    };

    this.indexedDB
      .loadCategories(config.id, archiveDate.id)
      .then(onSuccess, onFailure);

    return deferred.promise();
  }

  lastArchiveDateUpdate(config: Config): JQueryPromise<number> {
    return this.indexedDB.lastArchiveDateUpdate(config.id);
  }

  lastReaderResultUpdate(
    configId: number,
    archiveDateId: number,
    categoryId: number
  ): JQueryPromise<number> {
    return this.indexedDB.lastReaderResultUpdate(
      configId,
      archiveDateId,
      categoryId
    );
  }

  loadArchiveDateSummary(
    config: Config,
    archiveDate: ArchiveDate
  ): JQueryPromise<ArchiveDateSummary> {
    return this.client.getArchiveDateSummary(config, archiveDate).then(
      (archiveDateSummary: ArchiveDateSummary) => {
        return archiveDateSummary;
      },
      () => {
        const deferred = $.Deferred<ArchiveDateSummary>();
        const onSuccess = (response: ArchiveDateSummaryResponse) => {
          const summary = ApiResponseParser.parseArchiveDateSummary(response);
          deferred.resolve(summary);
        };
        const onFailure = () => {
          const summary = new ArchiveDateSummary(
            undefined,
            undefined,
            undefined
          );
          deferred.resolve(summary);
        };

        this.indexedDB
          .loadArchiveDateSummary(config.id, archiveDate.id)
          .then(onSuccess, onFailure);

        return deferred.promise();
      }
    );
  }

  loadResults(
    config: Config,
    archiveDate: ArchiveDate,
    category: ReaderCategory,
    limit: number
  ): JQueryPromise<ReaderResults> {
    return this.client
      .getReaderResults(config, archiveDate, category, limit)
      .then(
        (results: ReaderResults) => {
          return results;
        },
        () => {
          const deferred = $.Deferred<ReaderResults>();
          const onSuccess = (response: ReaderResultsResponse) => {
            const results = ApiResponseParser.parseReaderResults(
              response,
              archiveDate.id
            );
            deferred.resolve(results);
          };
          const onFailure = () => {
            const emptyResults = new Array<ReaderResult>();
            const results = new ReaderResults(emptyResults, 0);
            deferred.resolve(results);
          };

          $.when(
            this.indexedDB.loadReaderResults(
              config.id,
              archiveDate.id,
              category.id
            )
          ).then(onSuccess, onFailure);

          return deferred.promise();
        }
      );
  }
}
