import * as React from 'react';
import { SnackbarProvider } from 'notistack';
import { AppState } from '../AppState';
import { HeaderProps } from './Header';
import { SidebarProps } from './SidebarComponent';
import { NavigationComponentProps } from './NavigationComponent';
import { DetailListProps } from './DetailList';
import Header from './Header';
import SidebarComponent from './SidebarComponent';
import NavigationComponent from './NavigationComponent';
import DetailList from './DetailList';
import Insights from './insights/Insights';
import FullscreenImageView from './FullscreenImageView';
import Spinner from './SpinnerComponent';

export interface LayoutComponentProps {
  state: AppState;
  headerProps: HeaderProps;
  sidebarProps: SidebarProps;
  navigationProps: NavigationComponentProps;
  detailListProps: DetailListProps;
}

interface State {
  fullscreenImageUrl: string | null;
}

export default class LayoutComponent extends React.Component<
  LayoutComponentProps,
  State
> {
  state: State = {
    fullscreenImageUrl: null,
  };

  showFullscreenImage = (url: string) => {
    this.setState({ fullscreenImageUrl: url });
  };

  hideFullscreenImage = () => {
    this.setState({ fullscreenImageUrl: null });
  };

  render() {
    let sidebarBgClass = 'layout__sidebar__background';
    if (this.props.state.isSidebarOpen) {
      sidebarBgClass += ' overlay-visible';
    }

    let detailClass = 'layout__content';
    if (this.props.state.searchResults != undefined) {
      detailClass += ' search-results-details';
    }

    const {
      id: readerId,
      client_id,
      insights_api_bearer,
    } = this.props.detailListProps.config || {};

    return (
      <SnackbarProvider maxSnack={5}>
        <div className="layout">
          {this.state.fullscreenImageUrl && (
            <FullscreenImageView
              url={this.state.fullscreenImageUrl}
              onClose={() => this.hideFullscreenImage()}
            />
          )}
          <div id="header-container">
            <Header {...this.props.headerProps} />
          </div>
          <SidebarComponent {...this.props.sidebarProps} />
          <div
            className={sidebarBgClass}
            onClick={() => window.app.dataStore.sidebar.close()}
          ></div>
          <div className="layout__navigation" id="navigation">
            <NavigationComponent {...this.props.navigationProps} />
          </div>
          <div className={detailClass}>
            {insights_api_bearer && readerId && (
              <Insights
                token={insights_api_bearer}
                readerId={readerId}
                clientId={client_id || 0}
                archiveDateId={this.props.state.currentArchiveDate.id}
              />
            )}
            <div
              data-detail-loader
              className="loading-curtain"
              style={{ display: 'none' }}
            >
              <Spinner style="dark" />
            </div>
            <div data-detail-content-top></div>
            <div data-detail-results id="resultsDetail">
              <DetailList
                {...this.props.detailListProps}
                handleShowFullScreenImage={(url: string) =>
                  this.showFullscreenImage(url)
                }
              />
            </div>
          </div>
        </div>
      </SnackbarProvider>
    );
  }
}
