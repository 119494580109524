import App from './App';
import PrintPdfDownloader from './PrintPdfDownloader';
import Utils from './Utils';

export default class PdfDownloadManager {
  private app: App;
  constructor(app: App) {
    this.app = app;
  }
  start() {
    $(document).ready(() => {
      $('body').on('click', '[data-download-pdf]', (e) => {
        this.handleClick(e);
      });
    });
  }
  handleClick(event: JQueryEventObject) {
    if (!event.currentTarget) return;
    var target: JQuery = $(event.currentTarget);
    Utils.logClipAccess(target);
    var clipId: number = parseInt(target.attr('data-download-pdf'), 10);
    var downloader = new PrintPdfDownloader(clipId, this.app);
    downloader.start();
  }
}
