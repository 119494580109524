import Config from './Config';

// Returns a function that does the highlightng
export function highlightMentionsFn(config: Config): (text: string) => string {
  return config.search_term_highlighting ? highlightMentions : supressMentions;
}

// Highlights mentions in the given text
function highlightMentions(text: string): string {
  return processMentions(text, 'detail__highlight');
}

// Remove <b> tags from the given text, do not hightlight mentions
function supressMentions(text: string): string {
  return processMentions(text);
}

// Wraps mentions in result text in <span> tags and optionally
// assigns the given class name to span
function processMentions(text: string, klass?: string): string {
  var span = klass ? '<span class="' + klass + '">' : '<span>';
  return text
    .replace(/<highlight>/g, span)
    .replace(/<\/highlight>/g, '</span>');
}
