import Environment from './Environment';
import * as _ from 'underscore';

export default class ReaderLocale {
  static customizeLocales() {
    moment.updateLocale('de', {
      weekdaysShort: 'So_Mo_Di_Mi_Do_Fr_Sa'.split('_'),
    });

    moment.updateLocale('fr', {
      weekdaysShort: 'dim_lun_mar_mer_jeu_ven_sam'.split('_'),
    });
  }

  static setLocaleFromBrowser() {
    const availableLanguages = _.keys(I18n.translations);
    const supported = ReaderLocale.browserSupportedLanguages();
    supported.push('en');
    const locale =
      _.first(_.intersection(supported, availableLanguages)) || 'en';

    I18n.fallbacks = true;
    moment.locale(locale);
    I18n.locale = locale;
  }

  static browserSupportedLanguages(): Array<string> {
    const availableLanguages = _.keys(I18n.translations);
    let languages: Array<string> = navigator.languages || [];
    languages = languages.concat(navigator.userLanguage);
    languages = languages.concat(navigator.language);
    languages = _.compact(languages);
    let supportedLanguages: string[] = [];
    let langSuffix = '';

    _.each(languages, (language) => {
      if (ReaderLocale.supportedLanguage(language)) {
        supportedLanguages = supportedLanguages.concat(language);
      } else {
        langSuffix = language.split('-')[0];
        if (ReaderLocale.supportedLanguage(langSuffix)) {
          supportedLanguages = supportedLanguages.concat(langSuffix);
        }
      }
    });

    return supportedLanguages;
  }

  static supportedLanguage(language: string): boolean {
    const availableLanguages = _.keys(I18n.translations);
    return _.contains(availableLanguages, language);
  }

  static setLocale(locale: string) {
    I18n.fallbacks = true;
    moment.locale(locale);
    I18n.locale = locale;
  }
}
