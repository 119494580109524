import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { LayoutComponentProps } from './components/LayoutComponent';
import { AppState } from './AppState';
import { HeaderProps } from './components/Header';
import Environment from './Environment';
import { SidebarProps } from './components/SidebarComponent';
import { NavigationComponentProps } from './components/NavigationComponent';
import { DetailListProps } from './components/DetailList';
import ReaderResult from './ReaderResult';
import SummaryResult from './SummaryResult';
import LayoutComponent from './components/LayoutComponent';
import ReaderCategory from './ReaderCategory';
import CategoryWithResultCount from './CategoryWithResultCount';
import Utils from './Utils';
import * as _ from 'underscore';

export default class ReactLayout {
  layoutComponentProps(): JQueryPromise<LayoutComponentProps> {
    const dfd = $.Deferred<LayoutComponentProps>();
    $.when(window.app.dataStore.getState()).done((state: AppState) => {
      let navigationProps = this.buildNavigationProps(state);
      let detailProps = this.buildDetailProps(state);
      let headerProps: HeaderProps = this.buildHeaderProps(state);
      let sidebarProps = this.buildSidebarProps(state);

      let props: LayoutComponentProps = {
        state: state,
        headerProps: headerProps,
        navigationProps: navigationProps,
        sidebarProps: sidebarProps,
        detailListProps: detailProps,
      };

      return dfd.resolve(props);
    });

    return dfd.promise();
  }

  buildHeaderProps(state: AppState): HeaderProps {
    let showOffline =
      Environment.isNativeApp() &&
      window.isMobileOffline &&
      window.canShowOfflineNotification;

    const props: HeaderProps = {
      state: state,
      categoryTitle: state.currentCategoryTitle,
      showCategoryTitle: state.showCategoryTitle,
      lightColors: state.config.useLightColors(),
      showOfflineNotification: showOffline,
      onOfflineNotificationClose: () => {},
    };

    return props;
  }

  buildSidebarProps(state: AppState): SidebarProps {
    let cats = this.addResultCountsToCategories(
      state.results,
      state.categories
    );
    let sidebarProps: SidebarProps = {
      summary: state.summary,
      archiveDates: state.archiveDates,
      categories: cats,
      config: state.config,
      currentArchiveDate: state.currentArchiveDate,
      isLoading: state.isSidebarLoading,
      isOpen: state.isSidebarOpen,
      pushNotificationsEnabled: state.pushNotificationsEnabled,
      emailNotificationsEnabled: state.emailNotificationsEnabled,
      environment: state.environment,
      dataLastUpdatedAt: state.dataLastUpdatedAt,
      accessibleReaders: state.accessibleReaders,
    };

    return sidebarProps;
  }

  buildNavigationProps(state: AppState): NavigationComponentProps {
    let props: NavigationComponentProps = {
      archiveDates: state.archiveDates,
      summary: state.summary,
      config: state.config,
      isLoading: state.isNavigationLoading,
      newClips: false,
      download: false,
      categories: state.categories,
      readerResults: state.results,
      searchResults: state.searchResults,
    };

    return props;
  }

  buildDetailProps(state: AppState): DetailListProps {
    let searchResults: (ReaderResult | SummaryResult)[] | undefined = undefined;
    if (state.searchResults && state.searchResults.collection) {
      searchResults = state.searchResults.collection;
    }
    let results = searchResults || state.results;
    if (state.isNavigationLoading) {
      results = [];
    }
    let props: DetailListProps = {
      readerResults: results,
      categories: state.categories,
      summary: state.summary,
      config: state.config,
    };

    return props;
  }

  renderLayoutComponent(): JQueryPromise<void> {
    return this.layoutComponentProps().then((props: LayoutComponentProps) => {
      let component = React.createElement(LayoutComponent, props);
      ReactDOM.render(component, this.container());
    });
  }

  private container() {
    let cnt = document.getElementById('container');
    if (cnt != null) {
      return cnt;
    } else {
      throw new Error('#container not present in DOM');
    }
  }

  addResultCountsToCategories(
    results: any,
    categories: ReaderCategory[]
  ): CategoryWithResultCount[] {
    return _.map(categories, (category: ReaderCategory) => {
      var resultCount: number = 0;
      if (
        Utils.isPresent(results[category.id]) &&
        Utils.isPresent(results[category.id].resultCount())
      ) {
        resultCount = +results[category.id].resultCount();
      }

      let catWithCount = new CategoryWithResultCount(category, resultCount);
      return catWithCount;
    });
  }
}
