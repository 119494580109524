import * as React from 'react';
import { Category } from '../Category';
import ReaderResult from '../ReaderResult';
import Config from '../Config';
import Utils from '../Utils';
import NavigationItemEmpty from './NavigationItemEmpty';
import NavigationItem from './NavigationItem';

export interface NavigationGroupProps {
  category: Category;
  readerResults: ReaderResult[];
  config: Config;
}

export default class NavigationGroup extends React.Component<
  NavigationGroupProps,
  {}
> {
  render() {
    let category = this.props.category;
    let name = 'navigation_results_' + category.id;
    let count = this.readerResults.length;
    return (
      <li data-category-id={category.id} className="navigation__group">
        <span className="navigation__groupcount">{count}</span>
        <h2 className="navigation__grouptitle">{category.name}</h2>
        <div id={name}>
          <ul className="navigation__grouplist">{this.readerResults()}</ul>
        </div>
      </li>
    );
  }

  componentDidMount() {
    $('.layout').delegate(
      '.navigation__item',
      'is-selected',
      function (this: JQueryEventObject) {
        var selectedClipId = +$(this).data('clip-id');
        var selectedResultId = +$(this).data('reader-result-id');
        var agentResultId = +$(this).data('result-id');
        var resultKind = String($(this).data('kind'));
        $('.detail.selected-item').removeClass('selected-item');
        var detailElem = $(
          '.detail[data-reader-result-id=' + selectedResultId + ']'
        );
        detailElem.addClass('selected-item');
        if (Utils.isPresent(selectedClipId)) {
          window.app.logClipAccessRequest(
            selectedClipId,
            agentResultId,
            resultKind,
            'view'
          );
        }
      }
    );
  }

  readerResults(): any {
    if (this.props.readerResults.length > 0) {
      return this.props.readerResults.map((readerResult: ReaderResult) => {
        return this.navigationItemFromReaderResult(readerResult);
      });
    } else {
      return <NavigationItemEmpty />;
    }
  }

  navigationItemFromReaderResult(readerResult: ReaderResult) {
    return (
      <NavigationItem
        config={this.props.config}
        key={readerResult.id}
        readerResult={readerResult}
      />
    );
  }
}
