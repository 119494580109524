import { NotificationListener, PushNotificationData } from '../Notifications';
import App from '../../App';
import Scroller from '../../Scroller';

export default class ScrollToResultListener implements NotificationListener {
  constructor(private app: App) {
    this.app = app;
  }

  run({ archive_date_id, result_id, configuration_id }: PushNotificationData) {
    if (
      archive_date_id &&
      result_id &&
      this.app.currentlyActiveConfig().id === Number(configuration_id)
    ) {
      let scroller = new Scroller();
      scroller.scrollToReaderResult(
        Number(result_id),
        Number(archive_date_id),
        this.app
      );
    }
  }
}
