import ReaderCategory from './ReaderCategory';

export default class CategoryWithResultCount {
  constructor(
    public category: ReaderCategory,
    public resultCount: number
  ) {
    this.category = category;
    this.resultCount = resultCount;
  }
}
