import {
  FirebaseAnalytics,
  FirebaseInitOptions,
} from '@capacitor-community/firebase-analytics';
import Config from './Config';
import Environment from './Environment';
import { ArchiveDateSwitchType } from './ArchiveDateSwitchType';

export let firebaseConfig: FirebaseInitOptions;
if (Environment.isProduction()) {
  // https://console.firebase.google.com/project/bluereport-1103/settings/general/web:NTNiODM5ZmEtNTAyMy00YzM5LWE0NmUtZTIxNGIxZWQ2ZDRi
  firebaseConfig = {
    apiKey: 'AIzaSyCWMZJ-P09hs6aliW-jZqBNaJ_ALT2eFOk',
    authDomain: 'bluereport-1103.firebaseapp.com',
    databaseURL: 'https://bluereport-1103.firebaseio.com',
    projectId: 'bluereport-1103',
    storageBucket: 'bluereport-1103.appspot.com',
    messagingSenderId: '66850598694',
    appId: '1:66850598694:web:1404f3c7abb7984deb1be7',
    measurementId: 'G-CDBDVK34RQ',
  };
} else if (Environment.isReleasePreview()) {
  // https://console.firebase.google.com/project/bluereport-staging/settings/general/web:NTU2M2M5ZDEtYTJhYS00ZWQ4LThmNTMtMzU4YWY1NGI5MDE0
  firebaseConfig = {
    apiKey: 'AIzaSyDRx4NxhHPGBuPfQRV3EZtq4sKsKGFA21I',
    authDomain: 'bluereport-staging.firebaseapp.com',
    projectId: 'bluereport-staging',
    storageBucket: 'bluereport-staging.appspot.com',
    messagingSenderId: '790690766825',
    appId: '1:790690766825:web:fd49878e348a7725ee3578',
    measurementId: 'G-8M6WGRZ9T7',
  };
} else {
  // https://console.firebase.google.com/project/bluereport-dev/settings/general/web:NzcyNTI1NTEtOGQzYi00MzMzLWE1ZTItZjZlYzFjZjBjNjYy?nonce=1686657793995
  firebaseConfig = {
    apiKey: 'AIzaSyAjc9KJcjQWRcj57OFVWuR7SUJ2kgQG2lk',
    authDomain: 'bluereport-dev.firebaseapp.com',
    databaseURL: 'https://bluereport-dev.firebaseio.com',
    projectId: 'bluereport-dev',
    storageBucket: 'bluereport-dev.appspot.com',
    messagingSenderId: '444305366204',
    appId: '1:444305366204:web:5e790e3694205f249c44b9',
    measurementId: 'G-F4ZHN0QK0C',
  };
}

let disabled: boolean = false;

function disableReporting() {
  disabled = true;
  FirebaseAnalytics.setCollectionEnabled({ enabled: false });
}

function getPage() {
  if (userBelongsToAudience()) {
    var reader_token = window.app.tokenManager.getToken();
    return `/app/?rtoken=${reader_token}`;
  } else {
    return window.location.pathname + window.location.search;
  }
}

function userBelongsToAudience() {
  return window.location.search.indexOf('rtoken=') === -1;
}

function sendTiming(category: string, variable: string, duration: number) {
  FirebaseAnalytics.logEvent({
    name: 'reader_timing',
    params: { category, variable, duration },
  });
}

const Analytics = {
  setup({ analytics, user_id, client_id, id }: Config) {
    if (analytics === false) {
      disableReporting();
      return;
    }
    FirebaseAnalytics.setUserProperty({
      name: 'reader_client_id',
      value: client_id.toString(),
    });
    FirebaseAnalytics.setUserProperty({
      name: 'reader_id',
      value: id.toString(),
    });
  },

  sendPageview(page?: string, title?: string) {
    if (!page) {
      page = getPage();
    }
    this.sendEvent('reader_page_view', { page, title });
  },

  sendEvent(name: string, params: any = {}) {
    if (!params.reader && window.app.config?.id) {
      params.reader = window.app.config.id.toString();
    }
    console.log('analytics: %s', name, params);
    FirebaseAnalytics.logEvent({ name, params });
  },

  trackArchiveDateSwitch(count: number, type: ArchiveDateSwitchType) {
    const label = window.app.config.id.toString();
    const name =
      type === ArchiveDateSwitchType.Forward
        ? 'reader_archive_date_switch_next'
        : 'reader_archive_date_switch_previous';
    this.sendEvent(name);
  },

  logSearch(query: string) {
    let page = '/reader_search?query=' + query;
    this.sendPageview(page);
  },

  logInitialLoginPage() {
    this.sendPageview('/login', 'Login - E-Mail');
  },

  logTokenPage() {
    this.sendPageview('/login/token', 'Login - Access token');
  },

  logNamePage() {
    this.sendPageview('/login/signup', 'Login - Signup - enter name');
  },
};

export class Timer {
  private startTime: moment.Moment;

  constructor(
    private category: string,
    private variable: string
  ) {
    this.startTime = moment();
  }

  send() {
    var endTime = moment();
    var duration = endTime.unix() - this.startTime.unix();
    sendTiming(this.category, this.variable, duration);
  }
}

export default Analytics;
