import * as React from 'react';
import { AppState } from '../AppState';
import Utils from '../Utils';

export interface SearchComponentProps {
  state: AppState;
  lightColors?: boolean;
}

export interface SearchComponentState {
  inputValue: string;
}

export default class SearchComponent extends React.Component<
  SearchComponentProps,
  SearchComponentState
> {
  constructor(props: SearchComponentProps) {
    super(props);
    this.state = this.initialState(props);
  }

  initialState(props: SearchComponentProps) {
    let initialState: SearchComponentState = {
      inputValue: '',
    };

    return initialState;
  }

  UNSAFE_componentWillReceiveProps() {
    if (!this.store().isSearchOpen) {
      this.setState({ inputValue: '' });
    }
  }

  componentDidUpdate() {
    const open = !$('.layout__header__search__open').hasClass('collapsed');
    const shouldBeOpen = this.props.state.isSearchOpen;
    if (open != shouldBeOpen) {
      this.componentDidMount();
    }
  }

  componentDidMount() {
    if (this.props.state.isSearchOpen) {
      $('.layout__header').addClass('layout__header-hide-logo');
      $('.layout__header__search__open').removeClass('collapsed');
      $('.layout__header__search__open').addClass('opened');
      setTimeout(() => {
        if (this.props.state.isSearchOpen) {
          $('.layout__header__search__input').prop('disabled', false);
          -$('.layout__header__search__close__icon').removeClass('collapsed');
          -$('.layout__header__search__input').removeClass('collapsed');
        }
      }, 1000);
      setTimeout(() => {
        if (this.props.state.isSearchOpen) {
          -$('.layout__header__search__input').focus();
        }
      }, 1010);
    } else {
      $('.layout__header__search__open').removeClass('opened');
      $('.layout__header__search__open').addClass('collapsed');
      setTimeout(() => {
        if (!this.props.state.isSearchOpen) {
          -$('.layout__header').removeClass('layout__header-hide-logo');
          -$('.layout__header__search__open').addClass('collapsed');
          -$('.layout__header__search__input').addClass('collapsed');
        }
      }, 1000);
    }
  }

  render() {
    const self = this;
    let style: React.CSSProperties = {};

    if (this.props.state.config.search_available) {
      return (
        <form className="reader__search" style={style}>
          <div className="layout__header__search__container">
            <div className="layout__header__search">
              {this.searchInput()}
              {this.closeIcon()}
            </div>
            {this.openIcon()}
          </div>
          {this.searchHeader()}
        </form>
      );
    } else {
      return <span></span>;
    }
  }

  searchHeader() {
    let query = this.store().headerLabel();
    let searchOpenDetailHeader = 'layout__header__search__results__header';
    if (this.props.lightColors) {
      searchOpenDetailHeader += ' light';
    } else {
      searchOpenDetailHeader += ' dark';
    }

    if (this.store().isSearchActive()) {
      return <div className={searchOpenDetailHeader}>{query}</div>;
    }
  }

  private closeIcon() {
    let searchCloseIconClass = this.closeIconClass();
    let closeSearch = () => {
      this.setState({ inputValue: '' });
      this.store().closeSearch();
    };

    if (this.props.state.isSearchOpen) {
      return <div className={searchCloseIconClass} onClick={closeSearch}></div>;
    }
  }

  private closeIconClass() {
    let out = 'layout__header__search__close__icon button-behavior';

    if (this.props.lightColors) {
      out += ' light';
    } else {
      out += ' dark';
    }

    out += ' collapsed';

    return out;
  }

  private openIcon() {
    const self = this;
    let searchOpenIconClass = this.openIconClass();
    let searchOpenClass = this.searchOpenClass();
    let openSearchIconClick = () => {
      this.handleSearchIconClick();
    };

    return (
      <a className={searchOpenClass}>
        <div
          className={searchOpenIconClass}
          onClick={openSearchIconClick}
        ></div>
      </a>
    );
  }

  private handleSearchIconClick() {
    if (this.props.state.isSearchOpen) {
      this.store().runSearch(this.state.inputValue);
    } else {
      this.store().openSearch();
    }
  }

  private openIconClass() {
    let out = 'layout__header__search__open__icon button-behavior';

    if (this.props.lightColors) {
      out += ' light';
    } else {
      out += ' dark';
    }

    return out;
  }

  private searchOpenClass() {
    let out = 'layout__header__search__open collapsed';
    if (this.props.lightColors) {
      out += ' light';
    } else {
      out += ' dark';
    }

    return out;
  }

  private store() {
    return window.app.dataStore;
  }

  private searchInput() {
    const self = this;

    let searchInputClass = this.searchInputClass();
    return (
      <input
        type="search"
        className={searchInputClass}
        onKeyPress={this.handleInputKeyPress.bind(this)}
        onChange={this.handleInputChange.bind(this)}
        value={this.state.inputValue}
      />
    );
  }

  handleInputChange(ev: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      inputValue: ev.target.value,
    });
  }

  private searchInputClass() {
    let out = 'layout__header__search__input';

    if (this.props.lightColors) {
      out += ' light';
    } else {
      out += ' dark';
    }

    return out;
  }

  private searchForm() {
    return 'form.reader__search';
  }

  private handleInputKeyPress(pressEvent: any) {
    const key = pressEvent.which;
    const enterKeyCode = 13;

    if (key === enterKeyCode) {
      this.searchCallback();
      pressEvent.preventDefault();
      return false;
    }
  }

  private searchCallback() {
    let query = this.state.inputValue;
    if (Utils.isNotBlank(query)) {
      this.store().runSearch(query);
    }
  }
}
