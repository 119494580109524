import ReaderCategory from './ReaderCategory';
import ArchiveDate from './ArchiveDate';
import ReaderResults from './ReaderResults';
import { ResultsByCategoryId } from './ResultsByCategoryId';
import App from './App';
import ReaderResult from './ReaderResult';
import SummaryResult from './SummaryResult';
import Layout from './Layout';
import * as _ from 'underscore';

interface MoreResultsPromise {
  (
    category: ReaderCategory,
    archiveDate: ArchiveDate
  ): JQueryPromise<ReaderResults>;
}

/** Called after reader results of all categories have been loaded with
 * an array of of all loaded reader results
 */
export interface AfterAllReaderResultsLoadCallback {
  (
    archiveDate: ArchiveDate,
    categories: ReaderCategory[],
    results: ResultsByCategoryId
  ): void;
}

export default class ReaderResultLoader {
  private categories?: ReaderCategory[];
  private app: App;

  constructor(app: App) {
    this.app = app;
  }

  // TODO: I'm not sure why we need this, I've tried to remove its usage
  // in the loadApp method but the results were not rendered. Anyway, now
  // it is performing only callbacks, it is not fetching data anymore, we
  // should try to get rid of this class
  loadWithOrder(
    archiveDate: ArchiveDate,
    afterAllReaderResultsLoadCallback: AfterAllReaderResultsLoadCallback
  ) {
    $.when(
      window.app.dataStore.getCategories(),
      window.app.dataStore.getResults()
    ).done((categories: ReaderCategory[], results: ResultsByCategoryId) => {
      if (categories.length > 0) {
        var allResults: (ReaderResult | SummaryResult)[] = [];
        _.each(categories, (category: ReaderCategory) => {
          if (results[category.id].collection.length > 0) {
            allResults = _.union(allResults, results[category.id].collection);
          }
        });
        afterAllReaderResultsLoadCallback(archiveDate, categories, results);
      } else {
        Layout.hideNavigationLoadingIndicator();
      }
    });
  }

  fetch(
    archiveDate: ArchiveDate
  ): JQueryPromise<Map<ReaderCategory, ReaderResults>> {
    return window.app.dataStore
      .getCategories()
      .then((categories: ReaderCategory[]) => {
        let promises = _.map(categories, (category) => {
          return this.app.fetchMoreResults(category, archiveDate);
        });
        return $.when
          .apply($, promises)
          .then((...resultSets: ReaderResults[]) => {
            let map = new Map<ReaderCategory, ReaderResults>();
            _.each(categories, (category, index) => {
              _.each(
                resultSets[index].collection,
                (el: ReaderResult | SummaryResult) => {
                  el.category_name = category.name;
                  el.category_id = category.id;
                }
              );
              map.set(category, resultSets[index]);
            });
            return map;
          });
      });
  }
}
