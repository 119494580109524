import Source from './Source';
import { ClipAttachment as ClipAttachmentResponse } from './api/response/ClipAttachment';
import Config from './Config';
import * as _ from 'underscore';

export default class Result {
  text: string | undefined;
  categoryOrder: number;
  isHighlighted: boolean;
  private newResult: boolean;

  constructor(
    public id: number,
    public title: string,
    public primarySource: Source,
    public sources: Array<Source>,
    public clip_created_at: moment.Moment,
    public publish_time: moment.Moment,
    public clipId: number,
    public clip_attachments: ClipAttachmentResponse[],
    public snipplet: string | undefined,
    public fullText: string | undefined,
    public lang: string,
    public important: boolean,
    public apiIndex: number,
    public readingTime: number,
    public richText: string | undefined,
    public author: string | undefined,
    public print_edition: string | undefined,
    public kind: string | undefined,
    public youtube_content_removed: boolean
  ) {
    if (this.fullText !== undefined) {
      this.text = this.fullText;
    } else {
      this.text = this.snipplet;
    }

    if (richText !== undefined && richText !== null) {
      this.richText = richText.replace(
        /clip-detail__highlight/g,
        'detail__highlight'
      );
    }
    this.clip_attachments = clip_attachments;

    this.categoryOrder = 100;
    this.isHighlighted = important || false;
    this.newResult = false;
  }
  publishTime(config: Config): moment.Moment {
    if (config.show_publish_time && this.publish_time != undefined) {
      return this.publish_time;
    } else if (this.clip_created_at != undefined) {
      return this.clip_created_at;
    } else {
      throw new Error(
        'this.publish_time && this.clip_created_at are undefined'
      );
    }
  }
  isPresent(field: any): boolean {
    return !_.isUndefined(field) && !_.isNull(field);
  }
  markAsNew() {
    this.newResult = true;
  }
  isNewResult(): boolean {
    return this.newResult;
  }
  primarySourceTitle(): string {
    if (
      this.primarySource != undefined &&
      this.primarySource.title != undefined
    ) {
      return this.primarySource.title;
    } else {
      return '';
    }
  }
  additionalSourceCount(): number {
    if (this.sources != undefined) {
      return _.uniq(_.pluck(this.sources, 'title')).slice(1).length;
    } else {
      return 0;
    }
  }
  additionalSourceNames(): string {
    let sourceLink = '';
    if (this.sources !== undefined) {
      let additionalSources: { title: string; clipUrl: string | undefined }[] =
        _.uniq(this.sources)
          .map((source) => ({ title: source.title, clipUrl: source.clipUrl }))
          .slice(1);
      let spanClass = 'detail__additionalSourceNames__source';
      additionalSources.forEach((source) => {
        sourceLink += `<a href="${source.clipUrl}" target="_blank" class="${spanClass}">${source.title}</a>`;
      });
    }
    return sourceLink;
  }
  isPrintResult(): boolean {
    if (
      this.primarySource != undefined &&
      this.primarySource.isPrintSource != undefined
    ) {
      return this.primarySource.isPrintSource;
    } else {
      return false;
    }
  }
  isInstagramVideo(): Boolean {
    if (this.primarySource != undefined) {
      return this.primarySource.isInstagramVideo();
    } else {
      return false;
    }
  }
  isInstagramImage(): Boolean {
    if (this.primarySource != undefined) {
      return this.primarySource.isInstagramImage();
    } else {
      return false;
    }
  }
  isYoutubeClip(): Boolean {
    if (this.primarySource != undefined) {
      return this.primarySource.isYoutubeClip();
    } else {
      return false;
    }
  }
  isVimeoClip(): Boolean {
    if (this.primarySource != undefined) {
      return this.primarySource.isVimeoClip();
    } else {
      return false;
    }
  }
  clipAttachments(): ClipAttachmentResponse[] {
    return this.clip_attachments;
  }
  readingTimeShort() {
    return this.readingTime === 0;
  }
  readingTimePresent() {
    return !_.isUndefined(this.readingTime) && !_.isNull(this.readingTime);
  }
  setCategoryOrder(tags: any[]) {
    var orderCategories = this.selectCategoryOrderCategories(tags);

    if (orderCategories.length === 1) {
      this.categoryOrder = this.categoryOrderAsNumber(orderCategories[0]);
    }
  }
  private categoryOrderPattern() {
    return /^.(\d{2,2}$)/;
  }
  private selectCategoryOrderCategories(tags: any) {
    return _.select(tags, (tag: any) => {
      return tag.name.match(this.categoryOrderPattern());
    });
  }
  private categoryOrderAsNumber(tag: any) {
    var categoryOrder = tag.name.match(this.categoryOrderPattern());
    return parseInt(categoryOrder[1], 10);
  }
}
