import Environment from '../Environment';

export default class RequestUri {
  private host: string;

  constructor() {
    this.host = this.determineHost();
  }

  /** Build a request uri to the given resource */
  build(resource: string): string {
    return this.apiUrl() + resource;
  }

  determineHost(): string {
    if (Environment.isProduction()) {
      return 'https://app.bluereport.net/';
    } else if (Environment.isReleasePreview()) {
      return 'https://app.release-preview.bluereport.de/';
    } else {
      return 'https://app.dev.bluereport.ch/';
    }
  }

  downloadUrl() {
    return this.maybeFixHostForIE(this.host) + 'clips/';
  }

  private apiUrl() {
    return this.maybeFixHostForIE(this.host) + 'api/v3/';
  }

  private maybeFixHostForIE(host: string): string {
    // IE 8 and 9 had decided to not work properly with filthy
    // https schemes. Thats why we override it to http scheme.
    var newhost = host;
    if (Environment.isIE8OrIE9()) {
      newhost = host.replace('https', 'http');
    }
    return newhost;
  }
}
