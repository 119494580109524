import App from './App';

export default class Visibility {
  private timeoutId: number;
  private userWasAway: boolean;
  private app: App;

  constructor(app: App) {
    this.timeoutId = 0;
    this.userWasAway = false;
    this.app = app;
  }

  addListener() {
    if (this.pageVisibilityApiSupported()) {
      document.addEventListener(
        this.visibilityChangeCallbackName(),
        this.handleVisibilityChange.bind(this),
        false
      );
    }
  }

  private userAwayForMoreThanFiveMinutes(): void {
    if (this.isOn()) {
      clearTimeout(this.timeoutId);
      if (this.userWasAway) {
        this.userWasAway = false;
        this.app.userIsBack();
      }
    } else {
      this.timeoutId = window.setTimeout(
        () => {
          if (!this.isOn()) {
            this.userWasAway = true;
            this.app.userIsAway();
          }
        },
        5 * 60 * 1000
      );
    }
  }

  private isOn(): boolean {
    return !this.isHidden();
  }

  private handleVisibilityChange = function (this: Visibility) {
    return this.userAwayForMoreThanFiveMinutes();
  };

  private isHidden(): boolean {
    if (typeof document.hidden !== 'undefined') {
      return document.hidden;
    } else if (typeof (<any>document).mozHidden !== 'undefined') {
      return (<any>document).mozHidden;
    } else if (typeof document.msHidden !== 'undefined') {
      return document.msHidden;
    } else {
      return (<any>document).webkitHidden;
    }
  }

  private visibilityChangeCallbackName(): string {
    if (typeof document.hidden !== 'undefined') {
      return 'visibilitychange';
    } else if (typeof (<any>document).mozHidden !== 'undefined') {
      return 'mozvisibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      return 'msvisibilitychange';
    } else {
      return 'webkitvisibilitychange';
    }
  }

  private pageVisibilityApiSupported(): boolean {
    if (typeof document.hidden !== 'undefined') {
      return true;
    } else if (typeof (<any>document).mozHidden !== 'undefined') {
      return true;
    } else if (typeof document.msHidden !== 'undefined') {
      return true;
    } else {
      return false;
    }
  }
}
