import * as React from 'react';
import * as ReactDOM from 'react-dom';
import TokenManager from './TokenManager';
import { ReaderStartCallback } from './ReaderTokenInit';
import Analytics from './Analytics';
import { LoginViewProps } from './components/LoginView';
import LoginView from './components/LoginView';
import ApiClient from './ApiClient';
import { ClientContext } from './ApiClient';
import ActivationToken from './ActivationToken';
import { VisitWebsiteViewProps } from './components/VisitWebsiteView';
import VisitWebsiteView from './components/VisitWebsiteView';
import ReaderActivation from './ReaderActivation';
import { Activation as ActivationResponse } from './api/response/Activation';
import AccessToken from './AccessToken';
import Layout from './Layout';
import * as _ from 'underscore';

/**
 * Handles the native mobile login process
 */

interface LoginTranslations {
  nextLabel: string;
  inputPlaceholder: string;
  activateLabel: string;
  inputTokenPlaceholder: string;
  badEmailError: string;
  readerSeatMaximumError: string;
  inputPromptToken: string;
  enterTokenDirectlyLabel: string;
  invalidTokenError: string;
  signupButtonLabel: string;
  inputNamePrompt: string;
  invalidEmailError: string;
  contactSupportMessage: string;
}
export default class Login {
  private tokenManager: TokenManager;
  private template: any;
  private tr: LoginTranslations;
  private givenEmail?: string;

  constructor(private startReaderApp: ReaderStartCallback) {
    this.tokenManager = window.app.tokenManager;
    this.tr = {
      nextLabel: I18n.t('login.next'),
      inputPlaceholder: I18n.t('login.emailPlaceholder'),
      activateLabel: I18n.t('login.activate'),
      inputTokenPlaceholder: I18n.t('login.tokenPlaceholder'),
      inputPromptToken: I18n.t('login.tokenPrompt'),
      badEmailError: I18n.t('login.badEmailError'),
      readerSeatMaximumError: I18n.t('login.readerSeatMaxReached'),
      enterTokenDirectlyLabel: I18n.t('login.enterTokenDirectly'),
      invalidTokenError: I18n.t('login.invalidTokenError'),
      signupButtonLabel: I18n.t('login.signup'),
      inputNamePrompt: I18n.t('login.namePrompt'),
      invalidEmailError: I18n.t('login.invalidEmailError'),
      contactSupportMessage: I18n.t(
        'login.messageBlockedtooManyFailedAttempts'
      ),
    };
  }

  start() {
    this.show();
    this.showEmailInputScreen();
  }

  show() {
    Layout.hideLoadingScreen();
    $(this.container()).show();
  }

  showEmailInputScreen() {
    this.showScreen(this.emailScreenCommonProps());
    Analytics.logInitialLoginPage();
  }

  private showScreen(props: LoginViewProps) {
    let component = React.createElement(LoginView, props);
    if (component) {
      ReactDOM.render(component, this.container());
    }
  }

  private container() {
    let container = document.getElementById('container');
    if (container != undefined) {
      return container;
    } else {
      throw new Error('#container is not in the DOM');
    }
  }

  private hideLogin() {
    ReactDOM.unmountComponentAtNode(this.container());
  }

  private checkEmail(email: string) {
    if (this.validateEmail(email)) {
      ApiClient.create(ClientContext.EmailActivation)
        .postEmail(email)
        .done(() => {
          this.showTokenInputScreen();
        })
        .fail((e) => {
          let json: { error: {} | undefined } = { error: undefined };
          if (e !== undefined && e.responseJSON !== undefined) {
            json = JSON.parse(JSON.stringify(e.responseJSON));
          }
          if (e !== undefined) {
            if (e.status === 403) {
              this.showBadEmailScreen();
            } else if (e.status === 400 && json.error !== undefined) {
              this.showMaximumSeatScreen();
            } else {
              this.showContactServiceScreen();
            }
          } else {
            this.showContactServiceScreen();
          }
        });
    } else {
      this.showInvalidEmailScreen();
    }
  }

  private validateEmail(email: string) {
    var re =
      /^\w+([\w\.\-+!#$%&'*\/=?`{|}~^]*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  private showTokenInputScreen() {
    let props: LoginViewProps = _.extend(this.tokenInputScreenCommonProps(), {
      inputPrompt: this.tr.inputPromptToken,
    });

    this.showScreen(props);
    Analytics.logTokenPage();
  }

  private tokenInputScreenCommonProps(): LoginViewProps {
    let clickHandler = (token: string) => {
      this.activateWithToken(new ActivationToken(token));
    };
    let fullLink: string | undefined;
    let loginAs: string | undefined;
    let backLink: string | undefined;

    if (this.givenEmail) {
      loginAs = I18n.t('login.loginAs', { email: this.givenEmail });
      fullLink = I18n.t('login.loginWithDifferentEmail');
    } else {
      backLink = I18n.t('login.link_back_to_email');
    }
    let props: LoginViewProps = {
      firstButtonLabel: this.tr.activateLabel,
      inputPlaceholder: this.tr.inputTokenPlaceholder,
      inputClass: 'loginView__form__token-input',
      firstButtonClicked: clickHandler,
      showMessageInsteadOfLogin: false,
      screen: 'tokenInput',
      screenLoginAs: loginAs,
      screenLinkLabel: fullLink,
      screenBackLinkLabel: backLink,
      screenLinkClicked: () => {
        this.showEmailInputScreen();
      },
      showLocalStorageWarning: false,
    };

    return props;
  }

  private showInvalidEmailScreen() {
    let additionalProps = {
      error: this.tr.invalidEmailError,
      initialInputValue: this.givenEmail,
    };
    let props = _.extend(this.emailScreenCommonProps(), additionalProps);
    this.showScreen(props);
  }

  private showMaximumSeatScreen() {
    let additionalProps = {
      error: this.tr.readerSeatMaximumError,
      initialInputValue: this.givenEmail,
    };
    let props = _.extend(this.emailScreenCommonProps(), additionalProps);
    this.showScreen(props);
  }

  private showBadEmailScreen() {
    let additionalProps = {
      error: this.tr.badEmailError,
      initialInputValue: this.givenEmail,
      secondButtonLabel: this.tr.signupButtonLabel,
      secondButtonClicked: () => {
        this.showSignupScreen();
      },
    };
    let props = _.extend(this.emailScreenCommonProps(), additionalProps);
    this.showScreen(props);
  }

  private emailScreenCommonProps(): LoginViewProps {
    let submitHandler = (email: string) => {
      this.givenEmail = email;
      this.checkEmail(email);
    };
    let props: LoginViewProps = {
      firstButtonLabel: this.tr.nextLabel,
      inputPlaceholder: this.tr.inputPlaceholder,
      inputClass: 'loginView__form__email-input',
      inputType: 'email',
      firstButtonClicked: submitHandler,
      showMessageInsteadOfLogin: false,
      screen: 'emailInput',
      screenLinkLabel: this.tr.enterTokenDirectlyLabel,
      screenLinkClicked: () => {
        this.showTokenInputScreen();
      },
      errorPadding: true,
      showLocalStorageWarning: !this.isLocalStorageSupported(),
    };

    return props;
  }

  private showContactServiceScreen() {
    let additionalProps = {
      showMessageInsteadOfLogin: true,
      messageToShow: this.tr.contactSupportMessage,
    };
    let props = _.extend(this.emailScreenCommonProps(), additionalProps);
    this.showScreen(props);
  }

  private showSignupScreen() {
    let props = _.extend(this.signupCommonProps(), {
      inputPrompt: this.tr.inputNamePrompt,
    });
    this.showScreen(props);
    Analytics.logNamePage();
  }

  private showBadSignupNameScreen() {
    let props = _.extend(this.signupCommonProps(), {
      error: I18n.t('login.badNameError'),
    });
    this.showScreen(props);
  }

  private signupCommonProps() {
    let submitHandler = (userName: string) => {
      if (userName.length > 0) {
        this.sendSignupEmail(userName);
      } else {
        this.showBadSignupNameScreen();
      }
    };

    let props: LoginViewProps = {
      firstButtonLabel: this.tr.nextLabel,
      firstButtonClicked: submitHandler,
      showLocalStorageWarning: false,
      showMessageInsteadOfLogin: false,
      screen: 'signupInput',
    };

    return props;
  }

  private sendSignupEmail(name: string) {
    if (this.givenEmail === undefined) {
      throw 'givenEmail is undefined';
    }
    let email = this.givenEmail;
    ApiClient.create(ClientContext.EmailActivation).sendSignupEmail(
      email,
      name
    );
    this.showVisitWebsiteScreen(name);
  }

  private showVisitWebsiteScreen(userName: string) {
    let props: VisitWebsiteViewProps = {
      userName: userName,
    };

    let component = React.createElement(VisitWebsiteView, props);
    if (component) {
      ReactDOM.render(component, this.container());
    }
  }

  private activateWithToken(activationToken: ActivationToken) {
    var readerActivation = new ReaderActivation(activationToken);
    readerActivation
      .getAccessToken()
      .done((data: ActivationResponse) => {
        Analytics.sendEvent('reader_signin_completed');
        window.setTimeout(() => {
          this.hideLogin();
          let accessToken = new AccessToken(data.reader_token);
          this.tokenManager.store(accessToken);
          this.startReaderApp(accessToken);
        }, 300);
      })
      .fail((data: any) => {
        this.showTokenInputErrorScreen();
      });
  }

  showTokenInputErrorScreen() {
    let props = _.extend(this.tokenInputScreenCommonProps(), {
      error: this.tr.invalidTokenError,
    });
    this.showScreen(props);
  }

  private isLocalStorageSupported(): boolean {
    return window.app.tokenManager.isTokenStorageSupported();
  }
}
