import * as React from 'react';
export interface SidebarSettingsProps {
  closeSettingsPage: () => void;
  emailNotificationsEnabled: boolean;
  pushNotificationsEnabled: boolean;
}

export interface SidebarSettingsState {
  emailNotificationsEnabled: boolean;
  pushNotificationsEnabled: boolean;
}

export default class SidebarSettings extends React.Component<
  SidebarSettingsProps,
  SidebarSettingsState
> {
  constructor(props: SidebarSettingsProps) {
    super(props);
    this.state = {
      emailNotificationsEnabled: props.emailNotificationsEnabled,
      pushNotificationsEnabled: props.pushNotificationsEnabled,
    };
  }

  render() {
    return (
      <div className="sidebar__settings-container">
        {this.header()}
        <div id="sidebar__settings__notification__container"></div>
        <ul className="sidebar__settings-container__settings">
          <li>{this.listHeadline()}</li>
          <li>{this.emailNotification()}</li>
          <li>{this.pushNotification()}</li>
        </ul>
      </div>
    );
  }

  listHeadline() {
    let label = I18n.t('sidebar.settings.notifications_headline');

    return (
      <div className="sidebar__settings-container__settings-item">
        <div className="sidebar__settings-container__settings-item-label">
          {label}
        </div>
      </div>
    );
  }

  closeButton() {
    let closeHandler = (ev: React.MouseEvent<HTMLElement>) => {
      this.props.closeSettingsPage();
    };
    return (
      <div
        className="sidebar__top__close button-behavior"
        onClick={closeHandler}
      >
        <div className="sidebar__top__close__icon"></div>
      </div>
    );
  }

  header() {
    let label = I18n.t('sidebar.settings.headline');
    return (
      <div className="sidebar__top__container">
        <div className="sidebar__support__headline">{label}</div>
        {this.closeButton()}
      </div>
    );
  }

  emailNotification() {
    let label = I18n.t('sidebar.settings.notifications_email');
    return (
      <div
        className="sidebar__settings-container__settings-item"
        onClick={this.emailNotificationClick.bind(this)}
      >
        <div className="sidebar__settings-container__settings-item-label">
          {label}
        </div>
        <div className="sidebar__settings-container__settings-item-value">
          <div className="onoffswitch">
            <input
              type="checkbox"
              data-email-notification-input
              name="onoffswitch-email"
              className="onoffswitch__checkbox"
              id="myonoffswitch-email"
              checked={this.state.emailNotificationsEnabled}
              readOnly
            />
            <label
              className="onoffswitch__label"
              htmlFor="myonoffswitch-email"
            ></label>
          </div>
        </div>
      </div>
    );
  }

  pushNotification() {
    let label = I18n.t('sidebar.settings.notifications_push');

    return (
      <div
        className="sidebar__settings-container__settings-item"
        onClick={this.pushNotificationClick.bind(this)}
      >
        <div className="sidebar__settings-container__settings-item-label">
          {label}
        </div>
        <div className="sidebar__settings-container__settings-item-value">
          <div className="onoffswitch">
            <input
              type="checkbox"
              data-push-notification-input
              name="onoffswitch-push"
              className="onoffswitch__checkbox"
              id="myonoffswitch-push"
              checked={this.state.pushNotificationsEnabled}
              readOnly
            />
            <label
              className="onoffswitch__label"
              htmlFor="myonoffswitch-push"
            ></label>
          </div>
        </div>
      </div>
    );
  }

  emailNotificationClick() {
    const newState = {
      emailNotificationsEnabled: !this.state.emailNotificationsEnabled,
      pushNotificationsEnabled: this.state.pushNotificationsEnabled,
    };
    this.setState(newState);
    window.app.onSettingsChanged(newState);
  }

  pushNotificationClick() {
    const newState = {
      emailNotificationsEnabled: this.state.emailNotificationsEnabled,
      pushNotificationsEnabled: !this.state.pushNotificationsEnabled,
    };
    this.setState(newState);
    window.app.onSettingsChanged(newState);
  }
}
