import Config from './Config';

export class SummaryAgentResult {
  public primarySource: SummarySource;
  public publish_time: moment.Moment;
  public id: undefined;
  public sources: undefined;
  public readingTime: undefined;
  public richText: undefined;

  constructor(
    publishTime: moment.Moment,
    public text: string
  ) {
    this.primarySource = new SummarySource('summary');
    this.publish_time = publishTime;
  }

  publishTime(_config: Config): moment.Moment {
    return this.publish_time;
  }

  isPresent(field: any): boolean {
    return false;
  }

  markAsNew() {}

  isNewResult(): boolean {
    return false;
  }

  primarySourceTitle(): string {
    return 'Summary';
  }

  additionalSourceCount(): number {
    return 0;
  }

  additionalSourceNames(): string {
    return '';
  }

  isPrintResult(): boolean {
    return false;
  }

  isInstagramVideo(): boolean {
    return false;
  }

  isInstagramImage(): boolean {
    return false;
  }

  isYoutubeClip(): boolean {
    return false;
  }

  isVimeoClip(): boolean {
    return false;
  }

  readingTimeShort(): boolean {
    return true;
  }

  readingTimePresent(): boolean {
    return false;
  }

  setCategoryOrder(tags: any[]) {}

  clipAttachments() {
    return [];
  }
}

export class SummarySource {
  public url: undefined;
  public pdf: undefined;
  public info: undefined;
  public reachCount: undefined;
  public circulationDistributed: undefined;
  public videoId: undefined;
  public imageUrl: undefined;
  public kind: string;
  public reach: undefined;
  public thumbnailUrl: undefined;
  public downloadable: boolean;
  public pageNumber: undefined;
  public isPrintSource: boolean;
  public isRtv: boolean;
  public image: undefined;
  public mediaKind?: undefined;

  constructor(public title: string) {
    this.downloadable = false;
    this.isPrintSource = false;
    this.kind = 'summary';
  }

  isYoutubeClip(): boolean {
    return false;
  }

  isVimeoClip(): boolean {
    return false;
  }

  isInstagramVideo(): boolean {
    return false;
  }

  isInstagramImage(): boolean {
    return false;
  }
}

export default class SummaryResult {
  public position: number;
  public result: SummaryAgentResult;
  public archiveDateId: undefined;
  public category_name: undefined;
  public category_id: undefined;

  constructor(
    public id: number,
    public summaryTitle: string,
    public summaryText: string,
    public deliveryTime: moment.Moment
  ) {
    this.result = new SummaryAgentResult(deliveryTime, summaryText);
  }

  uniqueId(): number {
    return this.id;
  }

  title(): string {
    return this.summaryTitle;
  }

  clipId(): number {
    return this.id;
  }

  publishTime(): moment.Moment {
    return this.deliveryTime;
  }

  primarySourceTitle(): string {
    return this.result.primarySourceTitle();
  }

  isNewResult(): boolean {
    return false;
  }

  markAsNew() {}

  isPrintResult(): boolean {
    return false;
  }

  firstClipAttachmentUrl(): string | undefined {
    return undefined;
  }

  isYoutubeContentRemoved(): boolean {
    return false;
  }
}
