import Environment from './Environment';
import * as _ from 'underscore';

/**
 * Wrapper around bug reporting service.
 */
class ExternalBugReportingService {
  static service(): Bugsnag {
    return window.Bugsnag;
  }

  static servicePayload(): any {
    return this.service().metaData;
  }

  static addPayload(payload: any): Object {
    let service = this.service();
    let data = this.servicePayload();
    let newPayload: Object;

    if (_.isUndefined(data)) {
      service.metaData = _.extend({}, { payload: payload });
    } else {
      newPayload = _.extend(data.payload, payload);
      service.metaData = { payload: newPayload };
    }

    return service.metaData;
  }

  static notify(errorName: string, error: string) {
    this.service().notify(errorName, error);
  }

  static setEnvironment(environment: string) {
    this.service().releaseStage = environment;
  }

  static setNotificationEnvironments(envs: string[]) {
    this.service().notifyReleaseStages = envs;
  }
}

/**
 *  Handles the reporting of errors to an external service.
 */
export default class FailureReport {
  static addPayload(payload: Object): Object {
    return ExternalBugReportingService.addPayload(payload);
  }

  static notify(errorName: string, error: string) {
    ExternalBugReportingService.notify(errorName, error);
  }

  static initReporting() {
    let environment = Environment.errorReportingEnvironment();
    ExternalBugReportingService.setEnvironment(environment);
    ExternalBugReportingService.setNotificationEnvironments([
      'development',
      'staging',
      'production',
    ]);
  }
}
