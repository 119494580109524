import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import {
  FirebaseAnalytics,
  FirebaseInitOptions,
} from '@capacitor-community/firebase-analytics';
import { SplashScreen } from '@capacitor/splash-screen';
import { firebaseConfig } from './Analytics';
import App from './App';
import '../css/main.css.scss';

let appName = 'blueReport';
let appVersion = 'n/a';
let appId = 'n/a';

function start() {
  window.app = new App(appName, appVersion, appId);
  window.app.start(undefined);
}

document.addEventListener('DOMContentLoaded', () => {
  SplashScreen.show({
    autoHide: true,
  }).then(() => {
    CapacitorApp.getInfo()
      .then(({ name, version, id }) => {
        appName = name;
        appVersion = version;
        appId = id;
      })
      .catch((reason) => {
        if (reason.message !== 'Not implemented on web.') {
          console.log(reason);
        }
      })
      .finally(() => {
        if (Capacitor.getPlatform() === 'web') {
          FirebaseAnalytics.initializeFirebase(firebaseConfig)
            .then(() => console.log('firebase initialized'))
            .catch((err) => console.log('firebase initialization failed', err))
            .finally(() => start());
        } else {
          start();
        }
      });
  });
});
