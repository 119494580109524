import * as React from 'react';
import ArchiveDate from '../ArchiveDate';
import ArchiveDateSummary from '../ArchiveDateSummary';
import Config from '../Config';
import ReaderCategory from '../ReaderCategory';
import ReaderResults from '../ReaderResults';
import DataStore from '../DataStore';
import SearchNotification from './SearchNotification';
import Spinner from './SpinnerComponent';
import NavigationItemEmpty from './NavigationItemEmpty';
import { Category } from '../Category';
import ReaderResult from '../ReaderResult';
import NavigationGroup from './NavigationGroup';
import NavigationItem from './NavigationItem';
import NavigationSummary from './NavigationSummary';
import * as _ from 'underscore';

export interface NavigationComponentProps {
  archiveDates: ArchiveDate[];
  summary: ArchiveDateSummary;
  config: Config;
  isLoading: boolean;
  newClips: boolean;
  download: boolean;
  categories: ReaderCategory[];
  // Hash<CategoryId,ReaderResult[]>
  readerResults: any;
  searchResults?: ReaderResults;
}

export interface NavigationComponentState {
  displaySearchNotification: boolean;
}

export default class NavigationComponent extends React.Component<
  NavigationComponentProps,
  NavigationComponentState
> {
  constructor(props: NavigationComponentProps) {
    super(props);
    this.state = {
      displaySearchNotification: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: NavigationComponentProps) {
    let searchNotificationState =
      window.app.dataStore.displaySearchNotification();
    const stateChanged =
      this.state.displaySearchNotification !== searchNotificationState;

    if (stateChanged) {
      let newState: NavigationComponentState = {
        displaySearchNotification: searchNotificationState,
      };
      this.setState(newState);
    }
  }

  componentDidMount() {
    let self = this;
    new Hammer($('.layout .layout__navigation')[0]).on('swiperight', () => {
      self.store().openSidebar();
    });
  }

  store(): DataStore {
    return window.app.dataStore;
  }

  render() {
    return (
      <div className="navigation">
        {this.pullToRefresh()}
        <div>
          <ol
            id="pull-to-refresh-content"
            className={this.navigationListClassName()}
            tabIndex={0}
          >
            <li>{this.newClips()}</li>
            <li>
              <div data-navigation-archive-date-summary>{this.summary()}</div>
            </li>
            {this.results()}
          </ol>
        </div>
        {this.download()}
      </div>
    );
  }

  pullToRefresh() {
    return (
      <div>
        {this.curtain()}
        {this.searchNotification()}
        <div
          className="notification__pull-to-refresh"
          id="pull-to-refresh-notification"
        >
          <div data-loading-results id="pull__loading-results"></div>
          <div data-already-up-to-date id="pull__already-up-to-date"></div>
        </div>
      </div>
    );
  }

  searchNotification() {
    if (
      this.props.searchResults != undefined &&
      this.props.searchResults.moreAvailable != undefined
    ) {
      return (
        <SearchNotification
          expireTime={10000}
          moreAvailable={this.props.searchResults.moreAvailable}
          displayNotification={this.state.displaySearchNotification}
          noResults={this.props.searchResults.collection.length == 0}
        />
      );
    }
  }

  navigationListClassName() {
    return 'navigation__list enable-ios-scrolling';
  }

  curtain() {
    if (this.props.isLoading) {
      return (
        <div className="loading-curtain" data-sidebar-loader>
          <div className="loading-indicator-container">
            <Spinner style="dark" />
          </div>
        </div>
      );
    }
  }

  // TODO: fix this type
  results(): any {
    if (this.store().hasCurrentArchiveDate()) {
      if (this.props.searchResults) {
        return this.searchResults();
      } else if (this.props.readerResults) {
        if (
          this.props.config.hideEmptyCategories() &&
          this.allCategoriesEmpty()
        ) {
          return this.navigationEmptyResults();
        } else {
          return this.navigationResults();
        }
      } else {
        throw 'No search results and reader results found.';
      }
    } else {
      return this.navigationEmptyResults();
    }
  }

  allCategoriesEmpty() {
    return _.every(this.props.categories, function (cat: ReaderCategory) {
      return cat.readerResults.count == 0;
    });
  }

  navigationEmptyResults() {
    return (
      <ol>
        <NavigationItemEmpty />
      </ol>
    );
  }

  navigationResults() {
    let grps = this.props.categories.map((category: Category) => {
      var results: ReaderResult[] = [];
      if (this.props.readerResults[category.id]) {
        results = this.props.readerResults[category.id].collection;
      }

      if (!(_.isEmpty(results) && this.props.config.hideEmptyCategories())) {
        return (
          <NavigationGroup
            key={category.id}
            category={category}
            readerResults={results}
            config={this.props.config}
          />
        );
      }
    });

    return grps;
  }

  searchResults() {
    if (
      this.props.searchResults?.collection &&
      this.props.searchResults.collection.length > 0
    ) {
      const readerResults = this.props.searchResults.collection.filter(
        (r) => r instanceof ReaderResult
      ) as ReaderResult[];
      return readerResults.map((readerResult: ReaderResult) => {
        return this.navigationItemFromReaderResult(readerResult);
      });
    }
  }

  navigationItemFromReaderResult(readerResult: ReaderResult) {
    return (
      <NavigationItem
        config={this.props.config}
        key={readerResult.uniqueId()}
        readerResult={readerResult}
      />
    );
  }

  newClips() {
    if (this.props.newClips) {
      return (
        <div className="navigation__newclips">
          <a className="navigation__newclips-action">
            <i className="fa fa-chevron-circle-up"></i> Neue Treffer
          </a>
        </div>
      );
    }
  }

  download() {
    if (this.props.download) {
      return (
        <div className="navigation__download">
          <i className="fa fa-download"></i>
        </div>
      );
    }
  }

  summary() {
    let summary = this.props.summary;
    if (summary && _.isUndefined(this.props.searchResults)) {
      return <NavigationSummary summary={summary} />;
    }
  }
}
