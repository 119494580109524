import * as _ from 'underscore';

export default class Utils {
  public static isPresent(obj: any): boolean {
    return !_.isUndefined(obj) && !_.isNull(obj);
  }

  public static isNotBlank(obj?: string): boolean {
    return obj != undefined && obj != null && obj.length > 0;
  }

  public static jqueryIsPresent(el: JQuery) {
    return Utils.isPresent(el) && el.length > 0;
  }

  public static logClipAccess(el: JQuery): void {
    let parent = $(el).parents('.detail');
    let selectedClipId = +parent.data('clip-id');
    let agentResultId = +parent.data('result-id');
    let resultKind = String(parent.data('kind'));

    window.app.logClipAccessRequest(
      selectedClipId,
      agentResultId,
      resultKind,
      'click'
    );
  }
}
