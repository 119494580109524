import * as _ from 'underscore';

/**
 * This class is used to parse the parameter of an URL.
 *
 **/
export interface UrlParams {
  token?: string;
  rtoken?: string;
  readerId?: string;
  archiveDateId?: string;
  resultId?: string;
  richmedia?: string;
}

interface KeyValue {
  key: string;
  value: string;
}

export default class UrlParamParser {
  constructor() {}
  /**
   * Take a URL string as argument, returns the parameter in an hash
   * @param {string} query - The URL to parse.
   * @example <caption>Example usage of method deparam</caption>
   * var parser = new UrlParamParser;
   * var params = parser.deparam(http://local.reader.bluereport.net/index.html?rtoken=e32831da9e9f31bf6671212f1c169159&archive_date_id=829);
   * // returns 829
   * parsed.archiveDateId;
   * @returns {UrlParams}
   *
   **/
  deparam(query: string): UrlParams {
    var params: UrlParams = {};

    // split into key value pairs
    var matchData = query.match(/([^?&]+)=([^&]+)/g);
    if (matchData != null) {
      var keyValues: Array<KeyValue> = _.map(matchData, (m: string) => {
        var kv = m.split('=');
        return {
          key: kv[0],
          value: kv[1],
        };
      });

      // match pairs against the interface
      _.each(keyValues, (kv: KeyValue) => {
        switch (kv.key) {
          case 'token':
            params.token = kv.value;
            break;
          case 'rtoken':
            params.rtoken = kv.value;
            break;
          case 'reader_id':
            params.readerId = kv.value;
            break;
          case 'archive_date_id':
            params.archiveDateId = kv.value;
            break;
          case 'result_id':
            params.resultId = kv.value;
            break;
          case 'richmedia':
            params.richmedia = kv.value;
            break;
        }
      });

      return params;
    } else {
      let params: UrlParams = {
        token: undefined,
        rtoken: undefined,
        readerId: undefined,
        archiveDateId: undefined,
        resultId: undefined,
        richmedia: undefined,
      };
      return params;
    }
  }
}
