// TODO: make those fields non-nullable
export default class ArchiveDateSummary {
  constructor(
    public title?: string,
    public text?: string,
    public archiveDateId?: number
  ) {
    this.title = title;
    this.text = text;
    this.archiveDateId = archiveDateId;
  }
}
