import React from 'react';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  TooltipProps,
} from 'recharts';
import {
  ValueType,
  NameType,
} from 'recharts/types/component/DefaultTooltipContent';
import { NumDatum } from './types';
import { formatNumber } from './util';

interface Props {
  data: NumDatum[];
}

export default function Chart({ data }: Props) {
  const maxValue = data.map(({ value }) => value).sort((a, b) => b - a)[0] || 0;

  return (
    <ResponsiveContainer width="100%" height={100}>
      <BarChart data={data}>
        <Bar dataKey="value" fill="#e0e0e0" />
        <XAxis dataKey="label" hide />
        <YAxis type="number" domain={[0, maxValue]} hide />
        <Tooltip content={<CustomTooltip />} />
      </BarChart>
    </ResponsiveContainer>
  );
}

function CustomTooltip({
  payload,
  label,
  active,
}: TooltipProps<ValueType, NameType>) {
  if (active && payload?.[0]) {
    return (
      <div className="custom-tooltip">
        <span className="value">{formatNumber(payload?.[0].value)} </span>
        <span className="label">{label}</span>
      </div>
    );
  }

  return null;
}
