import BrowserChrome from './BrowserChrome';
import { UserGroupBranding as UserGroupBrandingResponse } from './api/response/UserGroupBranding';
import Environment from './Environment';
import ColorHandler from './ColorHandler';
import OfflineBranding from './OfflineBranding';

export default class UserGroupBranding {
  constructor(private userGroupBranding: UserGroupBrandingResponse) {
    this.userGroupBranding = userGroupBranding;
    this.browserChrome = new BrowserChrome();
  }

  /**
   * Achtung! Halt! This method adds dynamic content, if you cannot
   * test it with windows mobile, be aware that any change you make
   * can possible kill windows mobile, which is very strict about the
   * rules of adding dynamic content.
   */
  apply() {
    let styleContainer = document.createElement('style');
    let styleContent = document.createTextNode(this.cssStyles());
    styleContainer.setAttribute('type', 'text/css');
    styleContainer.appendChild(styleContent);
    $('head').append(styleContainer);
  }

  // Title color inverted means that the theme is dark
  categoryTitleColorInverted(): boolean {
    let data = this.userGroupBranding.category_title_color_inverted;
    const offlineData = this.offlineBranding().getInverted();
    if (offlineData !== null && this.useOfflineData()) {
      data = offlineData;
    }
    return data;
  }

  applyBrandingToBrowserChrome() {
    this.browserChrome.setColor(this.secondaryColor());
    if (this.categoryTitleColorInverted()) {
      this.browserChrome.setStatusBarStyle('light');
    } else {
      this.browserChrome.setStatusBarStyle('default');
    }
  }

  supportPageText(): string | undefined {
    let data = this.userGroupBranding.support_page_text;
    const offlineData = this.offlineBranding().getSupportPageText();
    if (offlineData !== null && this.useOfflineData()) {
      data = offlineData;
    } else if (data === null) {
      data = undefined;
    }
    return data;
  }

  private logoUrl() {
    let data = this.userGroupBranding.logo_path;
    const offlineData = this.offlineBranding().getLogo();
    if (offlineData !== null && this.useOfflineData()) {
      data = offlineData;
    }
    return data;
  }

  private primaryColor() {
    let data = this.userGroupBranding.primary_color;
    const offlineData = this.offlineBranding().getPrimaryColor();
    if (offlineData !== null && this.useOfflineData()) {
      data = offlineData;
    }
    return data;
  }

  private secondaryColor() {
    let data = this.userGroupBranding.secondary_color;
    const offlineData = this.offlineBranding().getSecondaryColor();
    if (offlineData !== null && this.useOfflineData()) {
      data = offlineData;
    }
    return data;
  }

  private linkColor() {
    let data = this.userGroupBranding.link_color;
    const offlineData = this.offlineBranding().getLinkColor();
    if (offlineData !== null && this.useOfflineData()) {
      data = offlineData;
    }
    return data;
  }

  private logoSize() {
    let data = this.userGroupBranding.reader_logo_size;
    const offlineData = this.offlineBranding().getLogoSize();
    if (offlineData !== null && this.useOfflineData()) {
      data = offlineData;
    }
    return data;
  }

  private useOfflineData(): boolean {
    return Environment.isNativeApp();
  }

  private navigationCategoryTitleCss() {
    const colorHandler = new ColorHandler();
    const color = colorHandler.applyAlpha(this.secondaryColor(), 0.7);
    return (
      '.navigation__grouptitle { color: ' +
      this.primaryColor() +
      ' !important; background-color: ' +
      color +
      ' !important}'
    );
  }

  private summaryResultCss() {
    return (
      '.summary-result .navigation__itemsource { color: ' +
      this.primaryColor() +
      ' !important; }'
    );
  }

  private summaryTitleCss() {
    return (
      '.navigation__summary-title { color: ' +
      this.primaryColor() +
      ' !important}'
    );
  }

  private summaryHeaderCss() {
    return (
      '.navigation__summary-text h2 { color: ' +
      this.primaryColor() +
      ' !important}'
    );
  }

  private detailTitleCss() {
    return '.detail__title { color: ' + this.primaryColor() + ' !important}';
  }

  private detailHeaderCategoryTitleCss() {
    return (
      '.layout__header__category__title { color: ' +
      this.primaryColor() +
      ' !important}'
    );
  }

  private detailHeaderSearchTitleCss() {
    return (
      '.layout__header__search__results__header { color: ' +
      this.primaryColor() +
      ' !important}'
    );
  }

  private highlightCss() {
    const colorHandler = new ColorHandler();
    const color = colorHandler.applyAlpha(this.primaryColor(), 0.1);
    return (
      '.query-highlight, .detail__highlight { background-color: ' +
      color +
      '; }'
    );
  }

  private importantCss() {
    const colorHandler = new ColorHandler();
    const color = colorHandler.applyAlpha(this.primaryColor(), 1);
    return `.important > .navigation__item-important { border-left: 2px solid ${color} !important; }`;
  }

  private isSelectedCss() {
    const colorHandler = new ColorHandler();
    const color = colorHandler.applyAlpha(this.primaryColor(), 0.05);
    return `.navigation__item.is-selected { background-color: ${color} !important; }`;
  }

  private isMobileSelectedCss() {
    const colorHandler = new ColorHandler();
    const color = colorHandler.applyAlpha(this.primaryColor(), 0.05);
    return (
      '@media (max-width: 700px) { ' +
      ' .navigation__item.is-selected { background-color: #ffffff !important; } ' +
      ' } '
    );
  }

  private navigationHeaderCss() {
    const layoutHeader =
      '.layout__header { background-color: ' +
      this.secondaryColor() +
      ' !important}';
    const layoutHeaderWrapper =
      '.layout__header_wrapper { ' +
      'background-color: ' +
      this.secondaryColor() +
      ' !important; }';
    return layoutHeaderWrapper + layoutHeader;
  }

  private headerImageCss() {
    const url = this.logoUrl();
    const size = this.logoSize();
    return `.layout__header:before {
      background-image: url('${this.logoUrl()}');
      background-size: auto ${size}%;
    }`;
  }

  private headerMobileImageCss() {
    return (
      '@media (max-width: 700px) {' +
      ".layout__header:before { background-image: url('" +
      this.logoUrl() +
      "') } }"
    );
  }

  private linkColorCss() {
    let fullStory =
      '.detail__fullstory { color: ' + this.linkColor() + ' !important}';
    let readOn =
      '.detail__readon { color: ' + this.linkColor() + ' !important}';
    let pdfLink = '.detail__pdf { color: ' + this.linkColor() + ' !important}';
    let readOnArrow = `.detail__readon .readon__arrow .arrow { fill: ${this.linkColor()}}`;
    let pdfLinkArrow = `.detail__pdf .readon__arrow .arrow { fill: ${this.linkColor()}}`;
    return [fullStory, readOn, readOnArrow, pdfLink, pdfLinkArrow].join('\n');
  }

  private cssStyles() {
    let result = [
      this.summaryHeaderCss(),
      this.summaryTitleCss(),
      this.navigationCategoryTitleCss(),
      this.navigationHeaderCss(),
      this.headerImageCss(),
      this.headerMobileImageCss(),
      this.linkColorCss(),
      this.summaryResultCss(),
      this.isSelectedCss(),
      this.isMobileSelectedCss(),
      this.detailTitleCss(),
      this.detailHeaderCategoryTitleCss(),
      this.detailHeaderSearchTitleCss(),
      this.importantCss(),
      this.highlightCss(),
    ].join('\n');

    return result;
  }

  private offlineBranding(): OfflineBranding {
    return new OfflineBranding(window.app.config);
  }

  private browserChrome: BrowserChrome;
}
