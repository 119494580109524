import * as React from 'react';
export interface SpinnerProps {
  style: 'light' | 'dark';
}

export default class Spinner extends React.Component<SpinnerProps, {}> {
  render() {
    return (
      <div className={this.spinnerClass()}>
        <div className="spinner-blade"></div>
        <div className="spinner-blade"></div>
        <div className="spinner-blade"></div>
        <div className="spinner-blade"></div>
        <div className="spinner-blade"></div>
        <div className="spinner-blade"></div>
        <div className="spinner-blade"></div>
        <div className="spinner-blade"></div>
        <div className="spinner-blade"></div>
        <div className="spinner-blade"></div>
        <div className="spinner-blade"></div>
        <div className="spinner-blade"></div>
      </div>
    );
  }

  spinnerClass(): string {
    let out = 'spinner';
    if (this.props.style == 'light') {
      out += ' light';
    } else {
      out += ' dark center';
    }

    return out;
  }
}
