import { IIDBRecord } from './IIDBRecord';
import { ReaderCategory as ReaderCategoryResponse } from '../api/response/ReaderCategory';
import CommonFinders from './CommonFinders';

export default class Category implements IIDBRecord {
  public identifier: string;
  public response: ReaderCategoryResponse[];

  constructor(
    response: ReaderCategoryResponse[],
    configId: Number,
    archiveDateId: Number
  ) {
    this.identifier = `${configId}-${archiveDateId}`;
    this.response = response;
  }

  static findByConfigIdAndArchiveDateId(
    configId: Number,
    archiveDateId: Number,
    objectStore: IDBObjectStore
  ) {
    let identifier = `${configId}-${archiveDateId}`;
    return CommonFinders.find(identifier, objectStore);
  }
}
