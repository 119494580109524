import ReaderResult from './ReaderResult';
import SummaryResult from './SummaryResult';
import * as _ from 'underscore';

// TODO: Deprecated
export default class ReaderResultWatch {
  private notedRecords: number[];

  constructor() {
    this.notedRecords = [];
  }
  // TODO: Deprecated
  recordLoadedResults(results: (ReaderResult | SummaryResult)[]): void {
    _.each(results, (result: ReaderResult | SummaryResult) => {
      if (result.id != undefined) {
        this.notedRecords.push(result.id);
      }
    });
    this.notedRecords = _.uniq(this.notedRecords);
  }
  // TODO: Deprecated
  recordedResults(): number[] {
    return this.notedRecords;
  }
  // TODO: Deprecated
  resultIsNew(result: ReaderResult | SummaryResult): boolean {
    var foundResult = _.find(this.notedRecords, (resultId: number) => {
      return resultId === result.id;
    });
    return _.isUndefined(foundResult);
  }
}
