import RequestUri from './api/RequestUri';
import ActivationToken from './ActivationToken';
import { Activation as ActivationResponse } from './api/response/Activation';

/** Use to get a valid reader access token using an activation token */
export default class ReaderActivation {
  private requestUri: RequestUri;
  constructor(private token: ActivationToken) {
    this.requestUri = new RequestUri();
  }

  /**
   * Sends the activation token to the api. Api responds with a valid
   * reader token or error
   */
  getAccessToken(): JQueryPromise<ActivationResponse> {
    var params = {
      activation_token: this.token.toString(),
    };
    return $.post(this.requestUri.build('activate_reader'), params);
  }
}
