import { ArchiveDate as ArchiveDateResponse } from './api/response/ArchiveDate';
import Config from './Config';

export default class ArchiveDate {
  private deliveryTime: number;
  private starts: number;
  private ends: number;
  public id: number;
  public config: Config;

  constructor(archiveDate: ArchiveDateResponse, config: Config) {
    this.config = config;
    this.deliveryTime = archiveDate.delivery_time;
    this.starts = archiveDate.starts;
    this.ends = archiveDate.ends;
    this.id = archiveDate.id;
  }

  public date(): moment.Moment {
    return moment.unix(this.deliveryTime);
  }
}
