import { IIndexedDatabase } from './IIndexedDatabase';
import Environment from './Environment';
import ReaderDBWithFallback from './ReaderDBWithFallback';
import DummyDB from './DummyDB';

export default class IndexedDatabaseFactory {
  static getIndexedDatabase(): IIndexedDatabase {
    if (Environment.useIndexedDB()) {
      return new ReaderDBWithFallback();
    } else {
      return new DummyDB();
    }
  }
}
