import * as _ from 'underscore';

export default class CommonFinders {
  /** Returns the stored API response field of found record */
  static find(
    identifier: string,
    objectStore: IDBObjectStore
  ): JQueryPromise<any> {
    let deferred = $.Deferred();
    let request: IDBRequest = objectStore.get(identifier);
    request.onerror = (e: Event) => {
      let req: IDBRequest = <IDBRequest>e.target;
      deferred.reject(req.error);
    };
    request.onsuccess = (e: Event) => {
      let req: IDBRequest = <IDBRequest>e.target;
      if (_.isUndefined(req.result)) {
        deferred.reject();
      } else {
        deferred.resolve(req.result.response);
      }
    };
    return deferred.promise();
  }

  /** Returns the database record  */
  static findRecord(
    identifier: string,
    objectStore: IDBObjectStore
  ): JQueryPromise<any> {
    let deferred = $.Deferred();
    let request: IDBRequest = objectStore.get(identifier);
    request.onerror = (e: Event) => {
      let req: IDBRequest = <IDBRequest>e.target;
      deferred.reject(req.error);
    };
    request.onsuccess = (e: Event) => {
      let req: IDBRequest = <IDBRequest>e.target;
      if (_.isUndefined(req.result)) {
        deferred.reject();
      } else {
        deferred.resolve(req.result);
      }
    };
    return deferred.promise();
  }
}
