/** Tagged string type for ActivationTokens */
export default class ActivationToken {
  private token: string;

  constructor(s: string) {
    this.token = s;
  }

  toString() {
    return this.token;
  }
}
